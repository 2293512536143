import { configureStore } from '@reduxjs/toolkit';
import globalSlice from './modules/global';
import siteInfo from './modules/site-info';
import auth from './modules/auth';
import menu from './modules/menu';
import version from './modules/version';


export const store = configureStore({
    reducer: {
        global: globalSlice,
        siteInfo,
        auth,
        menu,
        version
    }
})
