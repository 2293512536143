import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Spin, Form, Card, ColorPicker, Tabs, Input, Slider, Row, Col, Button, Flex } from 'antd';
import { LoadingOutlined, CheckOutlined } from '@ant-design/icons';
import { mapConfig } from '../../service';
import { onError, onSuccess } from '../../utils';
import MapView from '../../components/map';
import polygon from './lands';

export default function MapConfig() {
    const [activeKey, setActiveKey] = useState(null)
        , [dataSource, setDataSource] = useState([])
        , [loading, setLoading] = useState(false)
        , [form] = Form.useForm()
        // , [polygon, setPolygon] = useState(false)
        , [path, setPath] = useState({})
        , mapRef = useRef(null)


    useEffect(() => {
        mapConfig.api.getList().then(({ data }) => {
            setDataSource(data)
            setActiveKey(data.length ? data[0].id : null)
        }).catch(error => {
            onError(error)
        })
    }, [])

    useEffect(() => {
        if (activeKey) {
            const { id, name, weight, color, opacity, fillColor, fillOpacity, remark, value } = dataSource.find(item => item.id === activeKey)
            form.setFieldsValue({
                id,
                name,
                weight,
                color,
                opacity,
                fillColor,
                fillOpacity,
                value,
                remark
            })
            setPath({ weight, color, opacity, fillColor, fillOpacity, remark })
        }
    }, [activeKey, dataSource, form])

    const onFinish = (values) => {
        setLoading(true)
        mapConfig.api.edit(values).then(({ message }) => {
            onSuccess(message)
            setLoading(false)
            setDataSource((state) => {
                return state.map(item => item.id === values.id ? values : item)
            })
        }).catch(error => {
            onError(error)
            setLoading(false)
        })
    }



    const items = useMemo(() => {
        return dataSource.map(item => {
            const presets = [{
                label: '预设颜色',
                colors: ['#f5222d', '#fa541c', '#fa8c16', '#faad14', '#fadb14', '#a0d911', '#52c41a', '#13c2c2', '#1677ff', '#2f54eb', '#722ed1', '#eb2f96']
            }]
                , children = (
                    <Card
                        title="设置"
                        style={{ height: 'calc(100vh - 266px)' }}
                    >
                        <Spin
                            indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />}
                            spinning={loading}
                        >
                            <Form
                                form={form}
                                layout="vertical"
                                name={`map.config.form.${item.id}`}
                                onFinish={onFinish}
                                onChange={async (e) => {
                                    const { weight, color, opacity, fillColor, fillOpacity, remark } = await form.getFieldsValue()
                                    setPath({ weight, color, opacity, fillColor, fillOpacity, remark })
                                }}
                            >
                                <Form.Item
                                    name="name"
                                    label="名称"
                                    rules={[{ required: true, message: '名称不能为空' }]}
                                >
                                    <Input placeholder="请填写" />
                                </Form.Item>

                                <Form.Item noStyle>
                                    <Flex gap={16} justify="space-between">
                                        <Form.Item
                                            name="color"
                                            label="边框颜色"
                                        >
                                            <ColorPicker
                                                disabledAlpha
                                                presets={presets}
                                                onChange={async (e) => {
                                                    const values = await form.getFieldsValue()
                                                        , color = e.toHexString()
                                                    setPath({ ...values, color })
                                                    form.setFieldValue('color', color)
                                                }}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="opacity"
                                            label="边框透明度"
                                            style={{ flexGrow: 2 }}
                                        >
                                            <Slider
                                                min={0.1}
                                                max={1}
                                                step={0.1}
                                                onChange={async opacity => {
                                                    const values = await form.getFieldsValue()
                                                    console.log(opacity)
                                                    setPath({ ...values, opacity })
                                                }}
                                            />
                                        </Form.Item>
                                    </Flex>
                                </Form.Item>

                                <Form.Item
                                    name="weight"
                                    label="边框大小"
                                >
                                    <Slider
                                        min={1}
                                        max={20}
                                        onChange={async weight => {
                                            const values = await form.getFieldsValue()
                                            setPath({ ...values, weight })
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item noStyle>
                                    <Flex gap={16} justify="space-between">
                                        <Form.Item
                                            name="fillColor"
                                            label="填充颜色"
                                        >
                                            <ColorPicker
                                                disabledAlpha
                                                presets={presets}
                                                onChange={async (e) => {
                                                    const values = await form.getFieldsValue()
                                                        , fillColor = e.toHexString()
                                                    setPath({ ...values, fillColor })
                                                    form.setFieldValue('fillColor', fillColor)
                                                }}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="fillOpacity"
                                            label="填充透明度"
                                            style={{ flexGrow: 2 }}
                                        >
                                            <Slider
                                                min={0.1}
                                                max={1}
                                                step={0.1}
                                                onChange={async fillOpacity => {
                                                    const values = await form.getFieldsValue()
                                                    setPath({ ...values, fillOpacity })
                                                }}
                                            />
                                        </Form.Item>

                                    </Flex>
                                </Form.Item>


                                <Form.Item
                                    name="remark"
                                    label="备注"
                                >
                                    <Input.TextArea style={{ height: 150 }} />
                                </Form.Item>

                                <Form.Item
                                    name="id"
                                    hidden={true}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    name="value"
                                    hidden={true}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item>
                                    <Button
                                        icon={<CheckOutlined />}
                                        type="primary"
                                        onClick={() => form.submit()}
                                    >
                                        保存
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Spin>
                    </Card>
                )

            return {
                label: item.name,
                key: item.id,
                children
            }
        })
    }, [dataSource, form, loading])


    return (
        <Card
            title="地图样式配置"
            className='page-card-full'
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Tabs
                        tabPosition='left'
                        tabBarStyle={{ width: 220 }}
                        activeKey={activeKey}
                        onChange={setActiveKey}
                        items={items}
                    />
                </Col>
                <Col span={12}>
                    <MapView
                        ref={mapRef}
                        title="预览"
                        height='calc(100vh - 324px)'
                        isFullscreen={false}
                        polygon={{ data: [{ key: 'polygon', data: polygon, path }], selectable: false }}
                        alone='polygon'
                    />
                </Col>
            </Row>
        </Card>
    )
}
