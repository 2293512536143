import React from 'react';
import { Drawer } from 'antd';

function DocsView(props) {
    const { open, title, dataSource, size } = props
    return (
        <Drawer
            title={`${title}帮助文档`}
            open={open}
            onClose={props.onClose}
            destroyOnClose={true}
            getContainer={false}
            size={size}
        >
            {
                dataSource?.videoUrl ? (
                    <video
                        autoPlay={true}
                        src={dataSource?.videoUrl}
                    />
                ) : null
            }
            <div dangerouslySetInnerHTML={{ __html: dataSource?.content }}></div>
        </Drawer>
    )
}

DocsView.defaultProps = {
    open: false,
    title: null,
    size: 'default',
    dataSource: null
}

export default DocsView
