import React, { useEffect, useState, useCallback } from 'react'
import {
    Button,
    Card,
    Space,
    Table,
    Tooltip,
    Divider,
} from 'antd';
import {
    UserAddOutlined,
    Loading3QuartersOutlined,
} from '@ant-design/icons';
import { wxfarm } from "../../../service";
import { onError, onSuccess } from '../../../utils';
import FormDrawer from './form'


export default function WxFarmAuth() {
    const [dataSource, setDataSource] = useState([])
        , [loading, setLoading] = useState(false)
        , [open, setOpen] = useState(false)
        , [menus, setMenus] = useState([])
        , [selectedRowKey, setSelectedRowKey] = useState(null)
        , columns = [
            {
                title: '角色名称',
                dataIndex: 'name',
            },
            {
                title: '角色CODE',
                dataIndex: 'code',
            },
            {
                title: '状态',
                dataIndex: 'state',
                render: state => {
                    return state === 0 ? '停用' : '正常'
                }
            },
            {
                title: '角色描述',
                dataIndex: 'description'
            },
        ]

    useEffect(() => {
        fetch()
        getMenus()
    }, [])

    // 获取小程序菜单
    const getMenus = () => {
        // 获取菜单
        wxfarm.apiMenu.getList().then(({ data }) => {
            setMenus(data)
        }).catch(error => {
            onError(error)
        })
    }

    const fetch = useCallback(() => {
        setLoading(true)
        setSelectedRowKey(null)
        wxfarm.apiAuth.getList().then(({ data }) => {
            setLoading(false)
            setDataSource(data)
        }).catch(error => {
            onError(error)
            setLoading(false)
        })
    }, [])

    return (
        <Card
            title={'角色列表'}
            className='page-card-full'
            extra={(
                <Space
                    split={<Divider type="vertical" />}
                >
                    <Tooltip title='权限'>
                        <Button
                            type='text'
                            icon={<UserAddOutlined />}
                            disabled={!selectedRowKey}
                            onClick={() => { setOpen(true) }}
                        >
                            权限
                        </Button>
                    </Tooltip>

                    <Tooltip title='刷新'>
                        <Button
                            type='text'
                            icon={<Loading3QuartersOutlined />}
                            onClick={() => fetch()}
                        />
                    </Tooltip>

                </Space>
            )}
        >

            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={dataSource}
                scroll={{ y: window.innerHeight - 388 }}
                loading={loading}
                rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
                    onChange: keys => {
                        setSelectedRowKey(keys[0])
                    }
                }}
                onRow={({ id }) => ({
                    onClick: () => {
                        setSelectedRowKey(selectedRowKey === id ? null : id)
                    }
                })}
                pagination={false}
            />

            <FormDrawer
                menus={menus}
                open={open}
                id={selectedRowKey}
                onClose={() => setOpen(false)}
                onRefresh={() => fetch()}
            />
        </Card>
    )
}
