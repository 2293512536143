import React, { useEffect, useMemo, useState } from 'react';
import { Polygon, FeatureGroup, Tooltip as MapTooltip, useMap } from 'react-leaflet';
import * as turf from '@turf/turf';
import { object } from '../../utils/utils';

const PolygonView = (props) => {
    const { dataSource, maxZoom, defaultValue = [], path = {}, options, selectOptions, editOptions, value = [], editor = null, isFitBounds = false, fillOpacity, setOpacityPolygon, config: { zoom, range } } = props
        , { setMapFeatureGroup, setSelectedRows, setValue, onClick, contextmenu } = props
        , refPolygon = React.useRef(null)
        , [initialization, setInitialization] = useState(false)
        , map = useMap()

    const rangePolygon = useMemo(() => {
        if (range.length) {
            return turf.polygon(range)
        } else {
            return null
        }
    }, [range])

    useEffect(() => {
        const target = refPolygon.current
            , layers = []
        target.getLayers().forEach(group => {
            group.getLayers().forEach(layer => {
                layers.push(layer)
            })
        })
        if (!initialization && isFitBounds && target && layers.length) {
            const bounds = target.getBounds()
            map.fitBounds(bounds, { maxZoom })
            setInitialization(true)
        }
        setMapFeatureGroup((state) => ({
            ...state,
            polygon: { layers, target }
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource, value, initialization, maxZoom])

    const MapPolygon = useMemo(() => {
        if (dataSource) {
            const isObject = object(dataSource)
                , source = isObject ? dataSource : { data: [{ key: 'polygon', data: dataSource }] }
            const {
                data = [],                      // 数据源
                fieldNames = { key: 'key', positions: 'positions' }, // 默认配置字段
                multiple = false,               // 多选
                selectable = true,              // 是否可单击
                displayLevel = undefined,              // 自动显示地块多边形的地图层级
                display = false,                // 自动判断视野内控制显示地块多边
            } = source
            return data.map(row => {
                // 可单击优先级 以行数据优化
                const isSelectable = row.selectable ? true : selectable
                return (
                    <FeatureGroup
                        key={row.key}
                        id={row.key}
                    >
                        {
                            row.data.map(record => {
                                const { measureText = null, tooltip = false } = record
                                    // 默认多边形样式
                                    , defaultPath = record.path ? record.path : row.path ? row.path : options
                                    // 选中多边形样式
                                    , selectedPath = record.selectedPath ? record.selectedPath : row.selectedPath ? row.selectedPath : selectOptions
                                    // 编辑多边形样式
                                    , editPath = record.editPath ? record.editPath : row.editPath ? row.editPath : editOptions
                                    // key
                                    , key = record[fieldNames.key]
                                    // 坐标
                                    , positions = record[fieldNames.positions]
                                    , isCheck = defaultValue.includes(key) || value.includes(key)
                                    // 合并渲染多边形样式
                                    , mergePath = isCheck ? editor?.key === key ? editPath : { ...selectedPath, ...path } : defaultPath
                                    //全局配置可编辑或单行可编辑取值
                                    , ignore = typeof source.ignore === 'undefined' ? record.ignore : source.ignore
                                    // 判断地块多边是否在地图视野内
                                    , isDisplay = display ?
                                        positions.flat()[0].length ? turf.booleanPointInPolygon(turf.point(positions.flat()[0]), rangePolygon) : false :
                                        true


                                return typeof displayLevel === 'undefined' || (isDisplay && zoom >= displayLevel) ? (
                                    <Polygon
                                        key={key}
                                        id={key}
                                        prev={row.key}
                                        type={"Polygon"}
                                        ignore={ignore}
                                        positions={positions}
                                        pathOptions={setOpacityPolygon ? { ...mergePath, fillOpacity: (fillOpacity / 100).toFixed(2) } : { ...mergePath }}
                                        measureText={measureText}
                                        eventHandlers={{
                                            add: ({ target }) => {
                                                if (isCheck) {
                                                    setValue((state) => [...state, key])
                                                    setSelectedRows(state => [...state, target])
                                                }
                                            },
                                            click: ({ target }) => {
                                                if (!isSelectable || editor?.key === key) return
                                                const check = !value.includes(key)
                                                onClick({
                                                    ...record,
                                                    type: 'Polygon',
                                                    target,
                                                    check,
                                                    selectedKeys: check ? [...value, key] : value.filter(v => v !== key)
                                                })
                                                setValue((state) => {
                                                    return multiple ?
                                                        check ? [...state, key] : state.filter(v => v !== key) :
                                                        check ? [key] : []
                                                })

                                                setSelectedRows(state => {
                                                    return multiple ?
                                                        check ? [...state, target] : state.filter(item => item.options.id !== key) :
                                                        check ? [target] : []
                                                })
                                            },
                                            contextmenu: ({ target }) => {
                                                if (!contextmenu) return
                                                if (!isSelectable || editor?.key === key) return
                                                const check = !value.includes(key)
                                                contextmenu({
                                                    ...record,
                                                    type: 'Polygon',
                                                    target,
                                                    check,
                                                    selectedKeys: check ? [...value, key] : value.filter(v => v !== key)
                                                })
                                                setValue((state) => {
                                                    return multiple ?
                                                        check ? [...state, key] : state.filter(v => v !== key) :
                                                        check ? [key] : []
                                                })

                                                setSelectedRows(state => {
                                                    return multiple ?
                                                        check ? [...state, target] : state.filter(item => item.options.id !== key) :
                                                        check ? [target] : []
                                                })
                                            }
                                        }}
                                    >
                                        {
                                            // 鼠标悬停在地块上的提示信息
                                            tooltip ? (
                                                <MapTooltip
                                                    direction="top"
                                                    sticky
                                                >
                                                    <div dangerouslySetInnerHTML={{ __html: tooltip }}></div>
                                                </MapTooltip>
                                            ) : null
                                        }
                                    </Polygon>
                                ) : null
                            })
                        }
                    </FeatureGroup >
                )
            })
        } else {
            return null
        }
    }, [dataSource, rangePolygon, zoom, defaultValue, value, editor, editOptions, options, setSelectedRows, setValue, contextmenu, onClick, path, selectOptions, fillOpacity, setOpacityPolygon])

    return (
        <FeatureGroup
            ref={refPolygon}
            type='Polygon'
        >
            {MapPolygon}
        </FeatureGroup >
    )
}


PolygonView.defaultProps = {
    options: { color: 'red', opacity: .8, fillColor: 'green', fillOpacity: .8, weight: 1 },
    selectOptions: { color: 'red', opacity: 1, fillColor: 'red', fillOpacity: 1, weight: 1 },
    editOptions: { color: '#1677ff', opacity: 1, fillColor: '#1677ff', fillOpacity: 0.5, weight: 1 }
}

export default PolygonView
