import React, { useEffect, useMemo, useState } from 'react';
import { FeatureGroup, Tooltip as MapTooltip, Polyline, useMap } from 'react-leaflet';
import { AntPath } from 'leaflet-ant-path';

const LineView = (props) => {
    const { dataSource, options, selectOptions, editOptions, value = [], editor = null } = props
        , { setMapFeatureGroup } = props
        , [antPath, setAntPath] = useState([])
        , refLine = React.useRef(null)
        , map = useMap()

    // 返回所有图层
    const getLayers = () => {
        const target = refLine.current
            , _layers = []
        target.getLayers().forEach(group => {
            group.getLayers().forEach(layer => {
                _layers.push(layer)
            })
        })
        return _layers
    }

    // 更新图层
    useEffect(() => {
        const target = refLine.current
        setMapFeatureGroup((state) => ({
            ...state,
            line: { layers: getLayers(), target }
        }))

    }, [dataSource.data, setMapFeatureGroup])

    const MapLine = useMemo(() => {
        const { data = [], multiple = false, selectable = true, isArrowHead, fieldNames = { key: 'key', positions: 'positions' } } = dataSource
        return data.map(row => (
            <FeatureGroup
                key={row.key}
                id={row.key}
            >
                {
                    row.data.map(record => {
                        const { tooltip = false } = record
                            // 默认多边形样式
                            , path = record.path ? record.path : row.path ? row.path : options
                            // 选中多边形样式
                            , selectedPath = record.selectedPath ? record.selectedPath : row.selectedPath ? row.selectedPath : selectOptions
                            // 编辑多边形样式
                            , editPath = record.editPath ? record.editPath : row.editPath ? row.editPath : editOptions
                            // key
                            , key = record[fieldNames.key]
                            // 坐标
                            , positions = record[fieldNames.positions]
                            // 是否默认选中
                            , isCheck = value.includes(key)
                            // 渲染多边形样式
                            , pathOptions = isCheck ? editor?.key === key ? editPath : selectedPath : path
                            //全局配置可编辑或单行可编辑取值
                            , ignore = typeof dataSource.ignore === 'undefined' ? record.ignore : dataSource.ignore

                        const handleClick = ({ target }) => {
                            if (!selectable || editor?.key === key) return
                            const check = !value.includes(key)
                            props.onClick({
                                ...record,
                                type: 'Line',
                                target,
                                check
                            })
                            props.setValue((state) => {
                                return multiple ?
                                    check ? [...state, key] : state.filter(v => v !== key) :
                                    check ? [key] : []
                            })

                            props.setSelectedRows(state => {
                                return multiple ?
                                    check ? [...state, target] : state.filter(item => item.options.id !== key) :
                                    check ? [target] : []
                            })
                        }

                        return positions && positions.length && (
                            <Polyline
                                key={key}
                                id={key}
                                prev={row.key}
                                type={"Line"}
                                ignore={ignore}
                                positions={positions}
                                pathOptions={pathOptions}
                                eventHandlers={{
                                    click: handleClick,
                                    add: ({ target }) => {
                                        if (isCheck) {
                                            props.setValue((state) => [...state, key])
                                            props.setSelectedRows(state => [...state, target])
                                        }
                                        if (isArrowHead) {
                                            const _antPath = new AntPath(positions, { color: 'red', delay: 3000 });
                                            setAntPath((state) => ([
                                                ...state,
                                                _antPath
                                            ]))
                                            _antPath.on('click', () => {
                                                handleClick({ target })
                                            }).addTo(map)
                                        }
                                    },
                                    remove: () => {
                                        if (isArrowHead && antPath) {
                                            antPath.forEach(NewClass => {
                                                NewClass.remove()
                                            })
                                        }
                                    }
                                }}
                            >
                                {
                                    // 鼠标悬停在地块上的提示信息
                                    tooltip ? (
                                        <MapTooltip
                                            direction="top"
                                            sticky
                                        >
                                            <div dangerouslySetInnerHTML={{ __html: tooltip }}></div>
                                        </MapTooltip>
                                    ) : null
                                }
                            </Polyline>
                        )
                    })
                }
            </FeatureGroup>
        ))
    }, [dataSource, value, editor, antPath, editOptions, map, props, options, selectOptions])

    return (
        <FeatureGroup
            ref={refLine}
            type='Line'
        >
            {MapLine}
        </FeatureGroup>
    )
}


LineView.defaultProps = {
    options: { color: 'green', opacity: 1, weight: 10 },
    selectOptions: { color: 'red', opacity: 1, weight: 10 },
    editOptions: { color: '#1677ff', opacity: 1, weight: 10 }
}

export default LineView
