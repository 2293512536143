import React, { Component } from 'react';
import { Button, Card, Space, Table, Spin, Tooltip, Divider, Popconfirm, Avatar } from 'antd';
import { LoadingOutlined, Loading3QuartersOutlined, FormOutlined, QuestionCircleOutlined, UserSwitchOutlined, DeleteOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import DropdownColumns from '../../components/dropdown-columns';
import { PAGINATION } from '../../utils/constants';
import { base, role } from '../../service';
import BaseFormDrawer from './base-form';
import PowerFormDrawer from '../role/power';
import { onError, onSuccess } from '../../utils';
import { connect } from 'react-redux';
import dayjs from 'dayjs';

class BaseView extends Component {
    state = {
        queryParams: {
            search: null
        },
        columnsKey: ['name', 'baseCoding', 'principal', 'phone', 'province', 'address'],
        columns: [
            {
                title: '基地名称',
                dataIndex: 'name',
                render: (_, { name, logoSmall }) => (
                    <Space>
                        {
                            logoSmall ? (<Avatar src={this.props.serverUrl + logoSmall} />) : (<Avatar icon={<UserOutlined />} />)
                        }

                        {name}
                    </Space>
                )
            },
            {
                title: '超级用户',
                dataIndex: 'baseCoding',
            },
            {
                title: '负责人',
                dataIndex: 'principal',
            },
            {
                title: '联系电话',
                dataIndex: 'phone'
            },
            {
                title: '省市区',
                dataIndex: 'province',
                render: (_, { province, city, county }) => {
                    return province + city + county
                }
            },
            {
                title: '详细地址',
                dataIndex: 'address'
            },
            {
                title: '创建时间',
                dataIndex: 'createDate',
                render: value => dayjs(value).format('YYYY-MM-DD')
            },
            {
                title: '更新时间',
                dataIndex: 'updateDate',
                render: value => dayjs(value).format('YYYY-MM-DD')
            }
        ],
        dataSource: [],
        selectedRowKey: null,
        openBase: false,
        loading: false,
        pagination: PAGINATION,
        site: [],
        openPower: false,
    }

    componentDidMount() {
        this.fetch()
        this.getSite()
    }

    // 获取当前登录用户角色的系统，菜单列表
    getSite = () => {
        role.api.getSiteList().then(({ data }) => {
            this.setState({
                site: data
            })
        }).catch(error => {
            onError(error)
        })
    }

    fetch = (params = { pagination: PAGINATION }) => {
        this.setState({ loading: true })
        const { current, pageSize } = params.pagination
            , { queryParams } = this.state
        base.api.getList(queryParams, current, pageSize).then(res => {
            const { total, records } = res.data
            this.setState({
                loading: false,
                dataSource: records,
                pagination: {
                    ...PAGINATION,
                    current,
                    pageSize,
                    total: total
                }
            })
        }).catch(error => {
            onError(error)
            this.setState({ loading: false })
        })

    }

    onDelete = (id) => {
        base.api.delete(id).then(({ message }) => {
            onSuccess(message)
            this.fetch()
        }).catch(error => {
            onError(error)
        })
    }


    render() {
        const { dataSource, columns, columnsKey, pagination, loading, selectedRowKey, openBase, site, openPower } = this.state
            , { fetch, onDelete } = this
        return (
            <>
                <Card
                    title="基地管理"
                    className='page-card-full'
                    extra={(
                        <Space
                            split={<Divider type="vertical" />}
                        >
                            <Tooltip title='新增基地信息'>
                                <Button
                                    type='text'
                                    disabled={selectedRowKey}
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                        this.setState({
                                            openBase: true,
                                            selectedRowKey: null
                                        })
                                    }}
                                >
                                    新增
                                </Button>
                            </Tooltip>

                            <Tooltip title='编辑所选中的基地'>
                                <Button
                                    type='text'
                                    icon={<FormOutlined />}
                                    disabled={!selectedRowKey}
                                    onClick={() => {
                                        this.setState({
                                            openBase: true
                                        })
                                    }}
                                >
                                    编辑
                                </Button>
                            </Tooltip>

                            <Popconfirm
                                onConfirm={() => onDelete(selectedRowKey)}
                                title='此操作将永久删除该信息, 是否继续？'
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                disabled={!selectedRowKey}
                            >
                                <Tooltip title='删除所选中的基地'>
                                    <Button
                                        icon={<DeleteOutlined />}
                                        disabled={!selectedRowKey}
                                        type="text"
                                        danger
                                    >
                                        删除
                                    </Button>
                                </Tooltip>
                            </Popconfirm>

                            <Tooltip title='配置选中的基地的权限'>
                                <Button
                                    type='text'
                                    icon={<UserSwitchOutlined />}
                                    disabled={!selectedRowKey}
                                    onClick={() => {
                                        this.setState({
                                            openPower: true
                                        })
                                    }}
                                >
                                    权限
                                </Button>
                            </Tooltip>

                            <Tooltip title='刷新'>
                                <Button
                                    type='text'
                                    icon={<Loading3QuartersOutlined />}
                                    onClick={() => fetch()}
                                />
                            </Tooltip>

                            <DropdownColumns
                                columns={columns}
                                checkedKeys={columnsKey}
                                onChange={columnsKey => this.setState({ columnsKey })}
                            />

                        </Space>
                    )}
                >
                    <Spin
                        indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />}
                        spinning={loading}
                    >
                        <Table
                            rootClassName='table-full'
                            columns={columns.filter(record => columnsKey.includes(record.dataIndex))}
                            rowKey={record => record.id}
                            dataSource={dataSource}
                            pagination={pagination}
                            onChange={(pagination) => fetch({ pagination })}
                            scroll={{ y: window.innerHeight - 388 }}
                            rowSelection={{
                                type: 'checkbox',
                                hideSelectAll: true,
                                selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
                                onChange: keys => {
                                    this.setState({
                                        selectedRowKey: keys[0]
                                    })
                                }
                            }}
                            onRow={({ id }) => ({
                                onClick: () => {
                                    this.setState({
                                        selectedRowKey: selectedRowKey === id ? null : id
                                    })
                                }
                            })}
                        />
                    </Spin>
                </Card>

                <BaseFormDrawer
                    id={selectedRowKey}
                    open={openBase}
                    onClose={() => this.setState({ openBase: false })}
                    onRefresh={() => fetch({ pagination })}
                />

                <PowerFormDrawer
                    id={selectedRowKey ? dataSource.find(item => item.id === selectedRowKey)?.roleId : null}
                    open={openPower}
                    site={site}
                    onClose={() => this.setState({ openPower: false })}
                />

            </>
        )
    }
}


const mapStateToProps = (state) => ({
    serverUrl: state.siteInfo.serverUrl
})

export default connect(mapStateToProps, {})(BaseView)
