import React, { Component } from 'react';
import { Button, Card, Space, Table, Spin, Tooltip, Divider, Popconfirm, Avatar, Flex, Upload } from 'antd';
import { LoadingOutlined, Loading3QuartersOutlined, FormOutlined, CloudUploadOutlined, QuestionCircleOutlined, PlusOutlined, ReadOutlined, UserOutlined, CheckOutlined } from '@ant-design/icons';
import DropdownColumns from '../../components/dropdown-columns';
import { PAGINATION } from '../../utils/constants';
import { user, role } from '../../service';
import { onError, onSuccess } from '../../utils';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import UserFormDrawer from './form';
import { download } from '../../utils/utils';

const stateStr = ['停用', '正常'];
class SimView extends Component {
    state = {
        queryParams: {
            name: null,
            mobile: null
        },
        columnsKey: ['username', 'roleName', 'mobile', 'email', 'isEnabled', 'pwdUpdateDate', 'createDate', 'updateDate'],
        columns: [
            {
                title: '账号',
                dataIndex: 'username',
                render: (_, { username, name, imageUrl }) => {
                    const { serverUrl } = this.props
                    return (
                        <Flex
                            gap={10}
                        >
                            {
                                imageUrl ? (
                                    <Avatar
                                        src={serverUrl + imageUrl}
                                        size="large"
                                    />
                                ) : (
                                    <Avatar
                                        icon={<UserOutlined />}
                                        size="large"
                                    />
                                )
                            }

                            <Flex vertical={true}>
                                <span>{username}</span>
                                <span>{name}</span>
                            </Flex>
                        </Flex>
                    )
                }
            },
            {
                title: '角色',
                dataIndex: 'roleName',
            },
            {
                title: '注册手机号',
                dataIndex: 'mobile',
            },
            {
                title: '邮箱',
                dataIndex: 'email',
            },
            {
                title: '状态',
                dataIndex: 'isEnabled',
                render: isEnabled => stateStr[isEnabled]
            },
            {
                title: '密码更新时间',
                dataIndex: 'pwdUpdateDate',
                render: v => v ? dayjs(v).format('YYYY-MM-DD') : '-'
            },
            {
                title: '创建时间',
                dataIndex: 'createDate',
                render: value => dayjs(value).format('YYYY-MM-DD')
            },
            {
                title: '更新时间',
                dataIndex: 'updateDate',
                render: value => dayjs(value).format('YYYY-MM-DD')
            }
        ],
        dataSource: [],
        selectedRowKey: null,
        open: false,
        openDetail: false,
        loading: false,
        pagination: PAGINATION,
        roleList: [],
    }

    componentDidMount() {
        this.fetch()
        this.getRoleList()
    }

    getRoleList = () => {
        role.api.getList().then(({ data }) => {
            this.setState({
                roleList: data
            })
        }).catch(error => {
            onError(error)
        })
    }


    fetch = (params = { pagination: PAGINATION }) => {
        this.setState({ loading: true })
        const { current, pageSize } = params.pagination
            , { queryParams } = this.state
        user.api.getList(queryParams, current, pageSize).then(res => {
            const { total, records } = res.data
            this.setState({
                loading: false,
                dataSource: records,
                pagination: {
                    ...PAGINATION,
                    current,
                    pageSize,
                    total: total
                }
            })
        }).catch(error => {
            onError(error)
            this.setState({ loading: false })
        })

    }

    onDelete = (id) => {
        this.setState({ loading: true })
        user.api.delete(id).then(({ message }) => {
            onSuccess(message)
            const { pagination } = this.state
            this.fetch({ pagination })
        }).catch(error => {
            onError(error)
            this.setState({ loading: false })
        })
    }

    // 重置密码
    onReset = (id) => {
        this.setState({ loading: true })
        user.api.reset(id).then(({ message }) => {
            onSuccess(message)
            this.setState({ loading: false })
        }).catch(error => {
            onError(error)
            this.setState({ loading: false })
        })
    }

    // 批量导入用户信息
    onImport = (file) => {
        user.api.import(file)
    }


    render() {
        const { dataSource, columns, columnsKey, pagination, loading, selectedRowKey, open, roleList } = this.state
            , { fetch, onDelete, onReset } = this

        return (
            <>
                <Card
                    title="用户管理"
                    className='page-card-full'
                    extra={(
                        <Space
                            split={<Divider type="vertical" />}
                        >
                            <Tooltip title='新增用户信息'>
                                <Button
                                    type='text'
                                    disabled={selectedRowKey}
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                        this.setState({
                                            open: true
                                        })
                                    }}
                                >
                                    新增
                                </Button>
                            </Tooltip>

                            <Upload
                                beforeUpload={async file => {
                                    user.api.import(file).then(blob => {
                                        download(blob, 'error.xlsx')
                                    }).catch(error => {
                                        onError(error)
                                    })
                                }}
                                maxCount={1}
                                name="file"
                                disabled={selectedRowKey}
                                showUploadList={false}
                            >
                                <Button
                                    disabled={selectedRowKey}
                                    icon={<CloudUploadOutlined />}
                                    type='text'
                                >
                                    导入
                                </Button>
                            </Upload>

                            <Tooltip title='编辑所选中的用户'>
                                <Button
                                    type='text'
                                    icon={<FormOutlined />}
                                    disabled={!selectedRowKey}
                                    onClick={() => {
                                        this.setState({
                                            open: true
                                        })
                                    }}
                                >
                                    编辑
                                </Button>
                            </Tooltip>


                            <Tooltip title='查看所选中的用户'>
                                <Button
                                    type='text'
                                    icon={<ReadOutlined />}
                                    disabled={!selectedRowKey}
                                    onClick={() => {
                                        this.setState({
                                            openDetails: true
                                        })
                                    }}
                                >
                                    详情
                                </Button>
                            </Tooltip>

                            <Popconfirm
                                onConfirm={() => onDelete(selectedRowKey)}
                                title='此操作将启用/停用所选中该用户的状态, 是否继续？'
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                disabled={!selectedRowKey}
                            >
                                <Tooltip title='启用/停用所选中的用户'>
                                    <Button
                                        icon={<CheckOutlined />}
                                        disabled={!selectedRowKey}
                                        type="text"
                                        danger
                                    >
                                        启用/停用
                                    </Button>
                                </Tooltip>
                            </Popconfirm>

                            <Popconfirm
                                onConfirm={() => onReset(selectedRowKey)}
                                title='此操作将设置该用户的登录密码为：123456, 是否继续？'
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                disabled={!selectedRowKey}
                            >
                                <Tooltip title='重置该用户登录密码'>
                                    <Button
                                        icon={<CheckOutlined />}
                                        disabled={!selectedRowKey}
                                        type="text"
                                        danger
                                    >
                                        重置密码
                                    </Button>
                                </Tooltip>
                            </Popconfirm>

                            <Tooltip title='刷新'>
                                <Button
                                    type='text'
                                    icon={<Loading3QuartersOutlined />}
                                    onClick={() => fetch()}
                                />
                            </Tooltip>

                            <DropdownColumns
                                columns={columns}
                                checkedKeys={columnsKey}
                                onChange={columnsKey => this.setState({ columnsKey })}
                            />
                        </Space>
                    )}
                >
                    <Spin
                        indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />}
                        spinning={loading}
                    >
                        <Table
                            rootClassName='table-full'
                            columns={columns.filter(record => columnsKey.includes(record.dataIndex))}
                            rowKey={record => record.id}
                            dataSource={dataSource}
                            pagination={pagination}
                            onChange={(pagination) => fetch({ pagination })}
                            scroll={{ y: window.innerHeight - 388 }}
                            rowSelection={{
                                type: 'checkbox',
                                selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
                                onChange: keys => {
                                    this.setState({
                                        selectedRowKey: keys[0]
                                    })
                                }
                            }}
                            onRow={({ id }) => ({
                                onClick: () => {
                                    this.setState({
                                        selectedRowKey: selectedRowKey === id ? null : id
                                    })
                                }
                            })}
                        />
                    </Spin>
                </Card>

                <UserFormDrawer
                    open={open}
                    id={selectedRowKey}
                    roleList={roleList}
                    onClose={() => this.setState({ open: false })}
                    onRefresh={() => fetch({ pagination })}
                />

            </>
        )
    }
}


const mapStateToProps = (state) => ({
    serverUrl: state.siteInfo.serverUrl
})

export default connect(mapStateToProps, {})(SimView)
