import React, { useState } from 'react';
import { Form, Input, Space, Button, Drawer } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { sys } from '../../service';
import { onError, onSuccess } from '../../utils';

export default function SetPasswordDrawer(props) {
    const { open } = props
        , [form] = Form.useForm()
        , title = '设置密码'
        , [loading, setLoading] = useState(false)       // 设置按钮载入状态
    // 关闭
    const onClose = () => {
        props.onClose()
        setLoading(false)
        form.resetFields()
    }

    // 提交表单 => 区分编辑与新增提交
    const onFinish = async (queryParams) => {
        setLoading(true)
        delete queryParams.repPassword
        await sys.apiUser.userPassword(queryParams).then(() => {
            onSuccess('设置密码操作成功')
            onClose()
        }).catch(error => {
            onError(error)
            setLoading(false)
        })
    }

    return (
        <Drawer
            open={open}
            title={title}
            onClose={onClose}
            closable={false}
            extra={
                <Space.Compact>
                    <Button icon={<CloseOutlined />} onClick={onClose}>取消</Button>
                    <Button
                        icon={<CheckOutlined />}
                        type="primary"
                        onClick={() => form.submit()}
                        loading={loading}
                    >
                        保存
                    </Button>
                </Space.Compact>
            }
        >
            <Form
                form={form}
                layout="vertical"
                name="user-form"
                onFinish={onFinish}
            >
                <Form.Item
                    name="originPwd"
                    label="原密码"
                    hasFeedback
                    rules={[{ required: true, message: '原密码不能为空' }]}
                >
                    <Input.Password placeholder="请填写原密码" />
                </Form.Item>

                <Form.Item
                    name="newPwd"
                    label="新密码"
                    hasFeedback
                    rules={[{ required: true, message: '新密码不能为空' }]}
                >
                    <Input.Password placeholder="请填写新密码" />
                </Form.Item>

                <Form.Item
                    name="repPassword"
                    label="确认密码"
                    dependencies={['newPwd']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: '确认密码不能为空',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPwd') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('您输入的新密码不匹配'));
                            }
                        })
                    ]}
                >
                    <Input.Password placeholder="请填写确认密码" />
                </Form.Item>
            </Form>
        </Drawer>
    )
}
