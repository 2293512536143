import React, { useEffect, useState } from 'react';
import { Form, Input, Space, Button, Drawer, Upload } from 'antd';
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { sys } from '../../service';
import { onError, onSuccess } from '../../utils';
import { useSelector } from 'react-redux';

export default function SetInfoDrawer(props) {
    const { open } = props
        , { serverUrl } = useSelector(state => state.siteInfo)
        , [form] = Form.useForm()
        , [fileList, setFileList] = useState([])
        , title = '设置当前用户信息'
        , [loading, setLoading] = useState(false)       // 设置按钮载入状态

    useEffect(() => {
        if (open) {
            sys.apiUser.getUserInfo().then(({ data }) => {
                const { username, name, email, imageUrl, mobile, remark } = data
                if (imageUrl) {
                    const file = {
                        uid: 'imageUrl',
                        name: imageUrl,
                        status: 'done',
                        url: serverUrl + imageUrl
                    }
                    setFileList([file])
                }
                form.setFieldsValue({
                    username, name, email, imageUrl, mobile, remark
                })
            }).catch(error => {
                onError(error)
            })
        }
    }, [open, serverUrl, form])

    // 关闭
    const onClose = () => {
        props.onClose()
        setLoading(false)
        setFileList([])
        form.resetFields()
    }

    // 提交表单 => 区分编辑与新增提交
    const onFinish = async (queryParams) => {
        setLoading(true)
        await sys.apiUser.setUserInfo(queryParams).then(() => {
            onSuccess('设置当前用户信息操作成功')
            onClose()
        }).catch(error => {
            onError(error)
            setLoading(false)
        })
    }

    // 上传事件
    const beforeUpload = async (file) => {
        try {
            const { data: { path } } = await sys.uploadFile(file)
            form.setFieldValue('imageUrl', path)
            setFileList([{ ...file, url: serverUrl + path }])
            return false
        } catch (error) {
            onError(error)
        }
    }

    // 删除图片
    const onRemove = () => {
        setFileList([])
        form.setFieldValue('imageUrl', null)
    }

    return (
        <Drawer
            open={open}
            title={title}
            onClose={onClose}
            closable={false}
            extra={
                <Space.Compact>
                    <Button icon={<CloseOutlined />} onClick={onClose}>取消</Button>
                    <Button
                        icon={<CheckOutlined />}
                        type="primary"
                        onClick={() => form.submit()}
                        loading={loading}
                    >
                        保存
                    </Button>
                </Space.Compact>
            }
        >
            <Form
                form={form}
                layout="vertical"
                name="user-form"
                onFinish={onFinish}
            >
                <Form.Item
                    name="username"
                    label="用户账号"
                    rules={[{ required: true, message: '用户账号不能为空' }]}
                >
                    <Input placeholder="请填写用户账号" />
                </Form.Item>

                <Form.Item
                    name="name"
                    label="用户名"
                    rules={[{ required: true, message: '用户名不能为空' }]}
                >
                    <Input placeholder="请填写用户名" />
                </Form.Item>

                <Form.Item
                    name="email"
                    label="电子邮箱"
                >
                    <Input placeholder="请填写电子邮箱" />
                </Form.Item>

                <Form.Item
                    name="mobile"
                    label="手机"
                >
                    <Input placeholder="请填写手机" />
                </Form.Item>

                <Form.Item
                    label="头像"
                >
                    <Upload
                        beforeUpload={(file) => beforeUpload(file)}
                        listType="picture-card"
                        maxCount={1}
                        name="file"
                        fileList={fileList}
                        onRemove={() => onRemove()}
                    >
                        <div>
                            <PlusOutlined style={{ fontSize: 40, color: '#ccc' }} />
                        </div>
                    </Upload>
                </Form.Item>

                <Form.Item
                    name="remark"
                    label="备注"
                >
                    <Input.TextArea style={{ height: 150 }} placeholder="请填写" />
                </Form.Item>

                <Form.Item
                    name="imageUrl"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Drawer>
    )
}
