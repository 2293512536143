import React, { useEffect, useMemo, useState } from 'react';
import { Space, Button, Drawer, Spin, Divider, Tabs, Tree } from 'antd';
import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { role } from '../../service';
import { onSuccess, onError } from '../../utils';


export default function RolePowerDrawer(props) {
    const { open, id, site = [], onClose } = props
        , [spinning, setSpinning] = useState(false)     // 设置获取数据载入状态
        , [loading, setLoading] = useState(false)       // 设置按钮载入状态
        , [activeKey, setActiveKey] = useState(null)
        , [values, setValues] = useState([])


    useEffect(() => {
        if (open && id) {
            setSpinning(true)
            if (site.length) {
                setActiveKey(site[0].siteId)
            }
            // 当前角色已选的配置
            role.api.getRoleValue(id).then(({ data }) => {
                setValues(data)
                setSpinning(false)
            }).catch(error => {
                onError(error)
                setSpinning(false)
            })
        }
    }, [open, id, site])


    const treeData = useMemo(() => {
        if (activeKey) {
            const row = site.find(item => item.siteId === activeKey)
            return row.children
        } else {
            return []
        }
    }, [activeKey, site])


    const checkedKeys = useMemo(() => {
        if (treeData.length && values.length) {
            const record = values.find(item => item.siteId === activeKey)
            return record ? record.menuList : []
        } else {
            return []
        }
    }, [treeData, values, activeKey])

    const onFinish = () => {
        setLoading(true)
        role.api.setRoleValue(values).then(({ message }) => {
            onSuccess(message)
            setLoading(false)
            props.onClose()
        }).catch(error => {
            onError(error)
            setLoading(false)
        })
    }


    return (
        <Drawer
            title={'设置角色权限'}
            open={open}
            onClose={onClose}
            closable={false}
            destroyOnClose={true}
            extra={
                <Space
                    split={<Divider type="vertical" />}
                >
                    <Space.Compact>
                        <Button
                            icon={<CloseOutlined />}
                            onClick={onClose}
                        >
                            取消
                        </Button>

                        <Button
                            icon={<CheckOutlined />}
                            type="primary"
                            onClick={onFinish}
                            loading={loading}
                        >
                            保存
                        </Button>
                    </Space.Compact>
                </Space>
            }
        >
            <Spin
                indicator={<LoadingOutlined style={{ fontSize: 36 }} />}
                spinning={spinning}
            >
                <Tabs
                    tabPosition='left'
                    activeKey={activeKey}
                    onChange={setActiveKey}
                    items={
                        site.map(record => {
                            const children = (
                                <Tree
                                    treeData={treeData}
                                    checkable={true}
                                    autoExpandParent={true}
                                    fieldNames={{ title: 'name', key: 'id', children: 'children' }}
                                    checkedKeys={checkedKeys}
                                    onCheck={(checkedKeys) => {
                                        setValues((state) => {
                                            const isIn = values.find(item => item.siteId === activeKey)
                                                , _values = isIn ?
                                                    state.map(item => item.siteId === activeKey ? { ...item, menuList: checkedKeys } : item) :
                                                    [...state, { roleId: id, siteId: activeKey, menuList: checkedKeys }]
                                            return _values
                                        })
                                    }}
                                    rootStyle={{ height: window.innerHeight - 48 - 65 }}
                                />
                            )
                            return {
                                label: record.siteName,
                                key: record.siteId,
                                children
                            }
                        })
                    }
                />
            </Spin>
        </Drawer>
    )
}
