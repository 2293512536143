import request from "../request";


const api = {
    getList: (queryParams, current = 1, pageSize = 10) => request.post('/v1/sys/user/list', { ...queryParams, current, pageSize }),
    getById: (id) => request.get(`/v1/sys/user`, { params: { id } }),
    add: (queryParams) => request.post('/v1/sys/user/save', queryParams),
    edit: (queryParams) => request.put('/v1/sys/user', queryParams),
    delete: (id) => request.delete(`/v1/sys/user/${id}`),
    // 根据id重置密码
    reset: (id) => request.put('/v1/sys/user/reset', { id }),
    // 批量导入用户信息
    import: (file) => request.uploadFile('/v1/sys/user/import', { file, responseType: 'blob' })
}


const user = {
    api
}

export default user
