import React, { useMemo } from 'react';
import { LayerGroup, SVGOverlay } from 'react-leaflet';
import { calculateArea } from '../../utils/utils';


export default function MeasureView(props) {
    const { dataSource, visible } = props
    // 获取多边形或线的坐标组
    const getLatLngs = (target) => typeof target.getLatLngs === 'function' ? target.getLatLngs() : target.getLatLng()

    const MapMeasure = useMemo(() => {
        // console.log('MapMeasure', dataSource)
        // 显示多边形面积，线的两端距离
        return dataSource.map((target) => {
            const { measureText } = target.options
                , latLngs = getLatLngs(target)
                , bounds = target.getBounds()
                // , areaText = area ? area : calculateArea(latLngs[0])
            // console.log(target, measureText, bounds)
            return (
                <SVGOverlay
                    key={target._leaflet_id}
                    bounds={bounds}
                >
                    <text
                        x="50%"
                        y="50%"
                        className='measurements-text'
                    >
                        <tspan dx="-20" x="50%" y="45%" dominantBaseline="start">{measureText}</tspan>
                        <tspan dx="-20" x="50%" dy="20" dominantBaseline="end">{calculateArea(latLngs[0])} 亩</tspan>
                    </text>
                </SVGOverlay>
            )
        })
    }, [dataSource])

    return visible ? (
        <LayerGroup type="Measure">
            {MapMeasure}
        </LayerGroup>
    ) : null
}

// type === 'Line' ? (
//     latLngs.map((latLng, index) => {
//         return index > 0 && (
//             <SVGOverlay
//                 key={`${target._leaflet_id}-${index}`}
//                 bounds={L.latLngBounds(latLngs[index - 1], latLng)}
//             >
//                 <text
//                     x="50%"
//                     dx="-25"
//                     y="50%"
//                     dy="5"
//                     className='measurements-text'
//                     style={{
//                         transform: `rotate(${getRotation(latLngs[index - 1], latLng)}rad)`
//                     }}
//                 >
//                     {map.distance(latLngs[index - 1], latLng).toFixed(2)} 米
//                 </text>
//             </SVGOverlay>
//         )
//     })
// ) : null
