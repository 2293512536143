import React, { useEffect, useState, useCallback } from 'react'
import {
    Button,
    Card,
    Space,
    Table,
    Spin,
    Tooltip,
    Divider,
    Popconfirm
} from 'antd';
import {
    FormOutlined,
    DeleteOutlined,
    PlusOutlined,
    Loading3QuartersOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { wxfarm } from "../../../service";
import { onError, onSuccess } from '../../../utils';
import dayjs from 'dayjs';
import FormDrawer from './form'


export default function WxFarmMenu() {
    const [dataSource, setDataSource] = useState([])
        , [loading, setLoading] = useState(false)
        , [open, setOpen] = useState(false)
        , [selectedRowKey, setSelectedRowKey] = useState(null)
        , columns = [
            {
                title: '菜单名称',
                dataIndex: 'name',
            },
            {
                title: '路由地址',
                dataIndex: 'url',
            },
            {
                title: 'CODE',
                dataIndex: 'code',
            },
            {
                title: '状态',
                dataIndex: 'state',
                render: state => {
                    return state === 0 ? '停用' : '正常'
                }
            },
            // {
            //     title: '类型',
            //     dataIndex: 'type',
            // },
            {
                title: '排序',
                dataIndex: 'sort'
            },
        ]

    useEffect(() => {
        fetch()
    }, [])

    const fetch = useCallback(() => {
        setLoading(true)
        setSelectedRowKey(null)
        wxfarm.apiMenu.getList().then(({ data }) => {
            setLoading(false)
            setDataSource(data)
        }).catch(error => {
            onError(error)
            setLoading(false)
        })
    }, [])

    const onDelete = async (id) => {
        try {
            const { message } = await wxfarm.apiMenu.delete(id)
            onSuccess(message)
            fetch()
        } catch (error) {
            onError(error)
        }
    }

    return (
        <Card
            title={'菜单列表'}
            className='page-card-full'
            extra={(
                <Space
                    split={<Divider type="vertical" />}
                >
                    <Tooltip title='新增'>
                        <Button
                            type='text'
                            icon={<PlusOutlined />}
                            onClick={() => { setOpen(true) }}
                            disabled={selectedRowKey}
                        >
                            新增
                        </Button>
                    </Tooltip>

                    <Tooltip title='编辑'>
                        <Button
                            type='text'
                            icon={<FormOutlined />}
                            disabled={!selectedRowKey}
                            onClick={() => { setOpen(true) }}
                        >
                            编辑
                        </Button>
                    </Tooltip>

                    <Popconfirm
                        onConfirm={() => onDelete(selectedRowKey)}
                        title='此操作将永久删除, 是否继续？'
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        disabled={!selectedRowKey}
                        placement="bottomRight"
                    >
                        <Tooltip title='删除'>
                            <Button
                                icon={<DeleteOutlined />}
                                disabled={!selectedRowKey}
                                type="text"
                                danger
                            >
                                删除
                            </Button>
                        </Tooltip>
                    </Popconfirm>

                    <Tooltip title='刷新'>
                        <Button
                            type='text'
                            icon={<Loading3QuartersOutlined />}
                            onClick={() => fetch()}
                        />
                    </Tooltip>

                </Space>
            )}
        >

            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={dataSource}
                scroll={{ y: window.innerHeight - 388 }}
                loading={loading}
                rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
                    onChange: keys => {
                        setSelectedRowKey(keys[0])
                    }
                }}
                onRow={({ id }) => ({
                    onClick: () => {
                        setSelectedRowKey(selectedRowKey === id ? null : id)
                    }
                })}
                pagination={false}
            />

            <FormDrawer
                open={open}
                id={selectedRowKey}
                onClose={() => setOpen(false)}
                onRefresh={() => fetch()}
            />
        </Card>
    )
}
