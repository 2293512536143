import { createSlice } from "@reduxjs/toolkit";

const auth = sessionStorage.getItem("auth")
    , defaultAuth = { uid: null, name: null, imageUrl: null, mapKey: null, token: null, site: [], baseInfo: [], base: {}, code: null }
export const AuthSlice = createSlice({
    name: 'auth',
    initialState: auth ? JSON.parse(auth) : defaultAuth,
    reducers: {
        setAuth(state, { payload }) {
            const { uid, name, imageUrl, mapKey, token, site, baseInfo, base, code } = payload
            state.uid = uid
            state.name = name
            state.imageUrl = imageUrl
            state.mapKey = mapKey
            state.token = token
            state.site = site
            state.baseInfo = baseInfo
            state.base = base
            state.code = code
            sessionStorage.setItem("auth", JSON.stringify(payload))
        },
        setBase(state, { payload }) {
            state.base = payload.base
            sessionStorage.setItem("auth", JSON.stringify({ ...JSON.parse(sessionStorage.getItem("auth")), ...payload }))
            window.location.reload()
        },
        clear(state) {
            const { uid, name, imageUrl, mapKey, token, site, baseInfo, base, code } = defaultAuth
            state.uid = uid
            state.name = name
            state.imageUrl = imageUrl
            state.mapKey = mapKey
            state.token = token
            state.site = site
            state.baseInfo = baseInfo
            state.base = base
            state.code = code
            sessionStorage.setItem("auth", JSON.stringify(defaultAuth))
        }
    }
})

// 导出action函数
export const { setAuth, setBase, clear } = AuthSlice.actions


// 导出  reducer ， 创建store
export default AuthSlice.reducer
