import React, { useState, useEffect } from 'react';
import { Space, Button, Drawer, Spin, Form, Input, Upload, Divider, Cascader, InputNumber, Flex } from 'antd';
import { CheckOutlined, CloseOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { base, sys } from '../../service';
import provinces from "china-division/dist/provinces";
import cities from "china-division/dist/cities";
import areas from "china-division/dist/areas";
import MapView from '../../components/map';
import { onSuccess, onError } from '../../utils';
import { useSelector } from 'react-redux';

export default function BaseFormDrawer(props) {
    const { open, id } = props
        , mapRef = React.useRef(null)
        , [spinning, setSpinning] = useState(false)     // 设置获取数据载入状态
        , [loading, setLoading] = useState(false)       // 设置按钮载入状态
        , [form] = Form.useForm()
        , [fileList, setFileList] = useState({ logoBig: [], logoSmall: [] })
        , { serverUrl } = useSelector(state => state.siteInfo)
        , [marker, setMarker] = useState(false)

    useEffect(() => {
        if (open) {
            if (id) {
                // 编辑时获取信息
                setSpinning(true)
                base.api.getById(id)
                    .then(({ data: { name, principal, phone, province, city, county, address, logoBig, logoSmall, mapUrl, center, zoom, minZoom, maxZoom, opacityPolygon } }) => {
                        form.setFieldsValue({
                            id,
                            name,
                            principal,
                            phone,
                            cascader: [province, city, county],
                            province,
                            city,
                            county,
                            address,
                            logoBig,
                            logoSmall,
                            mapUrl,
                            center,
                            zoom,
                            minZoom,
                            maxZoom,
                            opacityPolygon
                        })
                        // 大 LOGO
                        if (logoBig) {
                            const file = {
                                uid: 'big',
                                name: logoBig,
                                status: 'done',
                                url: serverUrl + logoBig
                            }
                            setFileList((state) => ({
                                ...state,
                                logoBig: [file]
                            }))
                        }
                        // 小 LOGO
                        if (logoSmall) {
                            const file = {
                                uid: 'small',
                                name: logoSmall,
                                status: 'done',
                                url: serverUrl + logoSmall
                            }
                            setFileList((state) => ({
                                ...state,
                                logoSmall: [file]
                            }))
                        }
                        setMarker({ data: [{ key: 'baseMarker', data: [{ key: id, name, permanent: true, positions: center, ignore: false }] }] })
                        mapRef.current.flyTo(center, 5)
                        setSpinning(false)
                    })
                    .catch(error => {
                        onError(error)
                        setSpinning(false)
                    })
            }
        }
    }, [open, id, form, serverUrl])

    // 关闭窗口
    const onClose = () => {
        form.resetFields()
        setLoading(false)
        setFileList({ big: [], small: [] })
        setMarker(false)
        props.onClose()
    }

    // 提交表单
    const onFinish = (values) => {
        if (!values.center) {
            onError('基地的地理位置不能为空！')
            return
        }
        delete values.cascader
        setLoading(true)
        const promise = id ? base.api.edit(values) : base.api.add(values)
        promise.then(({ message }) => {
            onSuccess(message)
            props.onRefresh()
            onClose()
        }).catch(error => {
            onError(error)
            setLoading(false)
        })
    }

    const options = provinces.map((province) => ({ ...province, children: cities.filter(citie => province.code === citie.provinceCode).map(citie => ({ ...citie, children: areas.filter(area => area.cityCode === citie.code) })) }))

    // 上传事件
    const beforeUpload = async (file, field) => {
        try {
            const { data: { path } } = await sys.uploadFile(file)
            form.setFieldValue(field, path)
            setFileList((state) => {
                let _state = { ...state }
                _state[field] = [{ ...file, url: serverUrl + path }]
                return _state
            })
            return false
        } catch (error) {
            onError(error)
        }
    }

    // 删除图片
    const onRemove = (field) => {
        setFileList((state) => {
            let _state = { ...state }
            _state[field] = []
            return _state
        })
        form.setFieldValue(field, null)
    }

    return (
        <Drawer
            title={id ? '编辑' : '新增'}
            open={open}
            onClose={onClose}
            closable={false}
            destroyOnClose={true}
            size="large"
            extra={
                <Space
                    split={<Divider type="vertical" />}
                >
                    <Space.Compact>
                        <Button
                            icon={<CloseOutlined />}
                            onClick={onClose}
                        >
                            取消
                        </Button>

                        <Button
                            icon={<CheckOutlined />}
                            type="primary"
                            onClick={() => form.submit()}
                            loading={loading}
                        >
                            保存
                        </Button>
                    </Space.Compact>
                </Space>
            }
        >
            <Spin
                indicator={<LoadingOutlined style={{ fontSize: 36 }} />}
                spinning={spinning}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="base.form"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="name"
                        label="名称"
                        rules={[{ required: true, message: '基地名称不能为空' }]}
                    >
                        <Input placeholder="请填写基地名称" />
                    </Form.Item>

                    <Form.Item noStyle>
                        <Flex justify='space-between'>
                            <Form.Item
                                name="principal"
                                label="负责人"
                                style={{ width: 'calc(50% - 8px)', marginRight: 8 }}
                            >
                                <Input placeholder="请填写负责人姓名" />
                            </Form.Item>

                            <Form.Item
                                name="phone"
                                label="联系电话"
                                style={{ width: 'calc(50% - 8px)', marginLeft: 8 }}
                            >
                                <Input placeholder="请填写负责人联系电话" />
                            </Form.Item>
                        </Flex>
                    </Form.Item>

                    <Form.Item noStyle>
                        <Flex justify='space-between'>
                            <Form.Item
                                name="cascader"
                                label="省市区"
                                rules={[{ required: true, message: '基地地区不能为空' }]}
                                style={{ width: 'calc(50% - 8px)', marginRight: 8 }}
                            >
                                <Cascader
                                    placeholder='请选择'
                                    options={options}
                                    fieldNames={{ label: 'name', value: 'name' }}
                                    onChange={([province, city, county]) => {
                                        form.setFieldsValue({
                                            province, city, county
                                        })
                                    }}
                                />
                            </Form.Item>

                            <Form.Item
                                name="address"
                                label="详细地址"
                                rules={[{ required: true, message: '基地详细地址不能为空' }]}
                                style={{ width: 'calc(50% - 8px)', marginLeft: 8 }}
                            >
                                <Input placeholder="请填写基地详细地址" />
                            </Form.Item>
                        </Flex>
                    </Form.Item>

                    <MapView
                        required={true}
                        ref={mapRef}
                        height="400px"
                        title="地理位置"
                        id="base-map"
                        marker={marker}
                        mapStyle={{ marginBottom: 70 }}
                        eable={true}
                        isDel={false}
                        addItems={marker ? [] : [{ key: 'baseMarker', label: '标注', type: 'Marker', icon: 'RadiusSettingOutlined' }]}
                        onSave={({ value }) => {
                            form.setFieldValue('center', value)
                        }}
                        onCreate={({ value }) => {
                            setMarker({ data: [{ key: 'baseMarker', data: [{ key: id, name: form.getFieldValue('name'), permanent: true, positions: value, ignore: false }] }] })
                            form.setFieldValue('center', value)
                        }}
                    />

                    <Form.Item
                        name="mapUrl"
                        label="图片服务"
                        help="瓦片实景图FTP地址"
                        style={{ marginBottom: 32 }}
                    >
                        <Input placeholder="请填写" />
                    </Form.Item>

                    <Form.Item noStyle>
                        <Flex justify='space-between' style={{ marginBottom: 16 }}>
                            <Form.Item
                                name="zoom"
                                label="缩放等级"
                                help="地图初始化时的缩放等级"
                                rules={[{ required: true }]}
                            >
                                <InputNumber placeholder="请填写" />
                            </Form.Item>

                            <Form.Item
                                name="minZoom"
                                label="最小缩放"
                                help="地图的最小缩放级别"
                                rules={[{ required: true }]}
                            >
                                <InputNumber placeholder="请填写" />
                            </Form.Item>

                            <Form.Item
                                name="maxZoom"
                                label="最大缩放"
                                help="地图的最大缩放级别"
                                rules={[{ required: true }]}
                            >
                                <InputNumber placeholder="请填写" />
                            </Form.Item>
                        </Flex>
                    </Form.Item>

                    <Form.Item
                        name="opacityPolygon"
                        label="透明度"
                        help="地块多边形填充的不透明度"
                        style={{ marginBottom: 32 }}
                    >
                        <InputNumber />
                    </Form.Item>

                    <Form.Item noStyle>
                        <Space.Compact block>
                            <Form.Item
                                label="基地LOGO"
                                help="大尺寸"
                                style={{ width: '50%' }}
                            >
                                <Upload
                                    beforeUpload={(file) => beforeUpload(file, 'logoBig')}
                                    listType="picture-card"
                                    maxCount={1}
                                    name="file"
                                    fileList={fileList.logoBig}
                                    onRemove={() => onRemove('logoBig')}
                                >
                                    <div>
                                        <PlusOutlined style={{ fontSize: 40, color: '#ccc' }} />
                                    </div>
                                </Upload>
                            </Form.Item>

                            <Form.Item
                                label=" "
                                help="小尺寸"
                                style={{ width: '50%' }}
                            >
                                <Upload
                                    beforeUpload={(file) => beforeUpload(file, 'logoSmall')}
                                    listType="picture-card"
                                    maxCount={1}
                                    name="file"
                                    fileList={fileList.logoSmall}
                                    onRemove={() => onRemove('logoSmall')}
                                >
                                    <div>
                                        <PlusOutlined style={{ fontSize: 40, color: '#ccc' }} />
                                    </div>
                                </Upload>
                            </Form.Item>
                        </Space.Compact>
                    </Form.Item>

                    <Form.Item name="center" hidden={true}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="logoBig" hidden={true}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="logoSmall" hidden={true}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="province"
                        hidden={true}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="city"
                        hidden={true}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="county"
                        hidden={true}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="id"
                        hidden={true}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Spin>
        </Drawer>
    )
}
