import request from "../request";

const api = {
    getList: (siteId) => request.post('/v1/sys/menu', { siteId }),
    getById: (id) => request.get(`/v1/sys/menu/${id}`),
    add: (queryParams) => request.post('/v1/sys/menu/save', queryParams),
    edit: (queryParams) => request.put('/v1/sys/menu', queryParams),
    delete: (id) => request.delete(`/v1/sys/menu/${id}`)
}



const menu = {
    api
}

export default menu
