import React, { useMemo, useEffect } from 'react';
import { FeatureGroup, Polyline, Marker, useMap, Tooltip } from 'react-leaflet';
import * as turf from '@turf/turf';
import L from "leaflet";

const LineView = (props) => {
    const { dataSource = [], value = [], setMapFeatureGroup, config: { zoom, range } } = props
        , refLine = React.useRef(null)
        , map = useMap()

    const rangePolygon = useMemo(() => {
        if (range.length) {
            return turf.polygon(range)
        } else {
            return null
        }
    }, [range])

    // 更新图层
    useEffect(() => {
        const target = refLine.current
            // 返回所有图层
            , layers = []
        target.getLayers().forEach(group => {
            group.getLayers().forEach(layer => {
                layers.push(layer)
            })
        })
        if (layers.length) {
            map.fitBounds(target.getBounds())
        }
        setMapFeatureGroup((state) => ({
            ...state,
            trajectory: { layers, target }
        }))
    }, [dataSource, value, setMapFeatureGroup, map])

    const MapLine = useMemo(() => {
        return rangePolygon && dataSource && dataSource.length ? dataSource.map(record => {
            const { name, imei, isFlip, children, displayLevel = 15 } = record
            // 按设备遍历
            return JSON.stringify(value).includes(imei) ? (
                <FeatureGroup
                    key={imei}
                    id={imei}
                >
                    {
                        // 设备 按日期遍历 
                        children.map(item => {
                            const positions = [[], []]
                            let start = [], end = []

                            if (value.includes(item.imei)) {
                                item.coords.forEach((({ state, coords }, index) => {
                                    if (index === 0) {
                                        start = coords[0]
                                    }
                                    if (index + 1 === item.coords.length) {
                                        end = [...coords].pop()
                                    }
                                    // 判断轨迹是否在地图视野内
                                    if (turf.booleanPointInPolygon(turf.point(coords[0]), rangePolygon)) {
                                        positions[state].push(coords)
                                    }
                                }))
                            }

                            const validPositions = positions[isFlip ? 1 : 0]
                                , invalidPositions = positions[isFlip ? 0 : 1]

                            return value.includes(item.imei) ? (
                                <FeatureGroup
                                    key={item.imei}
                                    id={item.imei}
                                >
                                    {
                                        start.length ? (
                                            <Marker
                                                position={start}
                                                icon={L.icon({ iconUrl: require('./image/start.png'), iconSize: [48, 48], iconAnchor: [24, 24] })}
                                            >
                                                <Tooltip
                                                    direction="top"
                                                    permanent={true}
                                                    offset={[0, -16]}
                                                >
                                                    <div dangerouslySetInnerHTML={{ __html: `<p>${name}</p> <p>起点：${item.date}</p>` }}></div>
                                                </Tooltip>
                                            </Marker>
                                        ) : null
                                    }

                                    {
                                        zoom >= displayLevel && validPositions.flat().length ? (
                                            <Polyline
                                                positions={validPositions}
                                                pathOptions={{ color: '#f5222d' }}
                                                eventHandlers={{
                                                    add: () => {

                                                    }
                                                }}
                                            />
                                        ) : null
                                    }


                                    {
                                        zoom >= displayLevel && invalidPositions.flat().length ? (
                                            <Polyline
                                                positions={invalidPositions}
                                                pathOptions={{ color: '#a0d911' }}
                                                eventHandlers={{
                                                    add: () => {

                                                    }
                                                }}
                                            />
                                        ) : null
                                    }



                                    {
                                        end.length ? (
                                            <Marker
                                                position={end}
                                                icon={L.icon({ iconUrl: require('./image/end.png'), iconSize: [48, 48], iconAnchor: [24, 24] })}
                                            >
                                                <Tooltip
                                                    direction="top"
                                                    permanent={true}
                                                    offset={[0, -16]}
                                                >
                                                    <div dangerouslySetInnerHTML={{ __html: `<p>${name}</p><p>终点：${item.date}</p>` }}></div>
                                                </Tooltip>
                                            </Marker>
                                        ) : null
                                    }
                                </FeatureGroup>
                            ) : null
                        })
                    }
                </FeatureGroup>
            ) : null
        }) : null
    }, [dataSource, value, rangePolygon, zoom])

    return (
        <FeatureGroup
            ref={refLine}
            type='Line'
        >
            {MapLine}
        </FeatureGroup>
    )
}


LineView.defaultProps = {
    options: { color: 'green', opacity: 1, weight: 10 },
    selectOptions: { color: 'red', opacity: 1, weight: 10 },
    editOptions: { color: '#1677ff', opacity: 1, weight: 10 }
}

export default LineView
