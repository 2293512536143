import request from "../request";

const api = {
    getList: () => request.get('/v1/sys/color/card/search'),
    edit: (queryParams) => request.put('/v1/sys/color/card', queryParams),
}



const mapConfig = {
    api
}

export default mapConfig
