import request from "../request";

const apiNews = {
    getList: (queryParams) => request.post('/v1/sys/web/news/search', queryParams),
    getByID: (id) => request.get(`/v1/sys/web/news/${id}`),
    add: (queryParams) => request.post('/v1/sys/web/news', queryParams),
    edit: (queryParams) => request.put('/v1/sys/web/news', queryParams),
    delete: (id) => request.delete(`/v1/sys/web/news/${id}`)
}

const website = {
    apiNews
}

export default website;
