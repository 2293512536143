import React, { useLayoutEffect } from 'react';
import { NavBar, Button } from 'antd-mobile';
import { SkinOutlined } from '@ant-design/icons';
import { useConfigContextAtom } from '../../store/config';

export default function NavBarView() {
    const [config, setConfigContext] = useConfigContextAtom()

    useLayoutEffect(() => {
        document.documentElement.setAttribute(
            'data-prefers-color-scheme',
            config.algorithm
        )
    }, [config.algorithm])
    

    return (
        <NavBar
            back={null}
            right={(
                <Button
                    fill='none'
                    onClick={() => {
                        setConfigContext((state) => ({
                            ...state,
                            algorithm: state.algorithm === 'dark' ? 'light' : 'dark'
                        }))
                    }}
                >
                    <SkinOutlined />
                </Button>
            )}
        >
            首页
        </NavBar>
    )
}
