import React, { useMemo } from 'react';
import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const BreadcrumbView = () => {
    const { pathname } = useLocation()
        , { userMenu } = useSelector(state => state.menu)
        , { site } = useSelector(state => state.auth)
        , { code } = useSelector(state => state.siteInfo)
        , record = site.find(item => item.code === code)

    const items = useMemo(() => {
        const [, heading = null, subheading = null] = pathname.split("/")
            , items = [
                {
                    title: (
                        <>
                            <HomeOutlined style={{ fontSize: 18 }} />
                            <Link to={'/'} >{record.name}</Link>
                        </>
                    )
                }
            ]

        if (heading) {
            const record = userMenu.find(item => item.url.includes(heading))
            if (record) {
                items.push({
                    title: record.name
                })
                if (subheading) {
                    const row = record.children.find(item => item.url === pathname)
                    items.push({
                        title: row.name
                    })
                }
            }
        }

        return items
    }, [pathname, userMenu, record])


    return (
        <Breadcrumb
            style={{
                margin: '16px 0',
            }}
            items={items}
        />
    )
}


BreadcrumbView.defaultProps = {
    siteName: "有农数字农业管理系统"
}


export default BreadcrumbView
