import request from "../request";

const api = {
    getList: () => request.post('/v1/sys/role/search'),
    getById: (id) => request.get(`/v1/sys/role/${id}`),
    add: (queryParams) => request.post('/v1/sys/role/save', queryParams),
    edit: (queryParams) => request.put('/v1/sys/role', queryParams),
    delete: (id) => request.delete(`/v1/sys/role/${id}`),
    // 获取当前登录用户角色的系统，菜单列表
    getSiteList: () => request.get('/v1/sys/role/user/site'),
    // 根据roleId获取角色的菜单选择信息
    getRoleValue: (id) => request.get('/v1/sys/role/role', { params: { id } }),
    // 保存角色的菜单选择信息
    setRoleValue: (queryParams) => request.post('/v1/sys/role/saveOrUpdate/role', queryParams),

    // 查询所有小程序
    getWxapp: () => request.get('/v1/stats/app/search'),
}



const role = {
    api,
}

export default role
