import request from "../request";


const apiAuth = {
    login: (queryParams) => request.post('/v1/sys/auth/login', queryParams),
    config: () => request.get(`/v1/sys/config`),
    tokenLogin: (token) => request.post('/v1/sys/auth/sign/login', { token })
}


/**
 * 上传
 * @param {*} file 
 * @returns 
 */
const uploadFile = (file) => request.uploadFile('/v1/sys/file/upload', { file })

const apiUser = {
    userPassword: (queryParams) => request.post('/v1/sys/info/change/pwd', queryParams),
    getUserInfo: () => request.get('/v1/sys/info/basic/info'),
    setUserInfo: (queryParams) => request.put('/v1/sys/info/change/info', queryParams)
}

const getDocs = (menuUrl) => request.post(`/v1/sys/menu/docs/url`, { menuUrl })

const sys = {
    apiAuth,
    uploadFile,
    apiUser,
    getDocs
}

export default sys

