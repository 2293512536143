import React, { useState, useEffect, useRef, useMemo } from 'react';
import ReactQuill from 'react-quill';
import { useSelector } from 'react-redux';
import { message, theme } from 'antd';
import 'react-quill/dist/quill.snow.css';
import { sys } from '../../service';
// import { AppContext } from '../../store/context';
import './index.scss';

const { useToken } = theme;
export default function QuillItem({ value, width, height, placeholder, handleParams }) {
    const [valueText, setValue] = useState('')
        , { token: { colorText, borderRadius, colorBorder } } = useToken()
        , { serverUrl } = useSelector(state => state.siteInfo)
    let refs = useRef(null);
    const modules = useMemo(() => ({
        toolbar: {
            container: [
                ['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
                // ['blockquote', 'code-block'], // 引用，代码块
                ['link', 'image' /**'video' */], // 上传链接、图片、上传视频
                [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
                [{ list: 'ordered' }, { list: 'bullet' }], // 列表
                // [{ script: 'sub' }, { script: 'super' }], // 上下标
                [{ indent: '-1' }, { indent: '+1' }], // 缩进
                // [{ direction: 'rtl' }], // 文本方向
                [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
                [{ header: [1, 2, 3, 4, 5, 6, false] }], // 几级标题
                [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
                [{ font: [] }], // 字体
                [{ align: [] }], // 对齐方式
                ['clean'], // 清除字体样式
            ],
            handlers: {
                image: () => {
                    const input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');
                    input.click();
                    input.onchange = async () => {
                        const hide = message.loading('上传中...', 0);
                        try {
                            const { data: { path } } = await sys.uploadFile(input.files[0])
                            let quill = refs?.current?.getEditor(); //获取到编辑器本身
                            const cursorPosition = quill.getSelection().index; //获取当前光标位置
                            quill.insertEmbed(cursorPosition, 'image', serverUrl + path);
                            quill.setSelection(cursorPosition + 1); //光标位置加1
                            hide();
                        } catch (error) {
                            console.log(error)
                            hide()
                        }
                    }
                },
            },
        },
    }), [serverUrl]);


    const onChange = (e) => {
        setValue(e);
        handleParams(e);
    };

    const options = {
        placeholder,
        theme: 'snow',
        className: 'ql-editor', //组件要加上(className=“ql-editor”)样式类名,否则空格不回显
        value: valueText,
        modules: modules,
        ref: refs,
        style: {
            width,
            height,
            minHeight: height,
            overflow: 'hidden',
            padding: 0,
            borderRadius,
            '--colorBorder': colorBorder,
            '--colorText': colorText
        },
        onChange: onChange
    }


    useEffect(() => {
        setValue(value ? value : '')
    }, [value]);

    return (
        <div>
            <ReactQuill
                {...options}
            />
        </div>
    );
}

QuillItem.defaultProps = {
    placeholder: '请输入内容...',
    width: '100%',
    height: '400px',
    readOnly: false
}
