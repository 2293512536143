import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    type: 'success',
    message: '温馨提示',
    description: null,
    placement: 'bottomRight',
    duration: 4.5,
    btn: null
}


export const globalSlice = createSlice({
    name: 'global',
    initialState: { notificationInfo: initialState, messageInfo: {}},
    reducers: {
        open: (state, { payload }) => {
            state.notificationInfo = { ...payload }
        },
        close: (state) => {
            state.notificationInfo = initialState
        }
    },
});

export const { open, close } = globalSlice.actions;
export default globalSlice.reducer;
