import React, { useEffect, useState, useImperativeHandle, useMemo, useCallback } from 'react';
import { Button, Card, Space, Tooltip, Spin, Dropdown, Menu, Divider, theme, Popconfirm, Slider, Flex } from 'antd';
import { PlusOutlined, MinusOutlined, FullscreenOutlined, FullscreenExitOutlined, AimOutlined, LoadingOutlined, BranchesOutlined, RedoOutlined, CheckOutlined, DownOutlined, PieChartOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import * as Icon from '@ant-design/icons';
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { TILELAYER_IMG_URL, TILELAYER_CIA_URL, SUBDOMAINS } from './config';
import { MapContainer, ScaleControl, LayerGroup, Circle, TileLayer } from 'react-leaflet';
import { initFullScreen, enterFullScreen, exitFullScreen } from '../../utils/full';
import { getLatLngs, array, calculateArea } from '../../utils/utils';
import PolygonView from './polygon-view';
import LineView from './line-view';
import MarkerView from './marker-view';
import MeasureView from './measure-view';
import TrajectoryView from './trajectory-view';
import MqttGpsView from './mqtt-gps-view';
import { useSelector } from 'react-redux';
import './index.scss';

const { useToken } = theme;
const MapView = React.forwardRef((props, ref) => {
    const { token } = useToken()
        , [map, setMap] = useState(null)
        , {
            base,               // 当前基地信息
            mapKey,             // 天地图key
        } = useSelector(state => state.auth)
        , { version } = useSelector(state => state.version)// 当前基地地图版本
        , {
            center,         // 当前基地信息地图初始化时的中心点位置
            opacityPolygon  // 地图多边形地块填充透明度
        } = base
        , zoom = props.zoom || base.zoom                // 地图初始化时的缩放等级
        , minZoom = props.minZoom || base.minZoom       // 地图的最小缩放级别。
        , maxZoom = props.maxZoom || base.maxZoom       // 地图的最大缩放级别。 
        , {
            id,                         // elID
            title,                      // 标题
            width,                      // 窗口宽                 
            height,                     // 窗口高
            margin,                     // 窗口外边距       
            alone,
            eable,
            isLocate,
            addItems,
            addMode,
            addText,
            addOptions,
            showMeasurements,
            isFullscreen,
            extra,
            leftControl = [],
            rightControl = []
        } = props
        , [fillOpacity, setFillOpacity] = useState(opacityPolygon) // 地块总透明度
        // 是否全屏
        , [isFull, setIsFull] = useState(false)
        // 用户当前位置
        , [locatePosition, setLocatePosition] = useState(null)
        // 开启当前位置时，记录地图地理范围，以便返回地图
        , [locateBounds, setLocateBounds] = useState(null)
        // 地图状态
        , [spinning, setSpinning] = useState(true)
        // 新增状态
        , [adding, setAdding] = useState(null)
        // 创建的图层
        , [mapFeatureGroup, setMapFeatureGroup] = useState({
            polygon: { layers: [], target: null },
            line: { layers: [], target: null },
            marker: { layers: [], target: null },
            trajectory: null
        })
        , [leftSelectedKeys, setLeftSelectedKeys] = useState([])
        , [rightSelectedKeys, setRightSelectedKeys] = useState([])

        , [polygonValue, setPolygonValue] = useState([])                // 选择的多边形地块keys
        , [polygonSelectedRows, setPolygonSelectedRows] = useState([])  // 
        , [polygonPath, setPolygonPath] = useState({})
        , [markerValue, setMarkerValue] = useState([])      // 选择的标注点
        , [markerSelectedRows, setMarkerSelectedRows] = useState([])
        , [lineValue, setLineValue] = useState([])          // 选择的线
        , [lineSelectedRows, setLineSelectedRows] = useState([])
        , [editor, setEditor] = useState(null)                  // 编辑中的元素{ key: null, type: null, target: null }
        , [currentZoom, setCurrentZoom] = useState(zoom)
        , [options, setOptions] = useState([])

    const divId = useMemo(() => {
        return id + "-map-card"
    }, [id])

    // 开启编辑模式 当前用户选择的图形 ignore 是否可编辑
    const currentTarget = useMemo(() => {
        const selectedRows = [...polygonSelectedRows, ...markerSelectedRows, ...lineSelectedRows].filter(item => !item.options.ignore)
        if (selectedRows.length === 1) {
            const target = selectedRows[0]
                , { id, type } = target.options
            return { key: id, type, target }
        } else {
            return null
        }
    }, [polygonSelectedRows, markerSelectedRows, lineSelectedRows])

    // 移动地图
    // useEffect(() => {
    //     if (mapFeatureGroup[alone]?.target) {
    //         console.log(1)
    //         const bounds = mapFeatureGroup[alone].target.getBounds()
    //         bounds._northEast && map.fitBounds(bounds)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [map, alone])

    // 监控全屏
    useEffect(() => {
        if (map && isFullscreen) {
            setIsFull(isFull)
            const mapCard = document.getElementById(divId)
            isFull ? enterFullScreen(mapCard) : exitFullScreen(mapCard)
            map.invalidateSize()
            // 全屏事件回调
            props.onFullChange(isFull)
        }
    }, [map, isFullscreen, isFull, props, divId])

    // 编辑时，多边形两个点的删除事件
    const onDelete = useCallback(() => {
        const { key, type, target } = currentTarget
        if (type === 'Polygon') {
            setPolygonValue([])
            setPolygonSelectedRows([])
        }

        if (type === 'Line') {
            setLineValue([])
            setLineSelectedRows([])
        }

        if (type === 'Marker') {
            setMarkerValue([])
            setMarkerSelectedRows([])
        }

        // 返回删除事件
        props.onDelete({
            key,
            type
        })

        setEditor(null)
        // 地图中清除删除的图形
        map.removeLayer(target)
    }, [map, currentTarget, props])

    // 开始编辑
    const onEdit = useCallback(() => {
        // 已进入编辑状态时退出
        if (editor) return
        // 解构当前操作的图形
        const { key, type, target } = currentTarget
        // 设置当前需要编辑的元素
        setEditor(currentTarget)
        // 记录当前图形的坐标组， 当用户取消编辑时调用恢复原有状态
        target.pm.positions = getLatLngs(target)
        // 进入编辑状态 标注点时，不可删除结点
        target.pm.enable({
            preventMarkerRemoval: type === 'Marker'
        })
        // 当图形被编辑的时候触发
        target.on("pm:edit", (e) => {
            // 当用户配置显示测量时 更新图层信息
            if (props.showMeasurements && type === 'Polygon') {
                setPolygonSelectedRows([e.target])
            }
        })
        // 多边形与线删除节点时，触发删除事件
        target.on("pm:vertexremoved", (e) => {
            const len = e.target.getLatLngs().flat().length
            if (len === 0) {
                onDelete()
            }
        })
        props.onEdit({
            key,
            target,
            type
        })
    }, [editor, currentTarget, props, onDelete])

    // 取消编辑
    const onCancel = () => {
        if (adding) {
            map.pm.disableDraw()
            setAdding(null)
            return
        }
        const { key, type, target } = editor
            , { positions } = target.pm
        // 取消编辑
        target.pm.disable()
        // 恢复原有状态
        type === 'Marker' ? target.setLatLng(positions) : target.setLatLngs(positions)
        setEditor(null)
        props.onCancel({
            key,
            type,
            target
        })
    }

    // 保存编辑结果
    const onSave = () => {
        const { key, target, type } = editor
            , value = getLatLngs(target)
        target.pm.disable()
        props.onSave({
            key,
            target,
            type,
            value
        })
        setEditor(null)
    }

    // 新增
    const onAdd = useCallback(({ key, type }) => {
        if (adding) return
        setAdding(true)
        const options = type === 'Polygon' || type === 'Line' ?
            { pathOptions: { ...addOptions[type], key, type } } :
            { markerStyle: { icon: getIcon(addOptions[type]), key, type } }
        map.pm.enableDraw(type, options)
        setPolygonValue([])
        setPolygonSelectedRows([])
        setMarkerValue([])
        setMarkerSelectedRows([])
        setLineValue([])
        setLineSelectedRows([])
    }, [map, adding, addOptions])

    // 渲染图标
    const getIcon = (iconUrl) => {
        return L.icon({ iconUrl, iconSize: [48, 48], iconAnchor: [24, 24] })
    }

    // 多边形面积
    const getArea = (target) => {
        return { area: Number(calculateArea(target.getLatLngs()[0])) }
    }

    // 线长度
    const getDistance = (map, target) => {
        const latlngs = target.getLatLngs()
            , distance = latlngs.reduce(function (total, curren, currentIndex, arr) {
                const next = arr[currentIndex + 1]
                return total + (typeof next === 'object' ? map.distance(curren, next) : 0)
            }, 0)
        return { distance: distance.toFixed(2) }
    }

    // 获取测量数据 ，多边形面积或线之间距离
    const getMeasure = (map, target) => {
        const { type } = target.options
        return type === 'Polygon' ? getArea(target) : type === 'Line' ? getDistance(map, target) : {}
    }

    const getOptions = (target) => {
        const { _zoom } = target
            , { _southWest, _northEast } = target.getBounds()
            , bounds = L.bounds([_southWest.lat, _southWest.lng], [_northEast.lat, _northEast.lng])
            , topLeft = bounds.getTopLeft()
            , bottomLeft = bounds.getBottomLeft()
            , bottomRight = bounds.getBottomRight()
            , topRight = bounds.getTopRight()
            , range = [
                [
                    [topRight.x, topRight.y],
                    [bottomRight.x, bottomRight.y],
                    [bottomLeft.x, bottomLeft.y],
                    [topLeft.x, topLeft.y],
                    [topRight.x, topRight.y]
                ]
            ]
        setOptions({
            zoom: _zoom,
            range
        })
        props.onMoveend({
            zoom: _zoom,
            range
        })
    }

    const whenReady = ({ target }) => {
        // 更新map
        setMap(target)
        getOptions(target)
        // 设置编辑为中文提示
        target.pm.setLang('zh')
        // 地图加载完成的回调事件
        props.onReady({ map: target })
        // 标尺
        target.on('zoom', ({ target }) => {
            setCurrentZoom(target.getZoom())
            const Scale = document.getElementsByClassName('leaflet-control-scale-line')[0]
            document.getElementById('map-scale').style = `--scale: ${Scale.style?.width}`
        })

        // 配置过新增菜单时
        if (addItems.length) {
            target.on('pm:create', ({ layer }) => {
                const { key, type } = layer.options
                    , measureValue = getMeasure(target, layer)
                props.onCreate({
                    key,
                    type,
                    target: layer,
                    value: getLatLngs(layer),
                    ...measureValue
                })
                // 关闭创建
                target.pm.disableDraw()
                target.removeLayer(layer)
                setAdding(null)
            })
        }

        // 地图中心停止更改时触发（例如，用户停止拖动地图）
        target.on('moveend', ({ target }) => getOptions(target))

        // 调整地图大小时触发
        target.on('resize', ({ newSize, oldSize, target }) => {
            props.onResize({
                newSize,
                oldSize,
                map: target,
            })
        })

        // 用户位置
        if (isLocate) {
            target.on('locationfound', (e) => {
                setLocatePosition(e.latlng)
                setSpinning(false)
            })
        }

        // 加载状态
        setSpinning(false)

        if (isFullscreen) {
            // 注册全屏事件
            initFullScreen(setIsFull, document.getElementById(divId))
        }
    }

    // 自定义暴露给父组件的实例
    useImperativeHandle(ref, () => {
        return {
            map,
            // 设置加载状态
            setLoading: (value = true) => setSpinning(value),
            // 根据类型返回图层
            getGroup: (type) => mapFeatureGroup[type],
            getPolygonValue: () => {
                return polygonValue
            },
            // 根据Ids获取相应的图形
            getSelectedRows: (type, data = []) => mapFeatureGroup[type].layers.filter(item => data.includes(item.options.id)),
            // 根据类型设置选中的图层
            setValue: ({ type, data = [], selectedPath = {}, flyTo = false }) => {
                const selectedRow = ref.current.getSelectedRows(type, data)
                if (type === 'polygon') {
                    setPolygonValue(data)
                    setPolygonSelectedRows(selectedRow)
                    setPolygonPath(selectedPath)
                }

                if (type === 'line') {
                    setLineValue(data)
                    setLineSelectedRows(selectedRow)
                }

                if (type === 'marker') {
                    setMarkerValue(data)
                    setMarkerSelectedRows(selectedRow)
                }

                if (flyTo) {
                    if (data.length) {
                        const featureGroup = L.featureGroup([])
                        selectedRow.forEach(layer => {
                            layer.addTo(featureGroup)
                        })
                        map.fitBounds(featureGroup.getBounds())
                        featureGroup.clearLayers()
                    } else {
                        ref.current.fitBounds(type)
                    }
                }
            },
            // 移动地图 至 图形中心点
            fitBounds: (param, maxZoom = 15) => {
                // 字符串时，移动至传入的类型图层为中心范围 
                if (typeof param === 'string') {
                    const { target } = mapFeatureGroup[param]
                    setTimeout(() => {
                        const bounds = target.getBounds()
                        bounds._northEast && map.fitBounds(bounds, { maxZoom })
                    }, 300)
                }
            },
            // 执行一个平滑的平移-缩放动画，移动缩放地图范围至指定的地理中心和级别。
            flyTo: (latlng, zoom) => {
                if (array(latlng) && latlng.length === 2) {
                    map.panTo(latlng, zoom)
                }
            },
            // 地图中心点坐标
            getMapCenter: () => map.getCenter(),
            // 据类型清空值得
            clearValue: (type) => {
                if (type === 'polygon') {
                    setPolygonValue([])
                    setPolygonSelectedRows([])
                }
                if (type === 'line') {
                    setLineValue([])
                    setLineSelectedRows([])
                }
                if (type === 'marker') {
                    setMarkerValue([])
                    setMarkerSelectedRows([])
                }
            },
            setLeftSelectedKeys
        }
    }, [ref, mapFeatureGroup, map, setLeftSelectedKeys, polygonValue])

    // 天地图KEY
    const tiandituUrl = useMemo(() => {
        const tiandituKey = mapKey ? mapKey : props.key
        return { IMG: TILELAYER_IMG_URL + tiandituKey, CIA: TILELAYER_CIA_URL + tiandituKey }
    }, [props.key, mapKey])

    // 分发地图各版本地图实景图地址
    const localityUrl = useMemo(() => {
        if (version.length) {
            const record = version.find(item => item.isDef === 1)
                , { mapUrl, baseId, filePath } = record
            return `${mapUrl}/${baseId}/${filePath}/{z}/{x}/{y}.webp`
        } else {
            return null
        }
    }, [version])

    return (
        <div
            id={divId}
            ref={ref}
            style={{
                width: isFull ? '100vw' : `calc(${width} - ${margin * 2}px)`,
                height: isFull ? `calc(100vh - 56px)` : props.showHeader ? `calc(${height} - ${margin * 2}px)` : height,
                margin,
                position: 'relative',
                '--colorBorderBg': token.colorBorderBg,
                ...props.mapStyle
            }}
            className={props.showHeader ? 'showHeader map-card' : 'hideHeader map-card'}
        >
            <Spin
                indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />}
                spinning={spinning}
                style={{ width: '100%', height: '100%' }}
            >
                <Card
                    title={title}
                    style={{ '--scale': '97px' }}
                    id="map-scale"
                    bodyStyle={{
                        height: isFull ? `calc(100vh - 56px)` :
                            props.showHeader ? `calc(${height} - ${margin * 2}px)` : height,
                        padding: 0,
                        display: 'flex'
                    }}
                    extra={(
                        <Space
                            split={<Divider type="vertical" />}
                        >

                            {extra}

                            {
                                addItems.length || eable ? (
                                    <Space
                                        split={<Divider type="vertical" />}
                                    >
                                        {
                                            addItems.length && addMode === 'dropdown' ? (
                                                <Dropdown.Button
                                                    icon={<DownOutlined />}
                                                    disabled={adding}
                                                    type={adding ? 'primary' : 'default'}
                                                    menu={{
                                                        items: addItems.map(({ key, type, label, icon = null }) => ({ key, type, label, icon: icon ? React.createElement(Icon[icon]) : null })),
                                                        onClick: onAdd
                                                    }}
                                                    getPopupContainer={() => document.getElementById(divId)}
                                                >
                                                    {addText}
                                                </Dropdown.Button>
                                            ) : addItems.length && addMode === 'horizontal' ? (
                                                <Space.Compact>
                                                    {
                                                        addItems.map(({ key, type, label, icon = null, disabled = false }) => {
                                                            return (
                                                                <Button
                                                                    disabled={disabled || !key}
                                                                    key={type + key}
                                                                    onClick={() => {
                                                                        onAdd({ key, type })
                                                                    }}
                                                                    icon={icon ? React.createElement(Icon[icon]) : null}
                                                                >
                                                                    {label}
                                                                </Button>
                                                            )
                                                        })
                                                    }
                                                </Space.Compact>
                                            ) : null
                                        }
                                        {
                                            eable ? (
                                                <Space.Compact>
                                                    <Tooltip
                                                        title={`重新绘制选中的图形`}
                                                    >
                                                        <Button
                                                            icon={<BranchesOutlined />}
                                                            disabled={!currentTarget}
                                                            type={!editor ? 'default' : 'primary'}
                                                            onClick={() => onEdit()}
                                                            style={{ width: 50 }}
                                                        />
                                                    </Tooltip>

                                                    <Tooltip
                                                        title={'取消本次操作'}
                                                    >
                                                        <Button
                                                            icon={<RedoOutlined />}
                                                            disabled={!editor && !adding}
                                                            onClick={() => onCancel()}
                                                            style={{ width: 50 }}
                                                        />
                                                    </Tooltip>

                                                    <Tooltip
                                                        title={'保存绘制结果'}
                                                    >
                                                        <Button
                                                            icon={<CheckOutlined />}
                                                            disabled={!editor}
                                                            onClick={() => onSave()}
                                                            style={{ width: 50 }}
                                                        />
                                                    </Tooltip>
                                                    {
                                                        props.isDel ? (
                                                            <Popconfirm
                                                                title="此操作将永久删除该信息, 是否继续？"
                                                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                                onConfirm={() => onDelete()}
                                                                placement="bottomRight"
                                                            >
                                                                <Tooltip
                                                                    title={'删除选中'}
                                                                >
                                                                    <Button
                                                                        icon={<DeleteOutlined />}
                                                                        disabled={!currentTarget}
                                                                        type="primary"
                                                                        style={{ width: 50 }}
                                                                        danger
                                                                    />
                                                                </Tooltip>
                                                            </Popconfirm>
                                                        ) : null
                                                    }

                                                </Space.Compact>
                                            ) : null
                                        }
                                    </Space>
                                ) : null
                            }

                            {
                                isLocate ? (
                                    <Tooltip
                                        title={locatePosition ? '清除定位当前位置' : '定位当前自己的位置'}
                                        placement='topRight'
                                        getPopupContainer={() => document.getElementById(divId)}
                                    >
                                        <Button
                                            icon={<AimOutlined />}
                                            type={locatePosition ? 'primary' : 'default'}
                                            onClick={() => {
                                                if (locatePosition) {
                                                    map.stopLocate()
                                                    map.fitBounds(locateBounds)
                                                    setLocatePosition(null)
                                                    return
                                                } else {
                                                    setSpinning(true)
                                                    setLocateBounds(map.getBounds())
                                                    map.locate({
                                                        watch: true,
                                                        setView: true,
                                                        enableHighAccuracy: true,
                                                        maxZoom: 17
                                                    })
                                                }
                                            }}
                                        />
                                    </Tooltip>
                                ) : null
                            }

                            {
                                isFullscreen ? (
                                    <Tooltip
                                        title={isFull ? '退出全屏模式 Esc' : '进入全屏 F11'}
                                        getPopupContainer={() => document.getElementById(divId)}
                                    >
                                        <Button
                                            icon={isFull ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                                            type={isFull ? "primary" : "default"}
                                            onClick={() => setIsFull(!isFull)}
                                        />
                                    </Tooltip>
                                ) : null
                            }
                        </Space>
                    )}
                    className={props.required ? 'required' : ''}
                >
                    <Flex style={{ width: '100%', height: '100%' }}>
                        <MapContainer
                            id={id}
                            center={center}
                            zoom={zoom}
                            minZoom={minZoom}
                            maxZoom={maxZoom}
                            zoomControl={false}
                            whenReady={whenReady}
                            className='map-container'
                        >
                            {
                                // 天地图瓦片图层
                                props.TileLayerType === 'tianditu' || props.TileLayerType === 'blend' ? (
                                    <>
                                        <TileLayer
                                            url={tiandituUrl.IMG}
                                            subdomains={SUBDOMAINS}
                                            errorTileUrl={(e) => {
                                                console.log('errorTileUrl', e)
                                            }}
                                        />
                                        <TileLayer
                                            url={tiandituUrl.CIA}
                                            subdomains={SUBDOMAINS}
                                        />
                                    </>
                                ) : null
                            }

                            {
                                // 本地瓦片图层
                                localityUrl && (props.TileLayerType === 'locality' || props.TileLayerType === 'blend') ? (
                                    <TileLayer
                                        url={localityUrl}
                                        maxZoom={maxZoom}
                                    />
                                ) : null
                            }

                            <ScaleControl />

                            {
                                locatePosition ? (
                                    <LayerGroup>
                                        <Circle
                                            center={locatePosition}
                                            pathOptions={{ color: 'blue', opacity: .3, fillColor: 'blue', fillOpacity: .2 }}
                                            radius={400}
                                        />
                                        <Circle
                                            center={locatePosition}
                                            pathOptions={{ fillColor: 'red', fillOpacity: .4 }}
                                            radius={10}
                                            stroke={false}
                                        />

                                    </LayerGroup>
                                ) : null
                            }

                            <PolygonView
                                dataSource={props.polygon}
                                setMapFeatureGroup={setMapFeatureGroup}
                                onClick={props.onClick}
                                contextmenu={props.contextmenu}
                                defaultValue={props.polygonValue}
                                value={polygonValue}
                                setValue={setPolygonValue}
                                editor={editor}
                                setSelectedRows={setPolygonSelectedRows}
                                fillOpacity={fillOpacity}
                                setOpacityPolygon={props.setOpacityPolygon}
                                config={options}
                                path={polygonPath}
                                isFitBounds={alone === 'polygon'}
                                maxZoom={maxZoom}
                            />

                            <LineView
                                dataSource={props.line}
                                setMapFeatureGroup={setMapFeatureGroup}
                                onClick={props.onClick}
                                value={lineValue}
                                setValue={setLineValue}
                                editor={editor}
                                setSelectedRows={setLineSelectedRows}
                            />

                            <MarkerView
                                dataSource={props.marker}
                                setMapFeatureGroup={setMapFeatureGroup}
                                onClick={props.onClick}
                                value={markerValue}
                                setValue={setMarkerValue}
                                editor={editor}
                                setSelectedRows={setMarkerSelectedRows}
                                isFitBounds={alone === 'marker'}
                                maxZoom={maxZoom}
                            />

                            <TrajectoryView
                                dataSource={props.trajectory}
                                value={props.trajectoryValue}
                                setMapFeatureGroup={setMapFeatureGroup}
                                onClick={props.onClick}
                                config={options}
                            />

                            <MqttGpsView
                                defaultTrack={props.defaultTrack}
                                realTimeTrack={props.realTimeTrack}
                                lock={props.lock}
                            />

                            <MeasureView
                                visible={showMeasurements}
                                dataSource={polygonSelectedRows.filter(target => target.options.measureText)}
                            />

                            {
                                leftControl.length ? (
                                    <div
                                        className="leaflet-top leaflet-left"
                                        style={{ margin: 0 }}
                                    >
                                        <div className="leaflet-control leaflet-bar">
                                            <Menu
                                                items={leftControl.map(item => ({ ...item, icon: <PieChartOutlined /> }))}
                                                selectedKeys={leftSelectedKeys}
                                                onSelect={({ key, selectedKeys, keyPath }) => {
                                                    setLeftSelectedKeys(selectedKeys)
                                                    props.onClick({
                                                        type: 'LeftControl',
                                                        keyPath,
                                                        key
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                ) : null
                            }

                            {
                                rightControl.length ? (
                                    <div
                                        className="leaflet-top leaflet-right"
                                        style={{ margin: 0 }}
                                    >
                                        <div className="leaflet-control leaflet-bar">
                                            <Menu
                                                mode="inline"
                                                items={rightControl.map(item => ({ ...item, icon: <PieChartOutlined /> }))}
                                                selectedKeys={rightSelectedKeys}
                                                onSelect={(e) => {
                                                    const { key, selectedKeys, keyPath } = e
                                                    setRightSelectedKeys(selectedKeys)
                                                    props.onClick({
                                                        type: 'RightControl',
                                                        keyPath,
                                                        key
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                ) : null
                            }

                            {
                                props.rightChildren ? (
                                    <div
                                        className="leaflet-top leaflet-right"
                                        style={{ margin: 0 }}
                                    >
                                        <div className="leaflet-control leaflet-bar" style={{ padding: '10px 20px 0', borderColor: 'transparent', backgroundColor: token.colorBgContainer }}>
                                            {props.rightChildren}
                                        </div>
                                    </div>
                                ) : null
                            }



                        </MapContainer>

                        {props.children}

                        {
                            props.setOpacityPolygon && mapFeatureGroup.polygon.layers.length ? (
                                <div
                                    style={{ width: 200, borderRadius: 8, display: 'flex', justifyContent: 'center', position: 'absolute', right: 10, bottom: 10, zIndex: 1001, backgroundColor: token.colorBgContainer }}
                                >
                                    <Slider
                                        style={{ width: 160 }}
                                        min={0}
                                        max={100}
                                        step={10}
                                        value={fillOpacity}
                                        onChange={setFillOpacity}
                                    />
                                </div>
                            ) : null
                        }

                        {
                            // 显示地图缩放按钮
                            props.zoomControl ? (
                                <Space.Compact direction="vertical" className='zoomControl'>
                                    <Button
                                        icon={<PlusOutlined />}
                                        onClick={() => {
                                            const zoom = map.getZoom()
                                            if (zoom === maxZoom) {
                                                return
                                            }
                                            map.setZoom(zoom + 1)
                                            setCurrentZoom(zoom + 1)
                                        }}
                                    >
                                    </Button>
                                    <Button style={{ padding: 0 }}>{currentZoom}</Button>
                                    <Button
                                        icon={<MinusOutlined />}
                                        onClick={() => {
                                            const zoom = map.getZoom()
                                            if (zoom === minZoom) {
                                                return
                                            }
                                            map.setZoom(zoom - 1)
                                            setCurrentZoom(zoom - 1)
                                        }}
                                    >
                                    </Button>
                                </Space.Compact>
                            ) : null
                        }
                    </Flex>
                </Card>
            </Spin>
        </div >
    )
})

MapView.defaultProps = {
    title: '地图组件',
    id: 'MapContainer',
    // 指定地图中心点
    // center: null,       // [31.889094481130325, 116.05510979890826]
    width: '100%',
    height: 'calc(100vh - 221px)',
    showHeader: true,       // 是否显示头
    // 组件外边框  number 16
    margin: 0,
    zoom: undefined,
    minZoom: undefined,
    maxZoom: undefined,
    // 地图key
    key: 'd979f223ea462aaa6ee630f890ebd75b',
    setOpacityPolygon: false,        // 设置多边形默认透明度
    TileLayerType: 'blend', // 瓦片图层类型 天地图 tianditu || 本地 locality || 混合显示 blend
    mapStyle: {},
    // 渲染图形完成时， 指定设置地图的视图中心位置 Polygon | Line | Marker | Trajectory
    alone: false,
    // 开启编辑模式
    eable: false,
    // 按钮支持水平 horizontal 与下拉 dropdown两种类型 
    addMode: 'horizontal',
    addText: '新增',
    // {key：返回事件区分， type: 类型 Polygon | Line | Marker, label: 按钮名称，icon: 图标}
    addItems: [],
    // 配置添加参数 { label: '多边形', key: 'Polygon' }, { label: '线', key: 'Line' }, { label: '标注点', key: 'Marker' },
    addOptions: {
        Polygon: { type: 'Polygon', color: 'red', opacity: 1, fillColor: '#1677ff', fillOpacity: 0.5, weight: 1 },
        Line: { type: 'Line', color: '#1677ff', opacity: 1, weight: 10 },
        Marker: require('./image/edit-marker.png')
    },
    isDel: true, // 删除按钮
    addPolygon: { key: 'lands', label: '新增地块', icon: 'RadiusSettingOutlined', disabled: true },     // toDo
    // 显示用户当前位置 
    isLocate: false,
    // 显示多边形面积，线的两端距离
    showMeasurements: false,
    // 是否显示打印日志
    log: false,
    // 图标配置        
    iconUrl: {
        start: require('./image/start.png'),
        selectedStart: require('./image/selected-start.png'),
        end: require('./image/end.png'),
        selectedEnd: require('./image/selected-end.png'),
        marker: require('./image/marker.png'),
        selectedMarker: require('./image/selected-marker.png'),
        editMarker: require('./image/edit-marker.png')
    },
    // 地图内分组列表
    LeftControl: [],
    rightControl: [],
    // 位置，可选 topLeft topRight
    // 多边形配置数据
    polygon: false,

    // 线配置数据
    line: false,
    // 标注点配置数据
    marker: false,
    // 轨迹配置数据
    trajectory: false,
    // 全屏
    isFullscreen: true,
    required: false,        // 表单时，必要条件
    polygonValue: [],       // 选择的多边形
    markerValue: [],        // 选择的标注点
    lineValue: [],          // 选择的线
    trajectoryValue: [],    // 显示选择的轨迹 [imei]
    polygonEdit: [],        // 编辑中的多边形
    markerEdit: [],         // 编辑中的标注点
    lineEdit: [],           // 编辑中的线
    zoomControl: true,      // 地图左下角显示地图缩放按钮
    onReady: ({ map }) => {
        // 地图加载完成的回调事件
        // Map.defaultProps.log && console.log('onReady', map)
    },
    onRender: ({ type, layers, map }) => {
        // 渲染图形完成的回调
        // Map.defaultProps.log && console.log('onRender', type, layers, map)
    },
    onMoveend: ({ map, center }) => {
        // 地图中心停止更改时触发（例如，用户停止拖动地图）。
        // Map.defaultProps.log && console.log('onMoveend', { map, center })
    },
    onResize: ({ newSize, oldSize, target }) => {
        // 调整地图大小时触发
        // Map.defaultProps.log && console.log('onResize', { newSize, oldSize, target })
    },
    onEdit: (e) => {
        // 编辑触发
    },
    onCancel: () => {
        // 取消事件
    },
    onSave: ({ type, target, value }) => {
        // 编辑多边形，线，标注点的回调事件
        // Map.defaultProps.log && console.log('onSave', { type, target, value })
    },
    onCreate: ({ type, target, value }) => {
        // 初始创建多边形，线，标注点的回调事件
        // Map.defaultProps.log && console.log('onCreate', { type, target, value })
    },
    onAdd: ({ type, target, value }) => {
        // 新增多边形，线，标注点保存时的回调事件
        // Map.defaultProps.log && console.log('onAdd', { type, target, value })
    },
    onDelete: () => {
        //删除时
    },
    onSelect: (e) => {
        // 用户手动选择/取消选择地图内渲染的组件回调
        // Map.defaultProps.log && console.log('onSelect', e)
    },
    onClick: (e) => {
        // 用户单击地图内渲染的组件回调
        // Map.defaultProps.log && console.log('onClick', e)
    },
    // 多边形右键事件
    contextmenu: false,
    onFullChange: (isFull) => {
        // 全屏事件回调
        // console.log(isFull)
    }
}

export default MapView
