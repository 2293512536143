import React from 'react';
import { Layout, Space } from 'antd';
import { CopyrightOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

export default function FooterView() {
    const { recordNumber, version, copyright } = useSelector(state => state.siteInfo)
    return (
        <Layout.Footer
            style={{
                textAlign: 'center',
            }}
        >
            <Space>
                <CopyrightOutlined />
                {copyright}
                {recordNumber}
                {version}
            </Space>
        </Layout.Footer>
    )
}
