import React from 'react';
import { BrowserView, TabletView, MobileOnlyView } from 'react-device-detect';
import LayoutBrowserView from './browser';
import LayoutTabletView from './tablet';
import LayoutMobileView from './mobile';

export default function LayoutView() {
    return (
        <>
            <BrowserView className='browser'>
                <LayoutBrowserView />
            </BrowserView>
            <TabletView className='tablet'>
                <LayoutTabletView />
            </TabletView>
            <MobileOnlyView className='mobile'>
                <LayoutMobileView />
            </MobileOnlyView>
        </>
    )
}
