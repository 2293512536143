import { createContext } from 'react';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const initialState = JSON.parse(localStorage.getItem('config')) || {
    layout: 'sider',        // 顶部-侧边布局: top || 侧边布局: sider 
    algorithm: 'dark',      // 整体风格设置
    colorPrimary: 'rgb(24, 144, 255)', // 主题色
    collapsed: true         
}

export const ConfigContext = createContext(initialState)

const ConfigContextAtom = atomWithStorage('config', initialState)

export function useConfigContextAtom() {
    return useAtom(ConfigContextAtom)
}
