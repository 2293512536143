import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthRouter = ({ children }) => {
    const location = useLocation()
        , { token } = useSelector(state => state.auth)
    

    return token ? children : <Navigate to='/login' replace state={{ from: location }} />
}

export default AuthRouter
