import React from 'react';
import { Layout } from 'antd';
import LogoView from './logo';
import MenuView from './menu';
import ContentView from './content';
import FooterView from './footer';

import './index.scss';

export default function LayoutBrowserView() {

    return (
        <Layout
            className='layout-page'
        >
            <Layout.Sider
                defaultCollapsed={true}
                collapsedWidth={64}
            >
                <LogoView />
                <MenuView />
            </Layout.Sider>

            <Layout>
                <ContentView />
                <FooterView />
            </Layout>
        </Layout>
    )
}
