import request from "../request";

const api = {
    getList: (queryParams, current = 1, pageSize = 10) => request.post('/v1/sys/base/search', { ...queryParams, current, pageSize }),
    getById: (id) => request.get(`/v1/sys/base/${id}`),
    add: (queryParams) => request.post('/v1/sys/base', queryParams),
    edit: (queryParams) => request.put('/v1/sys/base', queryParams),
    delete: (id) => request.delete(`/v1/sys/base/${id}`),
}



const base = {
    api
}

export default base
