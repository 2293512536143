import React, { useState, useEffect } from 'react';
import { Space, Button, Drawer, Spin, Form, Input, Divider, DatePicker, Switch } from 'antd';
import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { tickets } from '../../service';
import { onSuccess, onError } from '../../utils';
import dayjs from "dayjs";

export default function ReplyDrawer(props) {
    const { open, id } = props
        , [spinning, setSpinning] = useState(false)     // 设置获取数据载入状态
        , [loading, setLoading] = useState(false)       // 设置按钮载入状态
        , [form] = Form.useForm()
        , [title, setTitle] = useState('')
    useEffect(() => {
        if (open && id) {
            // 编辑时获取信息
            setSpinning(true)
            tickets.api.getById(id).then(({ data: { baseName, siteName, createName, title, reply, state, imageUrl } }) => {
                form.setFieldsValue({
                    id,
                    baseName,
                    siteName,
                    createName,
                    title,
                    reply,
                    end: state === '2' ? true : false,
                    imageUrl
                })
                setTitle(title)
                setSpinning(false)
            }).catch(error => {
                onError(error)
                setSpinning(false)
            })
        }
    }, [open, id, form])

    // 关闭窗口
    const onClose = () => {
        form.resetFields()
        setLoading(false)
        setTitle('')
        props.onClose()
    }

    // 提交表单
    const onFinish = (values) => {
        setLoading(true)
        if(values.end){
            values.state = '2'
        }
        delete values.end
        tickets.api.edit(values).then(({ message }) => {
            onSuccess(message)
            props.onRefresh()
            onClose()
        }).catch(error => {
            onError(error)
            setLoading(false)
        })
    }


    return (
        <Drawer
            title={title}
            open={open}
            onClose={onClose}
            closable={false}
            destroyOnClose={true}
            size="large"
            extra={
                <Space
                    split={<Divider type="vertical" />}
                >
                    <Space.Compact>
                        <Button
                            icon={<CloseOutlined />}
                            onClick={onClose}
                        >
                            取消
                        </Button>

                        <Button
                            icon={<CheckOutlined />}
                            type="primary"
                            onClick={() => form.submit()}
                            loading={loading}
                        >
                            保存
                        </Button>
                    </Space.Compact>
                </Space>
            }
        >
            <Spin
                indicator={<LoadingOutlined style={{ fontSize: 36 }} />}
                spinning={spinning}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="dryer.form"
                    onFinish={onFinish}
                >

                    <Form.Item
                        name="baseName"
                        label="基地"
                    >
                        <Input placeholder="请填写" />
                    </Form.Item>

                    <Form.Item
                        name="siteName"
                        label="系统"
                    >
                        <Input placeholder="请填写" />
                    </Form.Item>

                    <Form.Item
                        name="createName"
                        label="提交人"
                    >
                        <Input placeholder="请填写" />
                    </Form.Item>

                    <Form.Item
                        name="reply"
                        label="回复内容"
                    >
                        <Input.TextArea style={{ height: 150 }} placeholder="请填写" />
                    </Form.Item>

                    <Form.Item
                        name="state"
                        label="回复内容"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>

                    <Form.Item
                        name="id"
                        hidden={true}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Spin>
        </Drawer>
    )
}
