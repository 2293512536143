import React, { useState, useEffect } from 'react';
import { Space, Button, Drawer, Spin, Form, Input, Upload, Divider, Select, Radio } from 'antd';
import { CheckOutlined, CloseOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { user, sys } from '../../service';
import { useSelector } from 'react-redux';
import { onSuccess, onError } from '../../utils';

export default function UserFormDrawer(props) {
    const { open, id, roleList = [] } = props
        , [spinning, setSpinning] = useState(false)     // 设置获取数据载入状态
        , [loading, setLoading] = useState(false)       // 设置按钮载入状态
        , [form] = Form.useForm()
        , [fileList, setFileList] = useState([])
        , { serverUrl } = useSelector(state => state.siteInfo)

    useEffect(() => {
        if (open) {
            if (id) {
                // 编辑时获取信息
                setSpinning(true)
                user.api.getById(id).then(({ data: { username, roleId, name, isWx, imageUrl, mobile, email, remark } }) => {
                    if (imageUrl) {
                        const file = {
                            uid: 'imageUrl',
                            name: imageUrl,
                            status: 'done',
                            url: serverUrl + imageUrl
                        }
                        setFileList([file])
                    }
                    form.setFieldsValue({ id, roleId: Number(roleId), isWx, username, name, imageUrl, mobile, email, remark })
                    setSpinning(false)
                }).catch(error => {
                    onError(error)
                    setSpinning(false)
                })
            }
        }
    }, [open, id, form, serverUrl])

    // 关闭窗口
    const onClose = () => {
        form.resetFields()
        setLoading(false)
        setFileList([])
        props.onClose()
    }

    // 提交表单
    const onFinish = (values) => {
        setLoading(true)
        const promise = id ? user.api.edit(values) : user.api.add(values)
        promise.then(({ message }) => {
            onSuccess(message)
            props.onRefresh()
            onClose()
        }).catch(error => {
            onError(error)
            setLoading(false)
        })
    }

    // 上传事件
    const beforeUpload = async (file) => {
        try {
            const { data: { path } } = await sys.uploadFile(file)
            form.setFieldValue('imageUrl', path)
            setFileList((state) => ([
                ...state,
                { ...file, url: serverUrl + path }
            ]))
            return false
        } catch (error) {
            onError(error)
        }
    }

    // 删除图片
    const onRemove = () => {
        setFileList([])
        form.setFieldValue('imageUrl', null)
    }

    return (
        <Drawer
            title={id ? '编辑' : '新增'}
            open={open}
            onClose={onClose}
            closable={false}
            destroyOnClose={true}
            extra={
                <Space
                    split={<Divider type="vertical" />}
                >
                    <Space.Compact>
                        <Button
                            icon={<CloseOutlined />}
                            onClick={onClose}
                        >
                            取消
                        </Button>

                        <Button
                            icon={<CheckOutlined />}
                            type="primary"
                            onClick={() => form.submit()}
                            loading={loading}
                        >
                            保存
                        </Button>
                    </Space.Compact>
                </Space>
            }
        >
            <Spin
                indicator={<LoadingOutlined style={{ fontSize: 36 }} />}
                spinning={spinning}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="user.form"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        label="账号"
                        rules={[{ required: true, message: '账号不能为空' }]}
                    >
                        <Input placeholder="请填写" />
                    </Form.Item>

                    <Form.Item
                        name="roleId"
                        label="角色"
                        rules={[{ required: true, message: '角色不能为空' }]}
                    >
                        <Select
                            options={roleList}
                            fieldNames={{ label: 'name', value: 'id' }}
                            placeholder="请填写"
                        />
                    </Form.Item>

                    <Form.Item
                        name="name"
                        label="名称"
                        rules={[{ required: true, message: '角色名称不能为空' }]}
                    >
                        <Input placeholder="请填写" />
                    </Form.Item>

                    <Form.Item
                        name="mobile"
                        label="手机号"
                    >
                        <Input placeholder="请填写" />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label="邮箱"
                    >
                        <Input placeholder="请填写" />
                    </Form.Item>

                    <Form.Item
                        name="isWx"
                        label="是否微信用户"
                        hidden={true}
                    >
                        <Radio.Group>
                            <Radio value={1}>是</Radio>
                            <Radio value={0}>否</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label="头像"
                    >
                        <Upload
                            beforeUpload={(file) => beforeUpload(file)}
                            listType="picture-card"
                            maxCount={1}
                            name="file"
                            fileList={fileList}
                            onRemove={() => onRemove()}
                        >
                            <div>
                                <PlusOutlined style={{ fontSize: 40, color: '#ccc' }} />
                            </div>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        name="remark"
                        label="备注"
                    >
                        <Input.TextArea style={{ height: 150 }} placeholder="请填写" />
                    </Form.Item>

                    <Form.Item
                        name="imageUrl"
                        hidden={true}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="id"
                        hidden={true}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Spin>
        </Drawer>
    )
}
