import request from "../request";

const api = {
    getList: () => request.get('/v1/sys/site/search'),
    getById: (id) => request.get(`/v1/sys/site/${id}`),
    add: (queryParams) => request.post('/v1/sys/site', queryParams),
    edit: (queryParams) => request.put('/v1/sys/site', queryParams),
    delete: (id) => request.delete(`/v1/sys/site/${id}`)
}



const site = {
    api
}

export default site
