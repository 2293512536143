import request from "../request";


const apiMenu = {
    getList: () => request.get('/v1/stats/menu/search'),
    getById: (id) => request.get(`/v1/stats/menu/${id}`),
    add: (queryParams) => request.post('/v1/stats/menu', queryParams),
    edit: (queryParams) => request.put('/v1/stats/menu', queryParams),
    delete: (id) => request.delete(`/v1/stats/menu/${id}`),
}

const apiAuth = {
    getList: () => request.get('/v1/sys/role/list'),
    getById: (id) => request.get(`/v1/stats/wx/role/${id}`),
    edit: (queryParams) => request.post('/v1/stats/wx/saveOrUpdate/role', queryParams),
}


const wxfarm = {
    apiMenu,
    apiAuth,
}

export default wxfarm
