import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setAuth } from '../store/modules/auth';
import { saveVersion } from '../store/modules/version';
import LoginComponent from '../components/login';

import { onSuccess, onError } from '../utils';
import { sys } from '../service';

export default function LoginView() {

    const [user, setUser] = useState(null)
        , [loading, setLoading] = useState(false)
        , { search } = useLocation()
        , params = new URLSearchParams(search)
        , TOKEN = params.get('TOKEN')
        , dispatch = useDispatch()
        , navigate = useNavigate()

    const login = (formValues) => {
        setLoading(true);
        sys.apiAuth.login({ ...formValues }).then(res => {
            const { message, data: { uid, name, imageUrl, mapKey, token, site, baseInfo } } = res
            dispatch(setAuth({ uid, name, imageUrl, mapKey, token, site, baseInfo, base: baseInfo[0], code: 'system' }))
            dispatch(saveVersion({ version: baseInfo[0].versionInfo }))
            navigate('/')
            onSuccess(message)
            setLoading(false)
            // 记住账号密码
            if (formValues.remember) {
                localStorage.setItem('user', JSON.stringify(formValues))
            } else {
                localStorage.removeItem('user')
            }
        }).catch(error => {
            onError(error)
            setLoading(false)
        })
    }

    useEffect(() => {
        if (TOKEN) {
            setLoading(true)
            sys.apiAuth.tokenLogin(TOKEN).then(res => {
                const { message, data: { uid, name, imageUrl, mapKey, token, site, baseInfo } } = res
                dispatch(setAuth({ uid, name, imageUrl, mapKey, token, site, baseInfo, base: baseInfo[0], code: 'system' }))
                dispatch(saveVersion({ version: baseInfo[0].versionInfo }))
                navigate('/')
                onSuccess(message)
                setLoading(false)
            }).catch(error => {
                onError(error)
                setLoading(false)
            })
        } else {
            const user = localStorage.getItem('user');
            if (user) {
                setUser(JSON.parse(user));
            }
        }

    }, [TOKEN, dispatch, navigate])

    return (
        <LoginComponent
            title="有农后台管理系统"
            img="./analytics.png"
            user={user}
            loading={loading}
            onLogin={login}
        />
    )
}
