import React, { useEffect, useState } from 'react';
import { Drawer, Space, Divider, Button, Form, Input, Upload, Spin, Image, DatePicker } from 'antd';
import { CheckOutlined, CloseOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import QuillItem from '../../../components/quill';

import { useSelector } from 'react-redux';
import { sys, website } from '../../../service';
import { onError, onSuccess } from '../../../utils';
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
dayjs.extend(weekday);
dayjs.extend(localeData);

export default function FormDrawer(props) {

    const { id, open, type, onClose, onRefresh } = props;
    const [spinning, setSpinning] = useState(false)
        , [fileList, setFileList] = useState([])
        , [content, setcontent] = useState(null)
        , [loading, setLoading] = useState(false)
        , [form] = Form.useForm()
        , { serverUrl } = useSelector(state => state.siteInfo);

    async function beforeUpload(file) {
        try {
            const { data: { path } } = await sys.uploadFile(file);
            setFileList([{
                url: path
            }])
            return false;
        } catch (error) {
            onError(error)
        }
    }

    function close() {
        form.resetFields();
        setLoading(false);
        setFileList([])
        onClose();
    }

    function onFinish(values) {
        const queryParams = {
            ...values,
            cover: fileList[0].url,
            content: content,
            day: values.day.format('YYYY-MM-DD')
        }
        setLoading(true)
        website.apiNews[type](queryParams).then(({ message }) => {
            setLoading(false)
            onSuccess(message);
            close();
            onRefresh();
        }).catch(error => {
            setLoading(false)
            onError(error)
        })
    }

    useEffect(() => {
        if (open) {
            // 编辑
            if (type === 'edit') {
                setSpinning(true)
                website.apiNews.getByID(id).then(({ data: { id, title, cover, content, digest, contentUrl, day } }) => {
                    setSpinning(false);
                    const file = { url: cover };
                    setFileList([{
                        url: file.url
                    }])
                    form.setFieldsValue({
                        id,
                        title,
                        cover: [file],
                        content,
                        digest,
                        contentUrl,
                        day: dayjs(day)
                    })

                }).catch(error => {
                    setSpinning(false)
                    onError(error)
                })

            } else {
                form.setFieldValue('day', dayjs())
            }
        }

    }, [open, type, id, form])

    return (
        <Drawer
            title={{ add: '新增', edit: '编辑' }[type]}
            size="large"
            closable={false}
            onClose={close}
            destroyOnClose={true}
            open={open}
            extra={
                <Space split={<Divider type="vertical" />}>
                    <Space.Compact>
                        <Button
                            icon={<CloseOutlined />}
                            onClick={close}
                        >取消</Button>
                        <Button
                            icon={<CheckOutlined />}
                            type='primary'
                            loading={loading}
                            onClick={() => { form.submit() }}
                        >保存</Button>
                    </Space.Compact>
                </Space>
            }
        >
            <Spin
                indicator={<LoadingOutlined style={{ fontSize: 36 }} />}
                spinning={spinning}
            >
                <Form
                    layout="vertical"
                    form={form}
                    name='new.form'
                    onFinish={onFinish}
                >
                    <Form.Item
                        label='标题'
                        name='title'
                        rules={[{ required: true, message: '标题不能为空' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label='封面'
                        name='cover'
                        rules={[{ required: true, message: '请上传封面' }]}
                    >
                        {fileList.length ? (
                            <div>
                                <Image
                                    width={200}
                                    height={110}
                                    style={{ objectFit: 'cover' }}
                                    src={serverUrl + fileList[0].url}
                                />
                                <Button type='text' onClick={() => {
                                    setFileList([]);
                                    form.setFieldValue('cover', null)
                                }}>删除</Button>
                            </div>) : (<Upload
                                listType="picture-card"
                                maxCount={1}
                                fileList={fileList}
                                beforeUpload={(file) => beforeUpload(file)}
                            >
                                <div>
                                    <PlusOutlined style={{ fontSize: 40, color: '#ccc' }} />
                                </div>
                            </Upload>)}
                    </Form.Item>
                    <Form.Item
                        label='发布日期'
                        name='day'
                        rules={[{ required: true, message: '请选择发布日期' }]}
                    >
                        <DatePicker />
                    </Form.Item>
                    <Form.Item
                        label='内容'
                        name='content'
                    >
                        <QuillItem handleParams={(value) => setcontent(value)} />
                    </Form.Item>
                    <Form.Item
                        label='摘要'
                        name='digest'
                    >
                        <Input.TextArea placeholder='请输入摘要' rows={4} />
                    </Form.Item>
                    <Form.Item
                        label='链接(外链时请填写)'
                        name='contentUrl'
                    >
                        <Input placeholder='请输入链接' />
                    </Form.Item>
                    <Form.Item name='id' hidden={true}>
                        <Input />
                    </Form.Item>
                </Form>
            </Spin>
        </Drawer>
    )
}
