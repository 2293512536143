/**
 * 天地图影像底图-url
 */
export const TILELAYER_IMG_URL = `http://t{s}.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=`;

/**
 * 天地图标注-url
 */
export const TILELAYER_CIA_URL = `http://t{s}.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=`;

// 地图参数
export const SUBDOMAINS = [0, 1, 2, 3, 4, 5, 6, 7]
