import { createSlice } from "@reduxjs/toolkit";
import { data as initialState } from '../../utils/menu';


export const tokenSlice = createSlice({
        name: 'menu',
        initialState: { userMenu: sessionStorage.getItem("menu") || initialState },
        reducers: {
            saveMenu(state, { payload }) {
                state.userMenu = payload.userMenu
                sessionStorage.setItem("menu", payload.userMenu)
            }
        }
    })

// 导出action函数
export const { saveMenu } = tokenSlice.actions


// 导出  reducer ， 创建store
export default tokenSlice.reducer
