import React from 'react';
import { Image } from 'antd';
import { useSelector } from 'react-redux';
import { useConfigContextAtom } from '../../store/config';

export default function LogoView() {
    const [config] = useConfigContextAtom()
        , { collapsed } = config
        , { serverUrl, logo } = useSelector(state => state.siteInfo)
        
    return (
        <div className="logo">
            <Image
                preview={false}
                width={collapsed ? 63 : 183}
                src={serverUrl + logo}
            />
        </div>
    )
}
