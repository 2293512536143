import React, { Component } from 'react';
import { BrowserView, TabletView, MobileOnlyView } from 'react-device-detect';
import { Card } from 'antd';
import { Card as MobileCard } from 'antd-mobile';

export default class HomeView extends Component {
    state = {

    }

    componentDidMount = () => {

    }

    render() {
        return (
            <>
                <BrowserView className='browser'>
                    <Card
                        title="首页"
                        className='page-card-full'
                    >

                    </Card>
                </BrowserView>
                <TabletView className='tablet'>
                    <Card
                        title="首页 - tablet"
                    >

                    </Card>
                </TabletView>
                <MobileOnlyView className='mobile'>
                    <MobileCard
                        title="首页 - mobile"
                    >

                    </MobileCard>
                </MobileOnlyView>
            </>
        )
    }
}
