import React, { useEffect } from 'react';
import { App } from 'antd';
import RouteView from './route';
import zhCN from 'antd/locale/zh_CN';
import { ConfigProvider, theme } from 'antd';
import { useConfigContextAtom } from './store/config';
import { setSiteInfo } from './store/modules/site-info';
import { useDispatch } from 'react-redux';
import { sys } from './service';
import dayjs from 'dayjs';
import Notification from './components/notification';
import { notification } from './utils'; 
import './assets/minireset.scss';
import './assets/redefine.scss';
import './assets/style.scss';

dayjs.locale('zh-cn');

const AppView = () => {
    const dispatch = useDispatch()
        , [config] = useConfigContextAtom()

    // 获取站点配置信息
    useEffect(() => {
        sys.apiAuth.config()
            .then(({ data }) => {
                dispatch(setSiteInfo({ ...data }))
            }).catch(error => {
                notification({
                    type: 'error',
                    content: error.message
                })
            })
    }, [dispatch])

    return (
        <ConfigProvider
            locale={zhCN}
            theme={{
                algorithm: config.algorithm === 'dark' ? theme.darkAlgorithm : theme.defaultAlgorithm,
                token: {
                    colorPrimary: config.colorPrimary
                    // screenXXLMin: 2000, // for grid (row/col)
                    // screenXXL: 2000 // default is 1600, for List
                }
            }}
        >
            <App>
                <Notification />
                <RouteView />
            </App>
        </ConfigProvider>
    )
}

export default AppView;
