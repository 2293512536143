import React, { useState } from 'react';
import { Button, Layout, theme } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import { useConfigContextAtom } from '../../store/config';
import LogoView from './logo';
import MenuView from './menu';
import HeaderView from './header';
import ContentView from './content';
import FooterView from './footer';
import ConfigDrawer from './config';
import './index.scss';

const { useToken } = theme;
export default function LayoutBrowserView() {
    const [config, setConfigContext] = useConfigContextAtom()
        , { layout, collapsed } = config
        , [openConfig, setConfig] = useState(false)
        , { token } = useToken()

    return layout === 'sider' ? (
        <Layout
            className='layout-page'
        >
            <Layout.Sider
                style={{
                    backgroundColor: token.colorBgContainer
                }}
                collapsible
                collapsed={collapsed}
                onCollapse={collapsed => {
                    setConfigContext((state) => ({
                        ...state,
                        collapsed
                    }))
                }}
                trigger={(
                    <Button
                        icon={<SwapOutlined />}
                        type="link"
                    >
                        
                    </Button>
                )}
            >
                <LogoView />
                <MenuView />
            </Layout.Sider>

            <Layout>
                <HeaderView
                    setConfig={setConfig}
                />
                <ContentView />
                <FooterView />
            </Layout>

            <ConfigDrawer
                open={openConfig}
                onClose={() => setConfig(false)}
            />
        </Layout>
    ) : null
}
