import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Button, Card, Space, Table, Tooltip, Divider, Popconfirm, Spin } from 'antd';
import { Loading3QuartersOutlined, FormOutlined, QuestionOutlined, QuestionCircleOutlined, DeleteOutlined, PlusOutlined, LoadingOutlined, AndroidOutlined, StopOutlined } from '@ant-design/icons';
import DropdownColumns from '../../components/dropdown-columns';
import { PAGINATION } from '../../utils/constants';
import { onError, onSuccess } from '../../utils';
import { tickets } from '../../service';
import ColumnSearchProps from '../../components/column-search';
import ReplyDrawer from './reply';


const title = '工单列表';
export default function TicketsView() {
    const [queryParams, setQueryParams] = useState({ state: 0, title: null })
        , [loading, setLoading] = useState(false)               // 设置按钮载入状态
        , [selectedRowKey, setSelectedRowKey] = useState(null)
        , [dataSource, setDataSource] = useState([])
        , [pagination, setPagination] = useState(PAGINATION)
        , [openReply, setOpenReply] = useState(false)
        , [openDetails, setOpenDetails] = useState(false)
        , columnsKey = ['title']
        , columns = [
            {
                title: '标题',
                dataIndex: 'title',
                with: 300,
                ...ColumnSearchProps({ queryParams, dataIndex: 'title', setQueryParams }),
            },
            {
                title: '备注',
                dataIndex: 'remark'
            }
        ]

    const fetch = useCallback((params = { pagination: PAGINATION }) => {
        setLoading(true)
        setSelectedRowKey(null)
        const { current, pageSize } = params.pagination
        tickets.api.getList(queryParams, current, pageSize).then(res => {
            const { total, records } = res.data
            setLoading(false)
            setDataSource(records)
            setPagination((state) => ({
                ...state,
                current,
                pageSize,
                total: total
            }))
        }).catch(error => {
            onError(error)
            setLoading(false)
        })
    }, [queryParams])

    useEffect(() => {
        fetch()
    }, [queryParams])

    const record = useMemo(() => {
        return selectedRowKey ? dataSource.find(item => item.id === selectedRowKey) : null
    }, [selectedRowKey, dataSource])

    return (
        <>
            <Card
                title={title}
                className='page-card-full'
                extra={(
                    <Space
                        split={<Divider type="vertical" />}
                    >
                        <Tooltip
                            title="回复工单"
                        >
                            <Button
                                disabled={!selectedRowKey}
                                type='text'
                                onClick={() => setOpenReply(true)}
                            >
                                回复
                            </Button>
                        </Tooltip>

                        <Tooltip title='刷新'>
                            <Button
                                type='text'
                                icon={<Loading3QuartersOutlined />}
                                onClick={() => fetch()}
                            />
                        </Tooltip>

                        <DropdownColumns
                            columns={columns}
                            checkedKeys={columnsKey}
                            onChange={columnsKey => this.setState({ columnsKey })}
                        />
                    </Space>
                )}
            >
                <Spin
                    indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />}
                    spinning={loading}
                >
                    <Table
                        rootClassName='table-full'
                        columns={columns.filter(record => columnsKey.includes(record.dataIndex))}
                        rowKey={record => record.id}
                        dataSource={dataSource}
                        pagination={pagination}
                        onChange={(pagination) => fetch({ pagination })}
                        scroll={{ y: window.innerHeight - 388 }}
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
                            onChange: keys => {
                                setSelectedRowKey(keys[0])
                            }
                        }}
                        onRow={({ id }) => ({
                            onClick: () => {
                                setSelectedRowKey(selectedRowKey === id ? null : id)
                            }
                        })}
                    />
                </Spin>
            </Card>

            <ReplyDrawer
                open={openReply}
                id={selectedRowKey}
                onClose={() => setOpenReply(false)}
                onRefresh={() => fetch({ pagination })}
            />
        </>
    )
}
