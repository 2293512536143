import React, { Component } from 'react';
import { Button, Card, Space, Table, Tooltip, Divider, Popconfirm, Spin, Tabs } from 'antd';
import { Loading3QuartersOutlined, FormOutlined, QuestionOutlined, QuestionCircleOutlined, PlusOutlined, LoadingOutlined, CheckOutlined, StopOutlined } from '@ant-design/icons';
import * as Icon from '@ant-design/icons';
import { menu, site } from '../../service';
import { onError, onSuccess } from '../../utils';
import { connect } from 'react-redux';
import SiteFormDrawer from './site-form';
import MenuFormDrawer from './menu-form';
import DocsFormDrawer from './docs';
import './index.scss';

class SiteView extends Component {
    state = {
        queryParams: {
            search: null
        },
        activeKey: null,
        dataSource: [],
        menuData: [],
        openSite: false,
        openMenu: false,
        type: null,
        loading: false,
        parentId: 0,
        menuID: null,
        openDocs: false,
        menuUrl: null,
    }

    componentDidMount() {
        this.fetch()
    }

    getMenu = (id) => {
        this.setState({ loading: true })
        menu.api.getList(id).then(({ data }) => {
            this.setState({
                activeKey: id,
                loading: false,
                menuData: data
            })
        }).catch(error => {
            onError(error)
            this.setState({ loading: false })
        })
    }


    fetch = () => {
        this.setState({ loading: true })
        site.api.getList().then(({ data }) => {
            const activeKey = data.length ? data[0].id : null
            this.setState({
                loading: false,
                dataSource: data
            })
            if (activeKey) {
                this.getMenu(activeKey)
            }
        }).catch(error => {
            onError(error)
            this.setState({ loading: false })
        })
    }

    // 删除
    onDelete = (id) => {
        this.setState({ delLoading: true })
        site.api.delete(id).then(({ message }) => {
            onSuccess(message)
            this.setState({ delLoading: false })
            this.fetch()
        }).catch(error => {
            onError(error)
            this.setState({ delLoading: false })
        })
    }

    // 删除
    onMenuDelete = (id) => {
        menu.api.delete(id).then(({ message }) => {
            onSuccess(message)
            this.getMenu(id)
        }).catch(error => {
            onError(error)
        })
    }


    render() {
        const { dataSource, activeKey, loading, openSite, type, delLoading, menuData, openMenu, parentId, menuID, openDocs, menuUrl } = this.state
            , { fetch, onDelete, onMenuDelete, getMenu } = this
            , typeOptions = ['目录', '菜单', '按钮']
            , columns = [
                {
                    title: '菜单名称',
                    dataIndex: 'name',
                    render: (name, { icon }) => (
                        <Space>
                            {React.createElement(Icon[icon])}
                            {name}
                        </Space>
                    )
                },
                {
                    title: '状态',
                    dataIndex: 'state',
                    render: state => {
                        return state === 0 ? '停用' : '正常'
                    }
                },
                {
                    title: '类型',
                    dataIndex: 'type',
                    render: type => typeOptions[Number(type) - 1]
                },
                {
                    title: '路由地址',
                    dataIndex: 'url'
                },
                {
                    title: '排序',
                    dataIndex: 'sort'
                },
                {
                    title: '操作',
                    dataIndex: 'id',
                    render: (id, { type, children, state, url }) => {
                        return (
                            <Space
                                split={<Divider type="vertical" />}
                            >
                                <Button
                                    type="text"
                                    disabled={type === 2}
                                    onClick={() => {
                                        this.setState({
                                            openMenu: true,
                                            parentId: id
                                        })
                                    }}
                                >
                                    添加
                                </Button>

                                <Button
                                    type="text"
                                    onClick={() => {
                                        this.setState({
                                            openMenu: true,
                                            menuID: id
                                        })
                                    }}
                                >
                                    编辑
                                </Button>

                                {
                                    state === 0 ? (
                                        <Button
                                            icon={<CheckOutlined />}
                                            type="text"
                                            onClick={() => {
                                                this.setState({
                                                    openMenu: true,
                                                    menuID: id
                                                })
                                            }}
                                        >
                                            启用
                                        </Button>
                                    ) : (
                                        <Popconfirm
                                            onConfirm={() => onMenuDelete(id)}
                                            title='此操作将永久删除该信息, 是否继续？'
                                            disabled={children}
                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        >
                                            <Tooltip title='删除所选中的菜单'>
                                                <Button
                                                    disabled={children}
                                                    icon={<StopOutlined />}
                                                    type="text"
                                                    loading={delLoading}
                                                    danger
                                                >
                                                    停用
                                                </Button>
                                            </Tooltip>
                                        </Popconfirm>
                                    )
                                }

                                <Button
                                    icon={<QuestionOutlined />}
                                    type="text"
                                    onClick={() => {
                                        this.setState({
                                            openDocs: true,
                                            menuUrl: url
                                        })
                                    }}
                                >
                                    文档
                                </Button>
                            </Space >
                        )
                    }
                }
            ]
        return (
            <>
                <Card
                    title="系统模块管理"
                    className='page-card-full'
                    extra={(
                        <Space
                            split={<Divider type="vertical" />}
                        >
                            <Tooltip title='新增菜单'>
                                <Button
                                    type='text'
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                        this.setState({
                                            openMenu: true,
                                            type: 'add'
                                        })
                                    }}
                                >
                                    新增菜单
                                </Button>
                            </Tooltip>
                        </Space>
                    )}
                >
                    <Tabs
                        tabPosition='left'
                        rootClassName='site-tabs'
                        activeKey={activeKey}
                        tabBarStyle={{ width: 200 }}
                        onChange={getMenu}
                        tabBarExtraContent={
                            {
                                left: (
                                    <Space
                                        split={<Divider type="vertical" />}
                                    >
                                        <Tooltip title='新增系统模块'>
                                            <Button
                                                type='text'
                                                icon={<PlusOutlined />}
                                                onClick={() => {
                                                    this.setState({
                                                        openSite: true,
                                                        type: 'add'
                                                    })
                                                }}
                                            >

                                            </Button>
                                        </Tooltip>

                                        <Tooltip title='编辑所选中的系统模块'>
                                            <Button
                                                type='text'
                                                icon={<FormOutlined />}
                                                onClick={() => {
                                                    this.setState({
                                                        openSite: true,
                                                        type: 'edit'
                                                    })
                                                }}
                                            >

                                            </Button>
                                        </Tooltip>

                                        <Popconfirm
                                            onConfirm={() => onDelete(activeKey)}
                                            title='此操作将停用该系统, 是否继续？'
                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        >
                                            <Tooltip title='停用所选中的系统'>
                                                <Button
                                                    icon={<StopOutlined />}
                                                    type="text"
                                                    loading={delLoading}
                                                    danger
                                                >

                                                </Button>
                                            </Tooltip>
                                        </Popconfirm>
                                    </Space>
                                ),
                                right: (
                                    <Tooltip title='刷新'>
                                        <Button
                                            type='text'
                                            icon={<Loading3QuartersOutlined />}
                                            onClick={() => fetch()}
                                        />
                                    </Tooltip>
                                )
                            }
                        }
                        items={
                            dataSource.map(({ id, name, icon }) => {
                                return {
                                    label: (
                                        <Space>
                                            {React.createElement(Icon[icon])}
                                            {name}
                                        </Space>
                                    ),
                                    key: id,
                                    children: (
                                        <Spin
                                            indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />}
                                            spinning={loading}
                                        >
                                            <Table
                                                dataSource={menuData}
                                                columns={columns}
                                                rowKey={record => record.id}
                                                pagination={false}
                                            />
                                        </Spin>
                                    ),
                                }
                            })
                        }
                    />
                </Card>


                <SiteFormDrawer
                    open={openSite}
                    id={activeKey}
                    type={type}
                    onClose={() => this.setState({ openSite: false })}
                    onRefresh={this.fetch}
                />

                <MenuFormDrawer
                    open={openMenu}
                    parentId={parentId}
                    siteId={activeKey}
                    onClose={() => this.setState({ openMenu: false, parentId: 0, menuID: null })}
                    onRefresh={() => this.getMenu(activeKey)}
                    id={menuID}
                />

                <DocsFormDrawer
                    open={openDocs}
                    menuUrl={menuUrl}
                    onClose={() => this.setState({ openDocs: false, menuUrl: null })}
                />
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    serverUrl: state.siteInfo.serverUrl
})

export default connect(mapStateToProps, {})(SiteView)
