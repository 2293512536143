import React, { useState, createElement, useEffect } from 'react';
import { Space, Button, Drawer, Spin, Form, Input, Divider, Select } from 'antd';
import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { site } from '../../service';
import { onSuccess, onError } from '../../utils';
import * as Icon from '@ant-design/icons';
import iconData from '../../utils/icons';

const { Option, OptGroup } = Select;
export default function SiteFormDrawer(props) {
    const { open, id, type } = props
        , [spinning, setSpinning] = useState(false)     // 设置获取数据载入状态
        , [loading, setLoading] = useState(false)       // 设置按钮载入状态
        , [form] = Form.useForm()

    useEffect(() => {
        if (open) {
            if (id && type === 'edit') {
                // 编辑时获取信息
                setSpinning(true)
                site.api.getById(id)
                    .then(({ data: { name, code, icon, port } }) => {
                        form.setFieldsValue({
                            id,
                            name,
                            code,
                            icon,
                            port
                        })
                        setSpinning(false)
                    })
                    .catch(error => {
                        onError(error)
                        setSpinning(false)
                    })
            }
        }
    }, [open, id, type, form])

    // 关闭窗口
    const onClose = () => {
        form.resetFields()
        setLoading(false)
        props.onClose()
    }

    // 提交表单
    const onFinish = (values) => {
        setLoading(true)
        const promise = type === 'edit' ? site.api.edit(values) : site.api.add(values)
        promise.then(({ message }) => {
            onSuccess(message)
            props.onRefresh()
            onClose()
        }).catch(error => {
            onError(error)
            setLoading(false)
        })
    }

    // 渲染图标
    const renderIcon = (name) => {
        return createElement(Icon[name])
    }

    return (
        <Drawer
            title={type === 'edit' ? '编辑' : '新增'}
            open={open}
            onClose={onClose}
            closable={false}
            destroyOnClose={true}
            extra={
                <Space
                    split={<Divider type="vertical" />}
                >
                    <Space.Compact>
                        <Button
                            icon={<CloseOutlined />}
                            onClick={onClose}
                        >
                            取消
                        </Button>

                        <Button
                            icon={<CheckOutlined />}
                            type="primary"
                            onClick={() => form.submit()}
                            loading={loading}
                        >
                            保存
                        </Button>
                    </Space.Compact>
                </Space>
            }
        >
            <Spin
                indicator={<LoadingOutlined style={{ fontSize: 36 }} />}
                spinning={spinning}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="site.form"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="name"
                        label="名称"
                        rules={[{ required: true, message: '系统名称不能为空' }]}
                    >
                        <Input placeholder="请填写" />
                    </Form.Item>

                    <Form.Item
                        name="code"
                        label="CODE"
                        rules={[{ required: true, message: 'CODE不能为空' }]}
                    >
                        <Input placeholder="请填写" />
                    </Form.Item>

                    <Form.Item
                        name="port"
                        label="端口"
                        rules={[{ required: true, message: '端口不能为空' }]}
                        help="开发端口号"
                    >
                        <Input placeholder="请填写" />
                    </Form.Item>

                    <Form.Item
                        name="icon"
                        label="图标"
                    >
                        <Select
                            placeholder="请选择图标"
                            listItemHeight={.256}
                            popupClassName="custom"
                        >
                            {
                                iconData.map(item => {
                                    return (
                                        <OptGroup key={item.value} label={item.label}>
                                            {
                                                item.children.map(key => {
                                                    return <Option key={key} value={key}>{renderIcon(key)}</Option>
                                                })
                                            }
                                        </OptGroup>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>


                    <Form.Item
                        name="id"
                        hidden={false}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Spin>
        </Drawer>
    )
}
