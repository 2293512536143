import { useEffect } from 'react';
import { App } from "antd";
import { close } from '../../store/modules/global';
import { useDispatch, useSelector } from "react-redux";

export default function Notification() {
    const dispatch = useDispatch()
        , { notification } = App.useApp()
        , { notificationInfo: { type, message, description, placement, duration, btn } } = useSelector((state) => state.global)

    useEffect(() => {
        if (notification && description) {
            notification[type]({
                message,
                description,
                placement,
                duration,
                btn,
                onClose: () => {
                    dispatch(close())
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification, description])
    return null
}
