import React, { useEffect, useState } from 'react';
import { Row, Col, Layout, Space, Button, Dropdown, Avatar, Divider, theme, Tooltip, Drawer, Menu, Badge, Popover, Tabs } from 'antd';
import { SkinOutlined, SettingOutlined, UnlockOutlined, LogoutOutlined, MenuOutlined, QuestionOutlined, BellOutlined } from '@ant-design/icons';
import * as Icon from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { clear } from '../../store/modules/auth';
import BreadcrumbView from './breadcrumb';
import { useLocation } from 'react-router-dom';
import SetPasswordDrawer from './set-password';
import SetInfoDrawer from './set-info';
import DocsView from '../../components/docs-view';
import { sys } from '../../service';
import { onError } from '../../utils';

const { useToken } = theme;
export default function HeaderView(props) {
    const dispatch = useDispatch()
        , { token } = useToken()
        , [open, setOpen] = useState(false)
        , auth = useSelector(state => state.auth)
        , { name, imageUrl, site } = auth
        , { serverUrl, code } = useSelector(state => state.siteInfo)
        , [openSetPassword, setOpenSetPassword] = useState(false)
        , [openSetInfo, setOpenSetInfo] = useState(false)
        , { pathname, state } = useLocation()
        , [help, setHelp] = useState(null)
        , [openHelp, setOpenHelp] = useState(false)
        // 用户下拉菜单
        , userItems = [
            {
                key: '1',
                label: '设置密码',
                icon: <UnlockOutlined />,
                onClick: () => {
                    setOpenSetPassword(true)
                }
            },
            {
                key: '3',
                label: '账号设置',
                icon: <SettingOutlined />,
                onClick: () => {
                    setOpenSetInfo(true)
                }
            },
            {
                type: 'divider',
            },
            {
                key: '4',
                label: '退出登录',
                icon: <LogoutOutlined />,
                onClick: () => {
                    dispatch(clear())
                }
            },
        ]
        , [activeKey, setActiveKey] = useState('0')

    const TabsItems = [
        {
            key: '0',
            label: `待回复`,
            children: `Content of Tab Pane 1`,
        },
        {
            key: '1',
            label: `处理中`,
            children: `Content of Tab Pane 2`,
        },
        {
            key: '2',
            label: `已完结`,
            children: `Content of Tab Pane 3`,
        }
    ]

    useEffect(() => {
        sys.getDocs(pathname).then(({ data }) => {
            setHelp(data)
        }).catch(error => {
            onError(error)
        })
    }, [pathname])

    return (
        <>
            <Layout.Header
                style={{
                    padding: 0,
                    backgroundColor: token.colorBgContainer
                }}
            >
                <Row
                    style={{ padding: '0px 16px' }}
                >
                    <Col span={12}>
                        <Space
                            split={<Divider type="vertical" />}
                        >
                            <Tooltip title="系统切换">
                                <Button
                                    icon={<MenuOutlined />}
                                    type='text'
                                    onClick={() => {
                                        setOpen(true)
                                    }}
                                >

                                </Button>
                            </Tooltip>
                            <BreadcrumbView />
                        </Space>
                    </Col>

                    <Col
                        span={12}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <Space
                            split={<Divider type="vertical" />}
                        >
                            <Dropdown
                                menu={{
                                    items: userItems,
                                    selectable: true,
                                    selectedKeys: []
                                }}
                                placement="bottomRight"
                                trigger='click'
                                overlayStyle={{ minWidth: 160 }}
                                arrow
                            >
                                <Button
                                    type='text'
                                >
                                    <Avatar
                                        src={serverUrl + imageUrl}
                                        style={{
                                            width: 32,
                                            height: 32,
                                            marginTop: -4,
                                            marginRight: 10,
                                        }}
                                    />
                                    {name}
                                </Button>
                            </Dropdown>

                            <Button
                                type="text"
                                icon={<SkinOutlined />}
                                onClick={() => props.setConfig(true)}
                            />

                            <Badge
                                dot={true}
                            >
                                <Popover
                                    placement="bottomRight"
                                    content={(
                                        <Tabs
                                            activeKey={activeKey}
                                            items={TabsItems}
                                            onChange={setActiveKey}
                                            tabBarExtraContent={{
                                                right: (
                                                    <Button
                                                        type='link'
                                                    >
                                                        提交工单
                                                    </Button>
                                                )
                                            }}
                                            style={{ width: 400, minHeight: 600 }}
                                        />
                                    )}
                                    trigger="click"
                                >
                                    <Button
                                        icon={
                                            <BellOutlined
                                                style={{
                                                    fontSize: 18
                                                }}
                                            />
                                        }
                                        type="text"
                                    />
                                </Popover>
                            </Badge>

                            <Tooltip title='帮助'>
                                <Button
                                    disabled={!help}
                                    type='text'
                                    icon={<QuestionOutlined />}
                                    onClick={() => setOpenHelp(true)}
                                />
                            </Tooltip>
                        </Space>
                    </Col>
                </Row>

                <Drawer
                    open={open}
                    title="有农数字农业管理系统"
                    placement="left"
                    onClose={() => setOpen(false)}
                >
                    <Menu
                        style={{ border: 'none', backgroundColor: 'transparent' }}
                        items={site.map(({ name, code, icon, port }) => ({ key: code, label: name, port, icon: icon ? React.createElement(Icon[icon]) : null }))}
                        defaultSelectedKeys={[code]}
                        onSelect={({ key }) => {
                            const { port } = site.find(item => item.code === key)
                                , url = process.env.NODE_ENV === 'development' ? `http://localhost:${port}/login?TOKEN=${auth.token}` : `http://${key}.yn-ag.cn/login?TOKEN=${auth.token}`
                            window.location.href = url
                        }}
                    />
                </Drawer>
            </Layout.Header>

            <SetPasswordDrawer
                open={openSetPassword}
                onClose={() => setOpenSetPassword(false)}
            />

            <SetInfoDrawer
                open={openSetInfo}
                onClose={() => setOpenSetInfo(false)}
            />

            <DocsView
                open={openHelp}
                title={state?.title}
                dataSource={help}
                onClose={() => setOpenHelp(false)}
            />
        </>
    )
}
