import React, { useEffect, useState } from 'react';
import { FeatureGroup, Polyline, Marker, useMap, Tooltip } from 'react-leaflet';
import L from "leaflet";



export default function MqttGpsView(props) {
    const { defaultTrack = [], realTimeTrack = null, lock = null } = props
        , refTrajector = React.useRef(null)
        , [layers, setLayers] = useState({})
        , map = useMap()

    useEffect(() => {
        if (realTimeTrack) {
            const { key, state, latLng = [] } = realTimeTrack
                , target = layers[key]

            if (target) {
                target.getLayers().forEach(NewClass => {
                    const { options: { type } } = NewClass
                    if (type === 'Polyline' && state === NewClass.options.state) {
                        NewClass.addLatLng(latLng)
                    }
                    if (type === 'Marker') {
                        NewClass.setLatLng(latLng)
                        if (lock === key) {
                            map.setView(latLng)
                        }
                    }
                })
            }
        }
    }, [realTimeTrack, map, layers, lock])

    return (
        <FeatureGroup
            ref={refTrajector}
            type='mqtt'
        >
            {
                defaultTrack.map(item => {
                    const { key, label, positions = [], isFlip, msg } = item
                        , latLng = [...positions.flat()].pop()
                        , __html = msg ? `<p>${label}</p><p>${msg}</p>` : `<p>${label}</p>`

                    return positions.flat().flat().length ? (
                        <FeatureGroup
                            key={key}
                            id={key}
                            eventHandlers={{
                                add: ({ target }) => {
                                    setLayers((data) => {
                                        let record = {}
                                        record[key] = target
                                        return {
                                            ...data,
                                            ...record
                                        }
                                    })
                                    map.setView(latLng)
                                }
                            }}
                        >
                            <Polyline
                                type='Polyline'
                                positions={positions[isFlip ? 1 : 0]}
                                pathOptions={{ color: '#f5222d' }}
                                state={isFlip ? 1 : 0}
                            />

                            <Polyline
                                type='Polyline'
                                positions={positions[isFlip ? 0 : 1]}
                                pathOptions={{ color: '#a0d911' }}
                                state={isFlip ? 0 : 1}
                            />

                            <Marker
                                type='Marker'
                                position={latLng}
                                icon={L.icon({ iconUrl: require('./image/end.png'), iconSize: [48, 48], iconAnchor: [24, 24] })}
                            >
                                <Tooltip
                                    direction="top"
                                    permanent={true}
                                    offset={[0, -16]}
                                >
                                    <div dangerouslySetInnerHTML={{ __html }}></div>
                                </Tooltip>
                            </Marker>
                        </FeatureGroup>
                    ) : null
                })
            }
        </FeatureGroup>
    )
}
