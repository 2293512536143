/**
 * 计算多边形的面积 亩
 * @param {*} coords 
 * @returns 
 */
export const calculateArea = function calculateArea(coords) {
    var RADIUS = 6378137;
    var rad = function rad(_) {
        return _ * Math.PI / 180;
    };
    var p1, p2, p3, lowerIndex, middleIndex, upperIndex,
        area = 0,
        coordsLength = coords.length;

    if (coordsLength > 2) {
        for (var i = 0; i < coordsLength; i++) {
            if (i === coordsLength - 2) {// i = N-2
                lowerIndex = coordsLength - 2;
                middleIndex = coordsLength - 1;
                upperIndex = 0;
            } else if (i === coordsLength - 1) {// i = N-1
                lowerIndex = coordsLength - 1;
                middleIndex = 0;
                upperIndex = 1;
            } else { // i = 0 to N-3
                lowerIndex = i;
                middleIndex = i + 1;
                upperIndex = i + 2;
            }
            p1 = coords[lowerIndex];
            p2 = coords[middleIndex];
            p3 = coords[upperIndex];
            area += (rad(p3.lng) - rad(p1.lng)) * Math.sin(rad(p2.lat));
        }

        area = area * RADIUS * RADIUS / 2;
    }

    return (Math.abs(area) * 0.0015).toFixed(2);
};


/**
 * 是否数组
 */
export function array(value) {
    if (typeof Array.isArray === 'function') {
        return Array.isArray(value)
    }
    return Object.prototype.toString.call(value) === '[object Array]'
}

/**
 * 是否对象
 */
export function object(value) {
    return Object.prototype.toString.call(value) === '[object Object]'
}

/**
 * 获取多边形或线的坐标组
 */
export function getLatLngs(target, isFormat = true, type = null) {
    type = type ? type : target.options.type
    const value = type === 'Marker' ? target.getLatLng() : target.getLatLngs()
    return isFormat ? formatLatLngs(value) : value
}

/**
 * 格式化坐标值
 * @param {*} value 
 * @returns 
 */
export function formatLatLngs(value) {
    return array(value) ? value.map(record => formatLatLngs(record)) : object(value) ? [value.lat, value.lng] : []
}




/**
 * 转换颜色格式
 * @param {*} hex 
 * @param {*} opacity 
 * @returns 
 */
export const hexToRgba = (hex, opacity = 1) => {
    return {
        r: parseInt('0x' + hex.slice(1, 3)),
        g: parseInt('0x' + hex.slice(3, 5)),
        b: parseInt('0x' + hex.slice(5, 7)),
        a: opacity,
    };
}

// 流文件下载
export const download = (blob, filename) => {
    let a = document.createElement('a')
        , url = window.URL.createObjectURL(blob)
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
}
