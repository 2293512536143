import React from 'react';
import RouteView from '../route';
import NavBarView from './navBar';
import TabBarView from './tabBar';
import './index.scss';

export default function LayoutMobileView() {
    

    

    return (
        <div className='layout-page'>
            <div className="layout-page-top">
                <NavBarView />
            </div>

            <div className="layout-page-body">
                <RouteView />
            </div>

            <div className="layout-page-bottom">
                <TabBarView />
            </div>
        </div>
    )
}
