import React, { useState, useEffect } from 'react';
import { Space, Button, Drawer, Spin, Form, Input, Select, Divider } from 'antd';
import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { role } from '../../service';
import { onSuccess, onError } from '../../utils';

export default function RoleFormDrawer(props) {
    const { open, id, stateStr } = props
        , [spinning, setSpinning] = useState(false)     // 设置获取数据载入状态
        , [loading, setLoading] = useState(false)       // 设置按钮载入状态
        , [form] = Form.useForm()
        , [wxList, setWxList] = useState([])            // 微信小程序列表

    useEffect(() => {
        if (open) {
            if (id) {
                // 编辑时获取信息
                setSpinning(true)
                role.api.getById(id)
                    .then(({ data: { name, code, description, state, appIds } }) => {
                        form.setFieldsValue({ id, name, code, description, state, appIds })
                        setSpinning(false)
                    })
                    .catch(error => {
                        onError(error)
                        setSpinning(false)
                    })
            }
            // 查询所有小程序
            role.api.getWxapp().then(({ data }) => {
                setWxList(data)
            }).catch(error => {
                onError(error)
            })
        }
    }, [open, id, form])

    // 关闭窗口
    const onClose = () => {
        form.resetFields()
        setLoading(false)
        props.onClose()
    }

    // 提交表单
    const onFinish = (values) => {
        setLoading(true)
        const promise = id ? role.api.edit(values) : role.api.add(values)
        promise.then(({ message }) => {
            onSuccess(message)
            props.onRefresh()
            onClose()
        }).catch(error => {
            onError(error)
            setLoading(false)
        })
    }

    return (
        <Drawer
            title={id ? '编辑' : '新增'}
            open={open}
            onClose={onClose}
            closable={false}
            destroyOnClose={true}
            extra={
                <Space
                    split={<Divider type="vertical" />}
                >
                    <Space.Compact>
                        <Button
                            icon={<CloseOutlined />}
                            onClick={onClose}
                        >
                            取消
                        </Button>

                        <Button
                            icon={<CheckOutlined />}
                            type="primary"
                            onClick={() => form.submit()}
                            loading={loading}
                        >
                            保存
                        </Button>
                    </Space.Compact>
                </Space>
            }
        >
            <Spin
                indicator={<LoadingOutlined style={{ fontSize: 36 }} />}
                spinning={spinning}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="role.form"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="name"
                        label="名称"
                        rules={[{ required: true, message: '角色名称不能为空' }]}
                    >
                        <Input placeholder="请填写" />
                    </Form.Item>


                    <Form.Item
                        name="code"
                        label="CODE"
                        rules={[{ required: true, message: '角色CODE不能为空' }]}
                    >
                        <Input placeholder="请填写" />
                    </Form.Item>

                    <Form.Item
                        name="state"
                        label="状态"
                        rules={[{ required: true, message: '角色状态不能为空' }]}
                    >
                        <Select
                            options={stateStr.map((label, value) => ({ label, value }))}
                            placeholder="请选择"
                        />
                    </Form.Item>

                    <Form.Item
                        name="appIds"
                        label="微信小程序"
                    >
                        <Select
                            options={wxList}
                            fieldNames={{ label: 'name', value: 'id' }}
                            mode="multiple"
                            allowClear
                            placeholder="请选择"
                        />
                    </Form.Item>

                    <Form.Item
                        name="description"
                        label="角色描述"
                    >
                        <Input.TextArea style={{ height: 150 }} placeholder="请填写" />
                    </Form.Item>

                    <Form.Item
                        name="id"
                        hidden={true}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Spin>
        </Drawer>
    )
}
