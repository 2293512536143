import axios from 'axios';
import API from './config';


//设置axios基础路径
const request = axios.create({
    baseURL: API,
    timeout: 1000 * 300,
})

// 请求拦截器
request.interceptors.request.use(config => {
    const auth = sessionStorage.getItem('auth')
        , token = auth ? JSON.parse(auth).token : null
        , base = auth ? JSON.parse(auth).base : null
        , version = sessionStorage.getItem('version')
        , current = version ? JSON.parse(version).findIndex(item => item.isDef === 1) : -1 //version.findIndex(item => item.isDef === 1)
    if (token && config.url.indexOf('/sys/auth/login') === -1 && config.url.indexOf('/sys/config/') === -1) {
        config.headers['token'] = token
        config.headers['baseId'] = base.id || ''
        if (current >= 0) {
            config.headers['sysVersion'] = JSON.parse(version)[current].id
        }
    }
    config.headers['siteCode'] = 'system'
    return config
}, error => {
    return error;
})

// 响应拦截器
request.interceptors.response.use(
    response => {
        const { data: { code }, config: { responseType } } = response
        return code === 0 || responseType === 'blob' ? response.data : Promise.reject(response.data)
    },
    error => {
        return Promise.reject(error)
    }
)

/**
 * 配置上传
 * @param {*} url 
 * @param {*} param1 
 * @param {*} fileName 
 * @returns 
 */
request.uploadFile = (url, { file, fileName = 'file', responseType }) => {
    let formData = new FormData();
    formData.append(fileName, file)
    return request({
        url,
        headers: { 'Content-Type': 'multipart/form-data' },
        transformRequest: data => data,
        method: 'POST',
        data: formData,
        responseType
    })
}

// 最后把封装好的axios导出
export default request
