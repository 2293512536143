import { createSlice } from "@reduxjs/toolkit";

const info = sessionStorage.getItem("siteInfo")

export const SiteInfoSlice = createSlice({
    name: 'siteInfo',
    initialState: info ? JSON.parse(info) : { code: null, logo: null, serverUrl: null, uploadUrl: null, version: null, copyright: null, recordNumber: null, language: null, timeZone: null },
    reducers: {
        setSiteInfo(state, { payload }) {
            const { code, logo, serverUrl, uploadUrl, version, copyright, recordNumber, language, timeZone } = payload
            state.code = code
            state.logo = logo
            state.serverUrl = serverUrl
            state.uploadUrl = uploadUrl
            state.version = version
            state.copyright = copyright
            state.recordNumber = recordNumber
            state.language = language
            state.timeZone = timeZone
            sessionStorage.setItem("siteInfo", JSON.stringify(payload))
        }
    }
})

// 导出action函数
export const { setSiteInfo } = SiteInfoSlice.actions


// 导出  reducer ， 创建store
export default SiteInfoSlice.reducer
