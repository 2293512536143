import React, { useCallback, useEffect, useState } from 'react';

import { Card, Space, Divider, Spin, Button, Table, Image } from 'antd'
import { PlusOutlined, FormOutlined, LoadingOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { website } from '../../../service'
import { onError, onSuccess } from '../../../utils';
import { PAGINATION } from '../../../utils/constants'
import FormDrawer from './form';



export default function NewsView() {

    const [open, setOpen] = useState(false)
        , [type, setType] = useState('add')
        , [loading, setLoading] = useState(false)
        , [dataSource, setDataSource] = useState([])
        , [pagination, setPagination] = useState(PAGINATION)
        , [selectedRowKey, setSelectedRowKey] = useState(null)
        , { serverUrl } = useSelector(state => state.siteInfo)
    const columns = [{
        title: '封面',
        dataIndex: 'cover',
        width: 232,
        render: row => {
            return <Image
                width={200}
                height={100}
                style={{ objectFit: 'cover' }}
                src={serverUrl + row} />
        }
    }, {
        title: '标题',
        dataIndex: 'title'
    }, {
        title: '摘要',
        dataIndex: 'digest'
    }, {
        title: '发布日期',
        dataIndex: 'day'
    }, {
        title: '创建时间',
        dataIndex: 'createTime'
    }]

    function onDelete() {
        setLoading(true)
        website.apiNews.delete(selectedRowKey).then(({ message }) => {
            setLoading(false)
            setSelectedRowKey(null)
            onSuccess(message)
            fetch(pagination)
        }).catch(error => {
            setLoading(false)
            onError(error)
        })
    }


    const fetch = useCallback((params = PAGINATION) => {
        setLoading(true)
        const { current, pageSize } = params;
        website.apiNews.getList({ current, pageSize }).then((res) => {
            setLoading(false)
            const { total, records } = res.data;
            setDataSource(records)
            setPagination(pre => ({
                ...pre,
                current,
                total
            }))

        }).catch((error) => {
            setLoading(false)
            onError(error)
        })
    }, [])

    useEffect(() => {
        fetch()
    }, [fetch])

    return (
        <Spin
            indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />}
            spinning={loading}>
            <Card
                title='新闻管理'
                className='page-card-full'
                extra={(
                    <Space split={<Divider type="vertical" />}>
                        <Button
                            type='text'
                            icon={<PlusOutlined />}
                            disabled={selectedRowKey}
                            onClick={() => {
                                setType('add')
                                setOpen(true)
                            }}
                        >新增</Button>
                        <Button
                            type='text'
                            icon={<FormOutlined />}
                            disabled={!selectedRowKey}
                            onClick={() => {
                                setType('edit')
                                setOpen(true)
                            }}
                        >编辑</Button>
                        <Button
                            type='text'
                            icon={<DeleteOutlined />}
                            disabled={!selectedRowKey}
                            onClick={() => {
                                onDelete()
                            }}
                        >删除</Button>
                    </Space>
                )}>
                <Table
                    scroll={{ y: window.innerHeight - 388 }}
                    rowKey={record => record.id}
                    columns={columns}
                    dataSource={dataSource}
                    pagination={pagination}
                    onChange={(pagination) => fetch(pagination)}
                    rowSelection={{
                        type: 'checkbox',
                        hideSelectAll: true,
                        selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
                        onSelect: (record, selected) => {
                            setSelectedRowKey(selected ? record.id : null)
                        }
                    }}
                    onRow={({ id }) => {
                        return {
                            onClick: () => {
                                setSelectedRowKey(selectedRowKey === id ? null : id)
                            }
                        }
                    }}
                />
                <FormDrawer
                    id={selectedRowKey}
                    open={open}
                    type={type}
                    onClose={() => { setOpen(false) }}
                    onRefresh={() => fetch(pagination)}
                />
            </Card>
        </Spin>
    )
}
