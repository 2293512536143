import { createSlice } from "@reduxjs/toolkit";

const storage = sessionStorage.getItem("version")
export const versionSlice = createSlice({
    name: 'version',
    initialState: { version: storage ? JSON.parse(storage) : [] },
    reducers: {
        saveVersion(state, { payload }) {
            state.version = payload.version
            sessionStorage.setItem("version", JSON.stringify(payload.version))
        },
        setDef(state, { payload: { key } }) {
            const version = state.version.map(row => row.id === key ? { ...row, isDef: 1 } : { ...row, isDef: 0 })
            state.version = version
            sessionStorage.setItem("version", JSON.stringify(version))
            window.location.reload()
        }
    }
})

// 导出action函数
export const { saveVersion, setDef } = versionSlice.actions


// 导出  reducer ， 创建store
export default versionSlice.reducer
