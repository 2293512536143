import React from 'react';
import { TabBar } from 'antd-mobile';
import { AppOutline, MessageOutline, UnorderedListOutline, UserOutline } from 'antd-mobile-icons';
import { useNavigate, useLocation } from 'react-router-dom';

export default function TabBarView() {
    const { pathname } = useLocation()
    , navigate = useNavigate()

    const tabs = [
        {
            key: '/home',
            title: '首页',
            icon: <AppOutline />,
        },
        {
            key: '/todo',
            title: '待办',
            icon: <UnorderedListOutline />,
        },
        {
            key: '/message',
            title: '消息',
            icon: <MessageOutline />,
        },
        {
            key: '/me',
            title: '我的',
            icon: <UserOutline />,
        },
    ]

    const setRouteActive = (key) => {
        const { title } = tabs.find(tab => tab.key === key)
        navigate(key, { state: { title } }, { replace: true })
    }

    return (
        <TabBar
            activeKey={pathname}
            onChange={value => setRouteActive(value)}
        >
            {
                tabs.map(item => (
                    <TabBar.Item
                        key={item.key}
                        icon={item.icon}
                        title={item.title}
                    />
                ))
            }
        </TabBar>
    )
}
