import React, { useState, useEffect } from 'react';
import { Space, Button, Drawer, Spin, Form, Input, Divider } from 'antd';
import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { docs } from '../../service';
import { onSuccess, onError } from '../../utils';
import QuillItem from '../../components/quill';

export default function DocsFormDrawer(props) {
    const { open, menuUrl } = props
        , [spinning, setSpinning] = useState(false)     // 设置获取数据载入状态
        , [loading, setLoading] = useState(false)       // 设置按钮载入状态
        , [form] = Form.useForm()
        

    useEffect(() => {
        if (open && menuUrl) {
            // 编辑时获取信息
            setSpinning(true)
            docs.api.getByUrl(menuUrl).then(({ data }) => {
                if (data) {
                    const { id, videoUrl, content } = data
                    form.setFieldsValue({
                        id,
                        menuUrl,
                        videoUrl,
                        content
                    })
                } else {
                    form.setFieldValue('menuUrl', menuUrl)
                }
                setSpinning(false)
            }).catch(error => {
                onError(error)
                setSpinning(false)
            })
        }
    }, [open, menuUrl, form])

    // 关闭窗口
    const onClose = () => {
        form.resetFields()
        setLoading(false)
        props.onClose()
    }

    // 提交表单
    const onFinish = (values) => {
        setLoading(true)
        const promise = values.id ? docs.api.edit(values) : docs.api.add(values)
        promise.then(({ message }) => {
            onSuccess(message)
            onClose()
        }).catch(error => {
            onError(error)
            setLoading(false)
        })
    }

    return (
        <Drawer
            title={'帮助文档'}
            open={open}
            onClose={onClose}
            closable={false}
            destroyOnClose={true}
            size="large"
            extra={
                <Space
                    split={<Divider type="vertical" />}
                >
                    <Space.Compact>
                        <Button
                            icon={<CloseOutlined />}
                            onClick={onClose}
                        >
                            取消
                        </Button>

                        <Button
                            icon={<CheckOutlined />}
                            type="primary"
                            onClick={() => form.submit()}
                            loading={loading}
                        >
                            保存
                        </Button>
                    </Space.Compact>
                </Space>
            }
        >
            <Spin
                indicator={<LoadingOutlined style={{ fontSize: 36 }} />}
                spinning={spinning}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="docs.form"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="videoUrl"
                        label="视频地址"
                    >
                        <Input placeholder="请填写" />
                    </Form.Item>

                    <Form.Item
                        name="content"
                        label="内容"
                    >
                        <QuillItem handleParams={(value) => form.setFieldsValue({ content: value })} />
                    </Form.Item>

                    <Form.Item
                        name="menuUrl"
                        hidden={false}
                    >
                        <Input placeholder="请填写" />
                    </Form.Item>

                    <Form.Item
                        name="id"
                        hidden={false}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Spin>
        </Drawer>
    )
}
