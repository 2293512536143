import React, { useState, useEffect } from 'react';
import { Space, Button, Drawer, Spin, Form, Input, Divider, Select, InputNumber } from 'antd';
import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { wxfarm } from '../../../service';
import { onSuccess, onError } from '../../../utils';

const { Option, OptGroup } = Select;
export default function MenuFormDrawer(props) {
    const { open, id } = props
        , [spinning, setSpinning] = useState(false)     // 设置获取数据载入状态
        , [loading, setLoading] = useState(false)       // 设置按钮载入状态
        , [form] = Form.useForm()
        , typeOptions = [
            { label: '目录', value: '1' },
            { label: '菜单', value: '2' },
            // { label: '按钮', value: '3' }
        ]

    useEffect(() => {
        if (open) {
            if (id) {
                // 编辑时获取信息
                setSpinning(true)
                wxfarm.apiMenu.getById(id)
                    .then(({ data: { name, code, url, type, sort, params, remark } }) => {
                        form.setFieldsValue({
                            id,
                            name,
                            code,
                            url,
                            type,
                            sort,
                            params,
                            remark
                        })
                        setSpinning(false)
                    })
                    .catch(error => {
                        onError(error)
                        setSpinning(false)
                    })
            }
        }
    }, [open, id, form])

    // 关闭窗口
    const onClose = () => {
        form.resetFields()
        setLoading(false)
        props.onClose()
    }

    // 提交表单
    const onFinish = (values) => {
        setLoading(true)
        const promise = id ? wxfarm.apiMenu.edit({ ...values, type: 2 }) : wxfarm.apiMenu.add({ ...values, type: 2 })
        promise.then(({ message }) => {
            onSuccess(message)
            props.onRefresh()
            onClose()
        }).catch(error => {
            onError(error)
            setLoading(false)
        })
    }

    return (
        <Drawer
            title={id ? '编辑' : '新增'}
            open={open}
            onClose={onClose}
            closable={false}
            destroyOnClose={true}
            extra={
                <Space
                    split={<Divider type="vertical" />}
                >
                    <Space.Compact>
                        <Button
                            icon={<CloseOutlined />}
                            onClick={onClose}
                        >
                            取消
                        </Button>

                        <Button
                            icon={<CheckOutlined />}
                            type="primary"
                            onClick={() => form.submit()}
                            loading={loading}
                        >
                            保存
                        </Button>
                    </Space.Compact>
                </Space>
            }
        >
            <Spin
                indicator={<LoadingOutlined style={{ fontSize: 36 }} />}
                spinning={spinning}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="wxfarm.menu.form"
                    onFinish={onFinish}
                >

                    {/* <Form.Item
                        name="type"
                        label="类型"
                        rules={[{ required: true, message: '类型不能为空' }]}
                    >
                        <Select
                            options={typeOptions}
                            placeholder="请选择"
                            onChange={(e) => {
                                if (e === 2) {
                                    form.setFieldValue('isRoute', null)
                                }
                            }}
                        />
                    </Form.Item> */}

                    <Form.Item
                        name="name"
                        label="名称"
                        rules={[{ required: true, message: '系统名称不能为空' }]}
                    >
                        <Input placeholder="请填写" />
                    </Form.Item>

                    <Form.Item
                        name="code"
                        label="权限标识"
                        rules={[{ required: true, message: '权限标识不能为空' }]}
                    >
                        <Input placeholder="请填写" />
                    </Form.Item>


                    <Form.Item
                        name="url"
                        label="路由地址"
                        rules={[{ required: true, message: '路由地址不能为空' }]}
                    >
                        <Input placeholder="请填写" />
                    </Form.Item>

                    <Form.Item
                        name="sort"
                        label="排序"
                    >
                        <InputNumber placeholder="请填写" />
                    </Form.Item>

                    <Form.Item
                        name="type"
                        label="类型"
                        hidden={true}
                    >
                        <Input value={2} />
                    </Form.Item>

                    <Form.Item
                        name="remark"
                        label="备注"
                        style={{ paddingTop: 24 }}
                    >
                        <Input.TextArea style={{ height: 150 }} />
                    </Form.Item>

                    <Form.Item
                        name="id"
                        label="id"
                        hidden={true}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Spin>
        </Drawer>
    )
}
