import React from 'react';
import { Layout } from 'antd';
import RouteView from '../route';
import BreadcrumbView from './breadcrumb';


export default function ContentView() {
    return (
        <Layout.Content
            style={{
                margin: '0 16px',
            }}
        >
            <BreadcrumbView />
            <div
                className='route-view'
            >
                <RouteView />
            </div>
        </Layout.Content>
    )
}
