import React, { useEffect, useState } from 'react';
import { Space, Button, Drawer, Spin, Divider, Tree } from 'antd';
import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { wxfarm } from '../../../service';
import { onSuccess, onError } from '../../../utils';


export default function RolePowerDrawer(props) {
    const { open, id, menus, onClose } = props
        , [spinning, setSpinning] = useState(false)     // 设置获取数据载入状态
        , [loading, setLoading] = useState(false)       // 设置按钮载入状态
        , [values, setValues] = useState([])


    useEffect(() => {
        if (open && id) {
            setSpinning(true)
            // 获取已配置
            wxfarm.apiAuth.getById(id).then(({ data }) => {
                setValues(data)
                setSpinning(false)
            }).catch(error => {
                onError(error)
                setSpinning(false)
            })
        }
    }, [open, id])

    const onFinish = () => {
        setLoading(true)
        const params = {
            "roleId": id,
            "menuIds": [...values]
        }
        wxfarm.apiAuth.edit(params).then(({ message }) => {
            onSuccess(message)
            setLoading(false)
            props.onClose()
        }).catch(error => {
            onError(error)
            setLoading(false)
        })
    }

    return (
        <Drawer
            title={'设置角色权限'}
            open={open}
            onClose={onClose}
            closable={false}
            destroyOnClose={true}
            extra={
                <Space
                    split={<Divider type="vertical" />}
                >
                    <Space.Compact>
                        <Button
                            icon={<CloseOutlined />}
                            onClick={onClose}
                        >
                            取消
                        </Button>

                        <Button
                            icon={<CheckOutlined />}
                            type="primary"
                            onClick={onFinish}
                            loading={loading}
                        >
                            保存
                        </Button>
                    </Space.Compact>
                </Space>
            }
        >
            <Spin
                indicator={<LoadingOutlined style={{ fontSize: 36 }} />}
                spinning={spinning}
            >
                <Tree
                    checkable
                    fieldNames={{ title: 'name', key: 'id' }}
                    treeData={menus}
                    checkedKeys={values}
                    onCheck={(checkedKeys) => setValues(checkedKeys)}
                />
            </Spin>
        </Drawer>
    )
}
