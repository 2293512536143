export const data = [
    {
        id: 1,
        name: "基地管理",
        url: "/base",
        type: 2,
        icon: "AppstoreOutlined"
    },
    {
        id: 2,
        name: "系统模块",
        url: "/site",
        type: 2,
        icon: "AppstoreOutlined"
    },
    {
        id: 3,
        name: "角色管理",
        url: "/role",
        type: 2,
        icon: "AppstoreOutlined"
    },
    {
        id: 4,
        name: "用户管理",
        url: "/user",
        type: 2,
        icon: "AppstoreOutlined"
    },
    {
        id: 5,
        name: "地图配置",
        url: "/map-config",
        type: 2,
        icon: "AppstoreOutlined"
    }
]
