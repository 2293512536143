import React from 'react';
import { Button, Space, Input, Flex } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export default function ColumnSearchProps(props) {
    const { queryParams, dataIndex, setQueryParams = () => { } } = props
        , searchInput = React.useRef(null)
        , handleSearch = (value, dataIndex, close) => {
            let params = { ...queryParams }
            params[dataIndex] = value
            setQueryParams((state) => ({ ...state, ...params }))
            close()
        }

    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, close }) => {
            return (
                <div
                    style={{ padding: 8, minWidth: 360 }}
                    onKeyDown={(e) => e.stopPropagation()}
                >
                    <Input
                        ref={searchInput}
                        placeholder={`请录入关键字查询`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys[0], dataIndex, close)}
                        style={{
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Flex justify="flex-end">
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => handleSearch(selectedKeys[0], dataIndex, close)}
                                icon={<SearchOutlined />}
                            >
                                搜索
                            </Button>

                            <Button
                                onClick={() => {
                                    setSelectedKeys([])
                                    handleSearch(null, dataIndex, close)
                                }}
                            >
                                重置
                            </Button>
                        </Space>
                    </Flex>
                </div>
            )
        },
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined, fontSize: 18 }} />
    }
}
