import React from 'react';
import { Drawer, Typography, Tooltip, Checkbox } from 'antd';
import { useConfigContextAtom } from '../../store/config';
import { CheckOutlined } from '@ant-design/icons';

export default function ConfigDrawer(props) {
    const { open } = props
        , [config, setConfigContext] = useConfigContextAtom()
        , { algorithm, colorPrimary } = config
        , colorList = [
            {
                label: '拂晓蓝（默认）',
                value: 'rgb(24, 144, 255)'
            },
            {
                label: '薄暮',
                value: 'rgb(245, 34, 45)'
            },
            {
                label: '火山',
                value: 'rgb(250, 84, 28)'
            },
            {
                label: '日暮',
                value: 'rgb(250, 173, 20)'
            },
            {
                label: '明青',
                value: 'rgb(19, 194, 194)'
            },
            {
                label: '极光绿',
                value: 'rgb(82, 196, 26)'
            },
            {
                label: '极客蓝',
                value: 'rgb(47, 84, 235)'
            },
            {
                label: '酱紫',
                value: 'rgb(114, 46, 209)'
            }
        ]


    const setConfig = (value) => {
        setConfigContext((state) => ({
            ...state,
            ...value
        }))
    }


    return (
        <Drawer
            open={open}
            title='设置'
            onClose={() => props.onClose()}
            className='config'
        >
            <div className='cell'>
                <div className='cell-label'>
                    <Typography.Title level={5}>整体风格设置</Typography.Title>
                </div>
                <div className='cell-content'>
                    <Tooltip
                        title="亮色菜单风格"
                    >
                        <div className='algorithm light'>
                            <Checkbox
                                checked={algorithm === 'light'}
                                onChange={({ target }) => {
                                    if (target.checked) {
                                        setConfig({ algorithm: 'light' })
                                    }
                                }}
                            />
                        </div>
                    </Tooltip>
                    <Tooltip
                        title="暗色菜单风格"
                    >
                        <div className='algorithm dark'>
                            <Checkbox
                                checked={algorithm === 'dark'}
                                onChange={({ target }) => {
                                    if (target.checked) {
                                        setConfig({ algorithm: 'dark' })
                                    }
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            </div>

            <div className='cell'>
                <div className='cell-label'>
                    <Typography.Title level={5}>主题色</Typography.Title>
                </div>
                <div className='cell-content'>
                    {
                        colorList.map(({ label, value }) => (
                            <Tooltip
                                key={value}
                                title={label}
                            >
                                <div
                                    className='theme-color'
                                    style={{ backgroundColor: value }}
                                    onClick={() => {
                                        setConfig({ colorPrimary: value })
                                    }}
                                >
                                    {
                                        colorPrimary === value ? <CheckOutlined style={{ color: 'white' }} /> : null
                                    }
                                </div>
                            </Tooltip>
                        ))
                    }
                </div>
            </div>
        </Drawer>
    )
}
