import React, { useEffect, useState, useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import HomeView from '../pages/home';
import BaseView from '../pages/base';
import SiteView from '../pages/site';
import RoleView from '../pages/role';
import UserView from '../pages/user';
import MapConfig from '../pages/map-config';
import IconView from '../pages/icon';
import TicketsView from '../pages/tickets';
import WxFarmMenu from '../pages/wx-farm/menu';
import WxFarmAuth from '../pages/wx-farm/auth';
import NewsView from '../pages/website/news';

// 404
import NotFoundView from '../pages/not-found';

const localRouterMap = {
    '/base': <BaseView />,
    '/site': <SiteView />,
    '/role': <RoleView />,
    '/user': <UserView />,
    '/map-config': <MapConfig />,
    '/icon': <IconView />,
    '/tickets': <TicketsView />,
    '/wx-farm/menu': <WxFarmMenu />,
    '/wx-farm/auth': <WxFarmAuth />,
    '/website/news': <NewsView />
}


export default function RouteView() {
    const [items, setItems] = useState([])
        , { code, site } = useSelector(state => state.auth)

    const userMenu = useMemo(() => {
        if (code && site.length) {
            return site.find(item => item.code === code).menuList
        } else {
            return []
        }
    }, [code, site])


    useEffect(() => {
        if (userMenu.length > 0) {
            let record = []
            const formarItem = (arr) => {
                arr.forEach(item => {
                    item.type === 2 && record.push(item)
                    item.children && formarItem(item.children)
                })
            }
            formarItem(userMenu)
            setItems(record)
        }
    }, [userMenu])


    return (
        <Routes>
            <Route exact path="/" element={<HomeView />} />
            {
                items.map(item => (
                    <Route
                        key={item.id}
                        exact
                        path={item.url}
                        element={localRouterMap[item.url]}
                    />
                ))
            }
            <Route path="*" element={<NotFoundView />}></Route>
        </Routes>
    )
}
