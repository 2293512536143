import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LayoutView from '../layout';
import LoginView from '../login';
import Auth from './auth';

export default function RouteView() {

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/*"
                    element={
                        (
                            <Auth>
                                <LayoutView />
                            </Auth>
                        )
                    } />
                <Route
                    path="/login"
                    element={<LoginView />}
                />
            </Routes>
        </BrowserRouter>
    )
}
