import request from "../request";

const api = {
    getByUrl: (menuUrl) => request.post(`/v1/sys/menu/docs/url`, { menuUrl }),
    add: (queryParams) => request.post('/v1/sys/menu/docs/save', queryParams),
    edit: (queryParams) => request.put('/v1/sys/menu/docs', queryParams),
    delete: (id) => request.delete(`/v1/sys/menu/docs/${id}`)
}



const docs = {
    api
}

export default docs
