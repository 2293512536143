/**
 * 表格分页配置
 */
export const PAGINATION = {
    current: 1,                 // 当前页数
    pageSize: 10,               // 每页条数
    total: 0,                   // 数据总数
    // showSizeChanger: true,           // 是否展示 pageSize 切换器，当 total 大于 50 时默认为 true
    // showQuickJumper: true,          // 是否可以快速跳转至某页
    showTotal: total => `共 ${total} 条记录` // 用于显示数据总量和当前数据顺序
}
