import React, { Component } from 'react';
import { Button, Card, Space, Table, Spin, Tooltip, Divider, Popconfirm } from 'antd';
import { LoadingOutlined, Loading3QuartersOutlined, FormOutlined, QuestionCircleOutlined, StopOutlined, PlusOutlined, UserSwitchOutlined } from '@ant-design/icons';
import DropdownColumns from '../../components/dropdown-columns';
import { role } from '../../service';
import RoleFormDrawer from './form';
import RolePowerDrawer from './power';
import { onError, onSuccess } from '../../utils';
import dayjs from 'dayjs';

const stateStr = ['停用', '正常']
export default class RoleView extends Component {
    state = {
        columnsKey: ['id', 'name', 'code', 'description', 'state', 'createTime'],
        columns: [
            {
                title: 'ID',
                dataIndex: 'id'
            },
            {
                title: '角色名称',
                dataIndex: 'name'
            },
            {
                title: '角色CODE',
                dataIndex: 'code',
            },
            {
                title: '角色描述',
                dataIndex: 'description',
            },
            {
                title: '状态',
                dataIndex: 'state',
                render: state => stateStr[state]
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                render: createTime => dayjs(createTime).format('YYYY-MM-DD')
            }
        ],
        dataSource: [],
        selectedRowKey: null,
        open: false,
        loading: false,
        openPower: false,
        site: [],
    }

    componentDidMount() {
        this.fetch()
        this.getSite()
    }

    // 获取当前登录用户角色的系统，菜单列表
    getSite = () => {
        role.api.getSiteList().then(({ data }) => {
            this.setState({
                site: data
            })
        }).catch(error => {
            onError(error)
        })
    }


    fetch = () => {
        this.setState({ loading: true })
        role.api.getList().then(({ data }) => {
            this.setState({
                loading: false,
                dataSource: data
            })
        }).catch(error => {
            onError(error)
            this.setState({ loading: false })
        })
    }

    onDelete = (id) => {
        role.api.delete(id).then(({ message }) => {
            onSuccess(message)
            this.fetch()
        }).catch(error => {
            onError(error)
        })
    }


    render() {
        const { dataSource, columns, columnsKey, loading, selectedRowKey, open, openPower, site } = this.state
            , { fetch, onDelete } = this

        return (
            <>
                <Card
                    title="角色管理"
                    className='page-card-full'
                    extra={(
                        <Space
                            split={<Divider type="vertical" />}
                        >
                            <Tooltip title='新增角色信息'>
                                <Button
                                    type='text'
                                    disabled={selectedRowKey}
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                        this.setState({
                                            open: true,
                                            selectedRowKey: null
                                        })
                                    }}
                                >
                                    新增
                                </Button>
                            </Tooltip>

                            <Tooltip title='编辑所选中的角色'>
                                <Button
                                    type='text'
                                    icon={<FormOutlined />}
                                    disabled={!selectedRowKey}
                                    onClick={() => {
                                        this.setState({
                                            open: true
                                        })
                                    }}
                                >
                                    编辑
                                </Button>
                            </Tooltip>

                            <Popconfirm
                                onConfirm={() => onDelete(selectedRowKey)}
                                title='此操作将停用该系统角色, 是否继续？'
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                disabled={!selectedRowKey}
                            >
                                <Tooltip title='停用所选中的角色'>
                                    <Button
                                        icon={<StopOutlined />}
                                        disabled={!selectedRowKey}
                                        type="text"
                                        danger
                                    >
                                        停用
                                    </Button>
                                </Tooltip>
                            </Popconfirm>

                            <Tooltip title='配置选中的基地的权限'>
                                <Button
                                    type='text'
                                    icon={<UserSwitchOutlined />}
                                    disabled={!selectedRowKey}
                                    onClick={() => {
                                        this.setState({
                                            openPower: true
                                        })
                                    }}
                                >
                                    权限
                                </Button>
                            </Tooltip>

                            <Tooltip title='刷新'>
                                <Button
                                    type='text'
                                    icon={<Loading3QuartersOutlined />}
                                    onClick={() => fetch()}
                                />
                            </Tooltip>

                            <DropdownColumns
                                columns={columns}
                                checkedKeys={columnsKey}
                                onChange={columnsKey => this.setState({ columnsKey })}
                            />
                        </Space>
                    )}
                >
                    <Spin
                        indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />}
                        spinning={loading}
                    >
                        <Table
                            columns={columns.filter(record => columnsKey.includes(record.dataIndex))}
                            rowKey={record => record.id}
                            dataSource={dataSource}
                            pagination={false}
                            loading={loading}
                            rowSelection={{
                                type: 'checkbox',
                                hideSelectAll: true,
                                selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
                                onChange: keys => {
                                    this.setState({
                                        selectedRowKey: keys[0]
                                    })
                                }
                            }}
                            onRow={({ id }) => ({
                                onClick: () => {
                                    this.setState({
                                        selectedRowKey: selectedRowKey === id ? null : id
                                    })
                                }
                            })}
                        />
                    </Spin>
                </Card>

                <RoleFormDrawer
                    open={open}
                    id={selectedRowKey}
                    onClose={() => this.setState({ open: false })}
                    onRefresh={fetch}
                    stateStr={stateStr}
                />

                <RolePowerDrawer
                    site={site}
                    open={openPower}
                    id={selectedRowKey}
                    onClose={() => this.setState({ openPower: false })}
                />

            </>
        )
    }
}
