import React, { useEffect, useMemo, useState } from 'react';
import { FeatureGroup, Tooltip as MapTooltip, Marker, useMap } from 'react-leaflet';
import L from "leaflet";
import { object } from '../../utils/utils';

const MarkerView = (props) => {
    const { dataSource, value = [], maxZoom, editor = null, isFitBounds = false, icon, editIcon, selectIcon } = props
        , { setMapFeatureGroup, setSelectedRows, setValue, onClick } = props
        , [initialization, setInitialization] = useState(false)
        , refMarker = React.useRef(null)
        , map = useMap()

    // 更新图层
    useEffect(() => {
        const target = refMarker.current
            , layers = []

        target.getLayers().forEach(group => {
            group.getLayers().forEach(layer => {
                layers.push(layer)
            })
        })
        if (!initialization && isFitBounds && target && layers.length) {
            const bounds = target.getBounds()
            map.fitBounds(bounds, { maxZoom })
            setInitialization(true)
        }
        setMapFeatureGroup((state) => ({
            ...state,
            marker: { layers, target }
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource, initialization, maxZoom])

    // 渲染图形图层
    const MapMarker = useMemo(() => {
        if (dataSource) {
            const isObject = object(dataSource)
                , source = isObject ? dataSource : { data: [{ key: 'marker', data: dataSource }] }
                , { data = [], multiple = false, selectable = true, fieldNames = { key: 'key', positions: 'positions' } } = source
            return data.map(row => {
                // 可单击优先级 以行数据优化
                const isSelectable = row.selectable ? true : selectable
                return (
                    <FeatureGroup
                        key={row.key}
                        id={row.key}
                    >
                        {
                            row.data.map(record => {
                                const { name, tooltip = false, permanent = false } = record
                                    // 默认多边形样式
                                    , url = record.icon ? record.icon : row.icon ? row.icon : icon
                                    // 选中多边形样式
                                    , selectedUrl = record.selectIcon ? record.selectIcon : row.selectIcon ? row.selectIcon : selectIcon
                                    // 编辑多边形样式
                                    , editUrl = record.editIcon ? record.editIcon : row.editIcon ? row.editIcon : editIcon
                                    // key
                                    , key = record[fieldNames.key]
                                    // 坐标
                                    , position = record[fieldNames.positions]
                                    // 是否默认选中
                                    , isCheck = value.includes(key)
                                    // 渲染多边形样式
                                    , iconUrl = isCheck ? editor?.key === key ? editUrl : selectedUrl : url
                                    //全局配置可编辑或单行可编辑取值
                                    , ignore = typeof source.ignore === 'undefined' ? record.ignore : source.ignore


                                return position && position.length === 2 ? (
                                    <Marker
                                        key={key}
                                        id={key}
                                        prev={row.key}
                                        type={"Marker"}
                                        ignore={ignore}
                                        position={position}
                                        icon={L.icon({ iconUrl, iconSize: [48, 48], iconAnchor: [24, 24] })}
                                        eventHandlers={{
                                            add: ({ target }) => {
                                                if (isCheck) {
                                                    setValue((state) => [...state, key])
                                                    setSelectedRows(state => [...state, target])
                                                }
                                            },
                                            click: ({ target }) => {
                                                if (!isSelectable || editor?.key === key) return
                                                const check = !value.includes(key)
                                                onClick({
                                                    ...record,
                                                    type: 'Marker',
                                                    target,
                                                    check
                                                })
                                                setValue((state) => {
                                                    return multiple ?
                                                        check ? [...state, key] : state.filter(v => v !== key) :
                                                        check ? [key] : []
                                                })

                                                setSelectedRows(state => {
                                                    return multiple ?
                                                        check ? [...state, target] : state.filter(item => item.options.id !== key) :
                                                        check ? [target] : []
                                                })
                                            }
                                        }}
                                    >
                                        {
                                            tooltip || name ? (
                                                <MapTooltip
                                                    direction="top"
                                                    permanent={permanent}
                                                    offset={[0, -16]}
                                                >
                                                    {
                                                        tooltip ? (
                                                            <div dangerouslySetInnerHTML={{ __html: tooltip }}></div>
                                                        ) : name
                                                    }
                                                </MapTooltip>
                                            ) : null
                                        }

                                    </Marker>
                                ) : null
                            })
                        }
                    </FeatureGroup>
                )
            })
        } else {
            return null
        }
    }, [dataSource, value, editor, icon, editIcon, selectIcon, setSelectedRows, setValue, onClick])

    return (
        <FeatureGroup
            ref={refMarker}
            type='Marker'
        >
            {MapMarker}
        </FeatureGroup>
    )
}


MarkerView.defaultProps = {
    icon: require('./image/marker.png'),
    selectIcon: require('./image/selected-marker.png'),
    editIcon: require('./image/edit-marker.png')
}

export default MarkerView
