import React, { useState, createElement, useEffect } from 'react';
import { Space, Button, Drawer, Spin, Form, Input, Divider, Select, InputNumber, Switch } from 'antd';
import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { menu } from '../../service';
import { onSuccess, onError } from '../../utils';
import * as Icon from '@ant-design/icons';
import iconData from '../../utils/icons';

const { Option, OptGroup } = Select;
export default function MenuFormDrawer(props) {
    const { open, id, parentId, siteId } = props
        , [spinning, setSpinning] = useState(false)     // 设置获取数据载入状态
        , [loading, setLoading] = useState(false)       // 设置按钮载入状态
        , [form] = Form.useForm()
        , typeOptions = [
            { label: '目录', value: '1' },
            { label: '菜单', value: '2' },
            // { label: '按钮', value: '3' }
        ]

    useEffect(() => {
        if (open) {
            if (id) {
                // 编辑时获取信息
                setSpinning(true)
                menu.api.getById(id)
                    .then(({ data: { siteId, parentId, name, code, icon, url, type, isRoute, sort, params, remark } }) => {
                        form.setFieldsValue({
                            id,
                            siteId,
                            parentId,
                            name,
                            code,
                            icon,
                            url,
                            type: type.toString(),
                            isRoute: isRoute ? 1 : 0,
                            sort,
                            params,
                            remark
                        })
                        setSpinning(false)
                    })
                    .catch(error => {
                        onError(error)
                        setSpinning(false)
                    })
            } else {
                form.setFieldsValue({
                    parentId, siteId
                })
                if (parentId) {
                    menu.api.getById(parentId).then(({ data: { name, url } }) => {
                        form.setFieldsValue({
                            parentName: name,
                            url: url
                        })
                    }).catch(error => {
                        onError(error)
                    })
                }
            }
        }
    }, [open, id, parentId, siteId, form])

    // 关闭窗口
    const onClose = () => {
        form.resetFields()
        setLoading(false)
        props.onClose()
    }

    // 提交表单
    const onFinish = (values) => {
        setLoading(true)
        values.isRoute = values.isRoute ? 1 : 0
        const promise = id ? menu.api.edit(values) : menu.api.add(values)
        promise.then(({ message }) => {
            onSuccess(message)
            props.onRefresh()
            onClose()
        }).catch(error => {
            onError(error)
            setLoading(false)
        })
    }

    // 渲染图标
    const renderIcon = (name) => {
        return createElement(Icon[name])
    }

    return (
        <Drawer
            title={id ? '编辑' : '新增'}
            open={open}
            onClose={onClose}
            closable={false}
            destroyOnClose={true}
            extra={
                <Space
                    split={<Divider type="vertical" />}
                >
                    <Space.Compact>
                        <Button
                            icon={<CloseOutlined />}
                            onClick={onClose}
                        >
                            取消
                        </Button>

                        <Button
                            icon={<CheckOutlined />}
                            type="primary"
                            onClick={() => form.submit()}
                            loading={loading}
                        >
                            保存
                        </Button>
                    </Space.Compact>
                </Space>
            }
        >
            <Spin
                indicator={<LoadingOutlined style={{ fontSize: 36 }} />}
                spinning={spinning}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="site.form"
                    onFinish={onFinish}
                >
                    {
                        parentId ? (
                            <Form.Item
                                name="parentName"
                                label="父级"
                            >
                                <Input disabled placeholder="请填写" />
                            </Form.Item>
                        ) : null
                    }

                    <Form.Item
                        name="type"
                        label="类型"
                        rules={[{ required: true, message: '类型不能为空' }]}
                    >
                        <Select
                            options={typeOptions}
                            placeholder="请选择"
                            onChange={(e) => {
                                if (e === 2) {
                                    form.setFieldValue('isRoute', null)
                                }
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        name="name"
                        label="名称"
                        rules={[{ required: true, message: '系统名称不能为空' }]}
                    >
                        <Input placeholder="请填写" />
                    </Form.Item>

                    <Form.Item
                        name="code"
                        label="权限标识"
                        rules={[{ required: true, message: '权限标识不能为空' }]}
                    >
                        <Input placeholder="请填写" />
                    </Form.Item>


                    <Form.Item
                        name="url"
                        label="路由地址"
                        rules={[{ required: true, message: '路由地址不能为空' }]}
                    >
                        <Input placeholder="请填写" />
                    </Form.Item>

                    <Form.Item
                        noStyle
                        dependencies={['type']}
                    >
                        {() => {
                            const type = form.getFieldValue('type')
                            return type === '1' ? (
                                <>
                                    <Form.Item
                                        name="isRoute"
                                        label="路由"
                                        valuePropName="checked"
                                        help="目录强制添加路由控制。子菜单将传参此路由"
                                    >
                                        <Switch />
                                    </Form.Item>
                                    <Form.Item
                                        name="params"
                                        label="传参"
                                    >
                                        <Input placeholder="请填写" />
                                    </Form.Item>
                                </>
                            ) : null
                        }}
                    </Form.Item>

                    <Form.Item
                        name="icon"
                        label="图标"
                    >
                        <Select
                            placeholder="请选择图标"
                            listItemHeight={.256}
                            popupClassName="custom"
                        >
                            {
                                iconData.map(item => {
                                    return (
                                        <OptGroup key={item.value} label={item.label}>
                                            {
                                                item.children.map(key => {
                                                    return <Option key={key} value={key}>{renderIcon(key)}</Option>
                                                })
                                            }
                                        </OptGroup>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="sort"
                        label="排序"
                    >
                        <InputNumber placeholder="请填写" />
                    </Form.Item>



                    <Form.Item
                        name="remark"
                        label="备注"
                        style={{ paddingTop: 24 }}
                    >
                        <Input.TextArea style={{ height: 150 }} />
                    </Form.Item>

                    <Form.Item
                        name="siteId"
                        label="siteId"
                        hidden={true}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="parentId"
                        label="parentId"
                        hidden={true}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="id"
                        label="id"
                        hidden={true}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Spin>
        </Drawer>
    )
}
