import { store } from "../store";


export const notification = ({ type = 'success', message = '温馨提示', description = null, placement = 'bottomRight', duration = 4.5, btn = null }) => {
    return store.dispatch({
        type: 'global/open',
        payload: { type, message, description, placement, duration, btn }
    })
}

export const onSuccess = (params) => {
    if (typeof params === 'string') {
        notification({
            type: 'success',
            description: params
        })
    } else {
        const { message = '温馨提示', description = null, placement = 'bottomRight', duration = 4.5, btn = null } = params
        notification({
            type: 'success',
            message,
            description,
            placement,
            duration,
            btn
        })
    }
}


export const onError = (params) => {
    if (typeof params === 'string') {
        notification({
            type: 'error',
            description: params
        })
    } else if (params.response) {
        const { name, message, response } = params
        if (response.status === 401){
            store.dispatch({
                type: 'auth/clear'
            })
        }
        
        notification({
            type: 'error',
            message: name,
            description: message,
            placement: 'bottomRight',
            duration: 4.5,
            btn: null
        })
    } else if (params.stack) {
        const { message, stack } = params
        notification({
            type: 'error',
            message: message,
            description: stack,
            placement: 'bottomRight',
            duration: 4.5,
            btn: null
        })
    } else if (params.code > 0) {
        const { message } = params
        notification({
            type: 'error',
            message: '提示',
            description: message,
            placement: 'bottomRight',
            duration: 4.5,
            btn: null
        })
    } else {
        debugger
    }
}

