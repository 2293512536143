/**
 * 登录组件
 * 可传值
 * title 登录卡片标题
 * img 登录卡片左侧图片url
 * user 表单组件 user={username,password}
 * loading 登录按钮loading状态
 * onLogin 登录方法
 * 
 */
import { Button, Checkbox, Form, Input, theme } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './index.scss'
import React, { useEffect } from 'react';
import image from "./image/analytics.png"


const { useToken } = theme;
export default function LoginComponent(props) {

    const { title, img, user, loading, onLogin } = props;
    const [form] = Form.useForm(),
        { token } = useToken()

    const onFinish = (form) => {
        onLogin(form);
    }

    useEffect(() => {
        if (user) {
            form.setFieldsValue(user);
        }
    }, [user, form])

    return (
        <div className="page-login"
            style={{ backgroundColor: token.colorBgLayout }}>
            <div className="balls">
                <div className="ball ball1"></div>
                <div className="ball ball2"></div>
                <div className="ball ball3"></div>
            </div>
            <div className="login-wrap" style={{ backgroundColor: token.colorBgContainer }}>
                <div className="login-img">
                    <img src={img || image} alt="登录" />
                </div>
                <div className="login-form">
                    <Form form={form} onFinish={onFinish}>
                        <Form.Item>
                            <h2 className="login-title" style={{ color: token.colorPrimary }}>{title || '登录'}</h2>
                        </Form.Item>
                        <Form.Item name="username" rules={[{ required: true, message: '请输入登录账号' }]}>
                            <Input
                                name="username"
                                prefix={<UserOutlined />}
                                size="large" placeholder="请输入账号/手机" />
                        </Form.Item>
                        <Form.Item name="password" rules={[{ required: true, message: '请输入登录密码' }]}>
                            <Input.Password
                                prefix={<LockOutlined />}
                                size="large"
                                placeholder="请输入密码"
                                autoComplete="new-password" />
                        </Form.Item>
                        <Form.Item name="remember" valuePropName="checked">
                            <Checkbox>记住密码</Checkbox>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                size="large"
                                block
                                loading={loading}
                                htmlType="submit">登录</Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            <div className="copyright" style={{ color: token.colorText }}> © 2024 黄山有农创新农业科技公司</div>
        </div>
    )

}
