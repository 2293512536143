import request from "../request";

const api = {
    getList: (queryParams, current = 1, pageSize = 5) => request.post('/v1/open/tickets/search', { ...queryParams, current, pageSize }),
    getById: (id) => request.get(`/v1/open/tickets/${id}`),
    add: (queryParams) => request.post('/v1/open/tickets', queryParams),
    edit: (queryParams) => request.put('/v1/open/tickets', queryParams),
    delete: (id) => request.delete(`/v1/open/tickets/${id}`),
}

const tickets = {
    api
}

export default tickets
